import React,{Component} from 'react';
import {Image} from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";
import {connect} from 'react-redux';
import * as userActions from "../../redux/user/user_actions";
import {bindActionCreators} from "redux";
import Config from "../../config";
import './email-welcome.css'
import Api from '../../services/api.js';


class OnBoardingEmailPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            infoActive: null,
            alredylogin:null,
            showRedirect:false,
            showContent:false
        };
    }

    isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    } 

    componentDidMount() {
  
            setTimeout(() => {
                this.setState({ showContent: true });
            }, 1000); 
   
    
        let code = window.location.search.split('code=');
        code = code && code.length > 1 ? code[code.length - 1] : null;
        console.log("----------------code ",code)
        
        this.props.userActions.verify({code: code}, (err, data) => {
            console.log("----------------data 1 ",data)
            if (err) {
                Config.alertMess(err);
            }
            else if (data) {
            let userData = localStorage.getItem('USERLOGIN'); 
            let parsedData = JSON.parse(userData);
            let userEmail= data[0]
            let lastLoginavatar=  userEmail.avatar
            
                if(lastLoginavatar !== ''){
                    this.setState({
                        alredylogin:true
                    })
                }

                this.setState({
                    infoActive: data
                });
                let params = {
                    email: userEmail.email,
                    password:parsedData.password
                };
                setTimeout(()=>{
                     this.props.userActions.login(params, (err, data) => {
                        console.log("----------------data ",data)
                                    if (data) {
                                        if (this.isMobileDevice()) {
                                            this.setState({ showRedirect: true });
                                        }
                                        if (data.user.lastLogin === 0) {
                                            window.location.href = "/on-boarding-one";
                                        }
                                        else window.location.href = '/';
                                    }
                                   
                                })
                   
                }, 2000)
            }

        })
    }


    render(){
        return(
            <div className="full_w full_h">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop: 50}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>

                        <p className="logoteam text-center hidden">
                            <Image style={{width: 150}} src={require('../../assets/images/bamboo.png')}/>
                        </p>
                        {this.state.showContent && (
                    this.state.alredylogin ? (
                        <div className="txt-condensed-extra-bold" style={{ marginBottom: 60, marginTop: 60 }}>
                            We're sorry, the link you are trying to access has either expired or has already been used.
                        </div>
                    ) : (
                        <div className="txt-condensed-extra-bold" style={{ marginBottom: 60, marginTop: 60 }}>
                            Welcome to Teemie.
                        </div>
                    )
                )}
                        
                    </div>
                </div>
            </div>
        )
    }
}

//export default OnBoardingEmailPage;

export default connect(state => ({}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
    })
)(OnBoardingEmailPage);