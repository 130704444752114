import React, { Component } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import { Button, Image,Modal } from "react-bootstrap";
import { select } from 'redux-saga/effects';

class Select2Component extends Component {
  constructor(props){
    super(props);
    this.state= {
        name: "",
        selectedOptions: [],
        selectedProfile: [],
        selectedValues: [],
        isAllSelected: false,
        openDrop : true,
        outopen : false
      
    }
  }

  componentDidMount() {
    this.isDropdownOpen = false;

    $(this.selectElement).on('select2:open', () => {
        this.isDropdownOpen = true;
        this.setState({
          outopen: true,
          openDrop : false,
        })
      });
    
      $(this.selectElement).on('select2:close', () => {
        this.isDropdownOpen = false;
        this.setState({
          outopen: false,
          openDrop : true,
        })
      });
    
      this.initializeSelect2();
      const { selectedValue } = this.props;
      $(this.selectElement).val([]).trigger('change');
      setTimeout(() => {
        if (this.props.selectedValue.length > 0) {
          $(this.selectElement).val(this.props.selectedValue).trigger('change');
          this.preSelectProfile(this.props.selectedValue);
        } 
      }, 400);  
  }

  componentDidUpdate(prevProps) {
    console.log('isDropdownOpen',this.isDropdownOpen,prevProps.options, this.props.options);
    if (!this.isDropdownOpen && prevProps.options!= this.props.options) {
      this.initializeSelect2();
    }
    

  }

  componentWillUnmount() {
    // Destroy Select2 to clean up event listeners
    $(this.selectElement).select2('destroy');
  }

  formatOption = (option) => {
    if (!option.id) return option.text; // Return the placeholder text for the dropdown
    const checkboxId = `option_${option.id}`; // Generate a unique ID for the checkbox

    return `
      <label for="${checkboxId}">
      <img 
        src="${option.element.dataset.image}" 
        alt="avatar" 
        style="width: 35px; height: 35px; border-radius: 50%;" 
      />
        <span>${option.text}</span>
      </label>
      <div class="line-div"></div>
    `;
  };

  formatSelection = (option) => {
    console.log("------------optionssssss",option)
    const selectedValues = $(this.selectElement).val() || [];
    console.log("------------selectedValues",selectedValues)
    // Update the state by creating a new array

    this.setState((prevState) => {
      // Remove items whose IDs are not in validIds
      const filteredItems = prevState.selectedProfile.filter((item) => selectedValues.includes(item.id));
      
      // Check for duplicates using `find` or `some`
      const isDuplicate = filteredItems.some((item) => item.id === option.id);

      if (!isDuplicate) {
        return { selectedProfile: [...filteredItems, option] };
      }
      // Return the previous state if the item is a duplicate
      return prevState;
    });

   
    return option.text;
      
  };
  

  handleSelectionChange = () => {
    console.log("-----------selected values :", $(this.selectElement).val());
    if ($(this.selectElement).val().includes('all')) {
      if($(this.selectElement).val().length > 1 && this.state.isAllSelected)
      {
        const allOptions = $(this.selectElement).val().filter(option => option !== 'all');
        $(this.selectElement).val(allOptions);
        this.setState({
          isAllSelected: false
        });
      }else{
        $(this.selectElement).val(['all']);
        this.setState({
          isAllSelected: true
        });
      }
    }
      const selectedValues = $(this.selectElement).val() || [];
    
    this.setState({
      selectedOptions: selectedValues
    });
    const allOptionValue = 'all';
    console.log("-----------selected values :", selectedValues);
    this.setState({
      name : selectedValues
    })
    // Remove items whose IDs are not in validIds
    const filteredItems = this.state.selectedProfile.filter((item) => selectedValues.includes(item.id));
    // assign reformated profile
    this.setState({
      selectedProfile: filteredItems
    });
    let user;
    if (selectedValues.length > 0) {
      const { options } = this.props;
    
      // Filter options based on the value of the first selected value
      const selectedOption = options.find(option => option.value === selectedValues[0].value);
      console.log("selectedOption:", selectedOption);
      // Check if a matching option is found and assign its text to the user
      if (selectedOption) {
        user = selectedOption.text;
      }
    }
  
    console.log("User:", user);
    console.log("Selected values:", selectedValues);

    if (selectedValues.includes(allOptionValue)) {
      // If "All" is selected, deselect all other options
      selectedValues.map((index, element) => {
        if (element.value !== allOptionValue) {
          console.log("element:", element);
        }
      });
      // $(this.selectElement)
      //   .val([allOptionValue]) // Keep only "All" selected
      //   .trigger('change'); // Trigger the change event to update Select2
    } else if (selectedValues.length === 0) {
      // If nothing is selected, clear everything
      // $(this.selectElement).val(null).trigger('change');
    }

    //this.props.onSelectionChange(selectedValues); // Notify parent component
  };

  handleRemoveProfile = (id) => {
    // Remove the profile from the state
    this.setState(
      (prevState) => ({
        selectedProfile: prevState.selectedProfile.filter(
          (item) => item.id !== id
        ),
      }),
      () => {
        // Update Select2 to remove the selection
        $(this.selectElement).val(
          this.state.selectedProfile.map((profile) => profile.id)
        ).trigger('change');
      }
    );
  };

  initializeSelect2() {
    const { options } = this.props;
    console.log("-----------------optionnnnnnnn:",options)
    $(this.selectElement).select2({
        placeholder: this.props.placeholder,
        allowClear: this.props.allowClear || false,
        closeOnSelect: this.props.closeOnSelect || false,
        dropdownParent: $(this.selectElement).parent(), // Prevent dropdown close issues
        theme: 'classic', // Use Select2 themes
        multiple: this.props.multiple || false, // Enable multiple selection
        templateResult: this.formatOption, // Custom option rendering
        escapeMarkup: (markup) => markup, // Allow HTML rendering in options
        templateSelection: this.formatSelection, // Custom selected option rendering
      }).on('change', () => {
        const selectedData = $(this.selectElement).select2('data');
      
        this.setState({ selectedValues: selectedData });
        this.handleSelectionChange();
        this.props.onSelectionChange(selectedData);
    });

    

      // Handle selection changes
    //$(this.selectElement).on('change', this.handleSelectionChange);
  }

  preSelectProfile = (selectedValue) => {
    const { options } = this.props;
    console.log("selectedValue:", selectedValue);
    let selectedProfile = options.filter((option) => selectedValue.includes(option.value));
    console.log("selectedProfile:", selectedProfile);
    selectedProfile = selectedProfile.map((option) => ({
      id: option.value,
      text: option.label,
      element: {
        dataset: {
          image: option.image,
      },
    },
    }));
    this.setState({ 
      "selectedProfile":selectedProfile
    });
    console.log("selectedProfile state:", this.state.selectedProfile);
  };
  

  getDisplayText() {
    const { selectedValues } = this.state;
  console.log("selectedValues display:", $(this.selectElement).val());
    if (selectedValues.length === 0) {
        return 'Select team here';
    } else if (selectedValues.length === 1) {
        return selectedValues[0].text;
    } else if($(this.selectElement).val().includes('all')) {
      return 'All'; 
    }else {
        return  selectedValues.map(item => item.text).join(", ");

    }
}

openSelect2Dropdown = (open) => {

 if(this.state.openDrop === true && open=== false){
    $(this.selectElement).select2('open');
    this.setState({
      openDrop : false
    })
  }
  else if(this.state.openDrop === false && open=== false){
    $(this.selectElement).select2('close');
    this.setState({
      openDrop : true
    })
  }
};


  render() {
    const { options } = this.props;
    

    let selectedLabels = options.filter(item => this.state.name.includes(item.value))
  .map(item => item.label);
    return (
      <>
      <select
      multiple={this.props.multiple}
        ref={(element) => (this.selectElement = element)}
        style={{ width: '100%' }}
       
        
      >
        {options.map((option, index) => (
          <option key={index} value={option.value} data-select2-id={`opt-${index}`} data-image={option.image} >
            {option.label}
          </option>
        ))}
      </select>
      <div className='user-input-icon' onClick={()=>this.openSelect2Dropdown(this.state.outopen)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
  <path d="M14.6041 1.20234L13.8904 0.488686C13.7953 0.393386 13.6858 0.345886 13.5619 0.345886C13.4383 0.345886 13.3289 0.393386 13.2338 0.488686L7.6233 6.09884L2.0131 0.488836C1.91795 0.393536 1.80845 0.346036 1.68475 0.346036C1.561 0.346036 1.4515 0.393536 1.3564 0.488836L0.6428 1.20254C0.5475 1.29764 0.5 1.40714 0.5 1.53089C0.5 1.65454 0.54765 1.76404 0.6428 1.85914L7.29495 8.51144C7.39005 8.60659 7.4996 8.65414 7.6233 8.65414C7.747 8.65414 7.85635 8.60659 7.9514 8.51144L14.6041 1.85914C14.6992 1.76399 14.7465 1.65449 14.7465 1.53089C14.7465 1.40714 14.6992 1.29764 14.6041 1.20234Z" fill="#31CCBC"/>
</svg>
                    </div>
      <div className="custom-display" onClick={this.openSelect2Dropdown}>
                    
                    {this.getDisplayText()}
                </div>
      
      {this.state.selectedProfile.length > 0 && 
      <div className='selected-staff-container'> 
        {this.state.selectedProfile.map((item,index) => (
          
          <label key={index} className={`image-container${index} image-container`} >
            <span
                onClick={() => this.handleRemoveProfile(item.id)}
                className={`remove-profile${index} remove-profile`}
              >
                &times;
              </span>
            {this.state.selectedProfile[0].id !== "all" && 
           <img src={item.element.dataset.image} alt="avatar" className={`circle-image${index} circle-image`} />}
            {this.state.selectedProfile[0].id === "all" && (
                <>
                  <Image
                    src={require('../../calendar/teamie-logoavatar.png')}
                    alt="avatar"
                    className="circle-image all-images"
                  />
                </>
              )}
          </label>
        ))}    
      </div>
      }
      
      </>
    );
  }
}

export default Select2Component;
