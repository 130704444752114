
import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";

//import css
import '../register/register.css'
import './calendar.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';
import 'react-datepicker/dist/react-datepicker.css';

//import component
import {Button, Image, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SearchBar from "../my-team-detail/search-bar";
import CForm from "../libs/cform/cform";

//import lib react day
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

//import lib react select
import Select from 'react-select';

import moment from "moment";
import Config from "../../config";
import ReactLoading from "react-loading";
import Header from '../header/header';
import Loaderview from '../roster/loaderview';
import FormatedDatePicker from './formated-date-picker.js'
import  FormatedTimePicker  from './formated-time-picker.js'

import DatePicker from "react-datepicker";
import Select2Component from '../libs/select2/Select2Component';

import { browserHistory } from 'react-router';
import { times } from 'lodash';

class Calendar extends Component{
    constructor(props){
        super(props);
        this.state={
            idTeam: null,
            flagTab: 2,
            isCreateEvent: false,
            isShowEvent: false,
            listEvent: [],
            month:null,
            fixedValue: 100,
            isScroll: false,
            toggleTeamSelection:true,
            isTeamOpened:false,
            updatedWeek:null,
            UpdateYearFromChild: null,
            editEvent : {
                name: {
                    value: "",
                    isValid: true,
                    isRequired: true
                },
                date: {
                    value: new Date(),
                    isValid: true,
                    isRequired: true
                },
                dropdownValue: {
                    value: [],
                    isValid: true,
                    isRequired: true
                },
                time: {
                    value: new Date(new Date().setSeconds(0)),
                    isValid: true,
                    isRequired: true
                },
            },
            isloading:false,
            modalOpen : false,
            eventDatas:'',
            isUpdateRemoveEventConfirmed:false,
            selectValue : '',
            isUpdateEvent:false

        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
    }

    componentDidMount() {
        // Add scroll event listener
        window.addEventListener('scroll', this.handleScroll);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString); 
        let notificationUrlparams =urlParams.has('eventId')
                      
        if(notificationUrlparams === false){
            localStorage.removeItem('getYear'); 
        }
                       
        localStorage.removeItem('Years');

    }
    

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }
    handleYearUpdate = (year) => {
        this.setState({ UpdateYearFromChild: year });
    };

    init = async () => {
        if (localStorage.getItem('LOGIN')) {
            const checkLogin = JSON.parse(localStorage.getItem('LOGIN'));
            console.log(checkLogin)
        }
    
        this.props.eventActions.getListEvent(this.dataFilter, (err, data) => {
            console.log("event data", data);
            if (err) {
                this.setState({
                    listEvent: [],
                });
            } else {
                this.setState({
                    listEvent: data,
                });
            }
        });
    }
    popupmodal = (valu) => {
        setTimeout(() => {
            this.setState({
                isShowEvent : false,
                modalOpen: true,
                eventDatas: valu
            });
        }, 1000); 
    };
    

    popupclose = () => {
        this.setState({
            modalOpen: false
        });

        browserHistory.push("/calendar");
    };

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 99,
                isScroll: true
            });
        } else {
            this.setState({
                fixedValue: 100,
                isScroll: false
            });
        }
    }

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                Config.popup.show('INFO',err.message);
            }
            else{
                // that._goScreenTeamDetai();
            }
        });
    };

    onChange = date => this.setState({ date });

    changeTab = (idx) => {
        if(this.state.flagTab === idx) return;
        let eventTime = new Date();
        this.setState({
          flagTab: idx,
          isloading: true,
          updatedWeek:null
        }, async() => {
            try {
                const result = await this.getEvent(null,eventTime); // Call your function
                console.log("Function result:", result);
              } catch (error) {
                console.error("Error executing function:", error);
              } 
              finally {
                console.log("Change Tab Executed successfully");
                //this.setState({ isloading: false }); // Hide loader
              }
        });
        localStorage.removeItem('Years');
      };

    changeDropDownTeam = (data) => {
        this.setState(
            {
                isloading: true, 
                idTeam: data.id,
            },
            () => {
                // Callback after state is updated
                this.getEvent(data.id);
                this.setState({
                    toggleTeamSelection: false,
                });
            }
        );
    };

    getId = () => {
        let location = window.location.href;
        if(location.indexOf('?user=') != -1){
            let memberId = window.location.search.split('?user=');
            memberId=memberId[memberId.length-1].split("&eventId=");
            memberId=memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else{
            return null;
        }
    };

    getEventDate = (eventList) => {
        let storedTeamsId = localStorage.getItem('getYear');
        let teamsId = storedTeamsId ? JSON.parse(storedTeamsId) : '';
        const filteredEvents = eventList.filter(event => event.time == teamsId[0]);
        let location = window.location.href;
        if(location.indexOf('?eventId=') != -1){
            let eventId = window.location.search.split('eventId=');
            eventId = eventId[eventId.length-1].split('&tid=');
            eventId=eventId[0];
            let eventDate = eventList ? eventList.find(n => n.id == eventId).time : null;
            this.popupmodal(filteredEvents[0])
            return eventDate;
        }
        else{
            return false;
        } 
    }

    getEvent = async(idTeam, time) => {
     
        let type= this.state.flagTab === 2 ? 'year' :this.state.flagTab==0?'week': 'month';
       if(localStorage.getItem('getYear')){
        let startOfYear = new Date(this.state.UpdateYearFromChild, 0, 1);
        if(this.state.UpdateYearFromChild !== null){
            time = startOfYear
        }
       }

       if(localStorage.getItem('Years') && time == undefined){
            let getChangeYear= localStorage.getItem('Years')
            time = getChangeYear
        }
   
       if(type == 'week'){
        time = this.state.updatedWeek
       }

        let timeUse = time ? time : this.state.month;
        let begin, end;
        if (type === 'week') {
            begin = timeUse ? moment(timeUse).startOf('isoWeek').toISOString() : moment().startOf('isoWeek').toISOString();
            end = timeUse ? moment(timeUse).endOf('isoWeek').toISOString() : moment().endOf('isoWeek').toISOString();
        } 
        else {
            begin = timeUse ? moment(timeUse).startOf(type).toISOString() : moment().startOf(type).toISOString();
            end = timeUse ? moment(timeUse).endOf(type).toISOString() : moment().endOf(type).toISOString();
        }

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let uid = checkLogin.user.id;
        this.dataFilter.uid = uid;
        this.dataFilter.team = this.state.idTeam;// ? id : this.state.idTeam;
        this.dataFilter.timeFrom = begin;
        this.dataFilter.timeTo= end;

        let MemberId = this.getId();
        this.setState({
            isloading: true,
        });
        if(MemberId){
            this.dataFilter.user = MemberId;
           await this.props.eventActions.summaryEvent(this.dataFilter, (err, data)=>{
                if(err){
                    // Config.popup.show('INFO',[Config.alertMess(err)]);
                    this.setState({
                        listEvent: [],
                    })
                }
                else if(data){
                    this.setState({
                        listEvent: data,
                        isloading:false
                    })
                }
                else {
                    this.setState({
                        isloading: false,
                    });
                }
            })
        }
        else{
           await this.props.eventActions.getListEvent(this.dataFilter, async(err, data)=>{
                if (err) {
                    // Config.popup.show('INFO',[Config.alertMess(err)]);
                    this.setState({
                        listEvent: [],
                        isloading: false,
                    });
                } 
		        else if (data) {
                    let showEventDate = await this.getEventDate(data);
                    this.setState({
                        listEvent: data,
                        modalOpen: showEventDate,
                        isloading: false,
                    });
                } else {
                    this.setState({
                        listEvent: [],
                        isloading: false,
                    });
                }
            })
        }
    };
    
    toggleCreateEvent = () => {
        const currentTime = new Date();
        currentTime.setSeconds(0); // Optional: remove seconds from time
        this.setState({
            isCreateEvent: !this.state.isCreateEvent,
            isUpdateEvent:false,
            editEvent:{
                name: {
                    value: "",
                    isValid: true,
                    isRequired: true
                },
                date: {
                    value: new Date(),
                    isValid: true,
                    isRequired: true
                },
                dropdownValue: {
                    value: [],
                    isValid: true,
                    isRequired: true
                },
                time: {
                    value: new Date(new Date().setSeconds(0)),
                    isValid: true,
                    isRequired: true
                },
            }
        })
    };

    editeTOggleCreateEvent = (data)=>{
        this.setState({
            modalOpen: false
        });
        this.toggleCreateEvent();
        data.date=data.time;
        let eventData={
            id: data.id,
            name: {
                value: data.name,
                isValid: true,
                isRequired: true
            },
            date: {
                value: new Date(data.time),
                isValid: true,
                isRequired: true
            },
            dropdownValue: {
                value: data.staff,
                isValid: true,
                isRequired: true
            },
            time: {
                value: new Date(data.time),
                isValid: true,
                isRequired: true
            }
        };
      this.setState({
        editEvent: eventData || "",
        isUpdateEvent:true
      })
    }

    toggleShowEvent = (date) => {
        this.setState({
            isShowEvent: date ? date : false
        })
        this.setState({
            isloading:false
        })
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this._getMemberTeamList();
            this.getEvent();
        });
    };

    addEvent = (params, cb) => {
        console.log("params addevent calender",params);
        
        if(params.id!=undefined){
            this.props.eventActions.editEvent(params, (err, data)=>{
                if(data){
                    this.getEvent();
                    this.setState({
                        isCreateEvent: false
                    })
                }
                console.log("error on edit event",err);
                cb && cb(err, data);
        });
    }
        else{
        this.props.eventActions.addEvent(params, (err, data)=>{
            if(data){
                this.getEvent();
                this.setState({
                    isCreateEvent: false
                })
            }
            cb && cb(err, data);
        })
    }
    };

    goEvent = (date) => {
        this.toggleShowEvent(date);
        // this.openModalWeek(date)
    };

    onChangeMonth = (time) => {
        
        this.setState({
            month: time,
            isloading: true
        },()=>{
            this.getEvent(null, time);
        });
    };

    onChangeyear = (year) => {
        const currentYear = new Date().getFullYear();
        if(year !== currentYear){
           localStorage.setItem('Years', JSON.stringify(year));
          let setChangeYear=  localStorage.getItem('Years')
          parseInt(setChangeYear, 10)
           
          }
        this.setState({
            isloading: true
        },()=>{
            let begin = new Date(year, 0, 1);
            this.getEvent(null, begin);
        });
    };

    onChangeweek = (time) => {
        this.setState({
            isloading: true,
            updatedWeek : time
        },()=>{
            this.getEvent(null, time);
        });
    }

   

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }
    _userFullNameLength = (userFullName)=>{
        let maxLength = 10
        if (userFullName.length > maxLength) {
            return userFullName.substring(0, maxLength) + "...";
        } else {
            return userFullName;
        }
    
    }

    removeEvent = (myTeam) => {
        this.setState({ isloading: true });
    
        this.props.eventActions.removeEvent({ id: myTeam.id }, (err, data) => {
            if (data) {
               this.getEvent()
                setTimeout(()=>{
                    this.setState({
                        isloading: false
                    })
                },8000)
                // window.location.reload();
            } else if (err) {
                Config.alertMess(err);
                this.setState({ isloading: false }); 
            }
            console.log("-----------5");
        });
    
        setTimeout(() => {
            this.setState({
                isUpdateRemoveEventConfirmed: false,
            });
        }, 2000);
    };
    

    removeConfirmpopup = (valu) =>{
         this.setState({ modalOpen: false }, () => {
           
            setTimeout(() => {
                this.setState({ isUpdateRemoveEventConfirmed: true });
            }, 1000);
        });   
    }

    closeRemoveConfirmpopup = () =>{
        setTimeout(() => {
            this.setState({
                isUpdateRemoveEventConfirmed: false
            });
        }, 1000); 
    }

    selectValue = (val) =>{
        console.log("-----------val",val)
        this.setState({
            selectValue : val
        })
    }

    render(){
        let {teamList,memberList} = this.props;
        let dataTab = ['WEEK','MONTH','YEAR'];
        let myTeam = [];
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        let temeopen = this.state.isTeamOpened
        let userMonthEvent = this.state.eventDatas

        if (this.state.listEvent.length === 0) {
            if(myTeam.length === 0 ){
                this.state.isloading = true; 
            } 
        }

        
        const currentDate = new Date(); // Current date and time
        const givenDate = new Date(userMonthEvent.time); // Parse the given date string
        // Compare the dates
         const isBefore = givenDate < currentDate;

         const filteredMembers = memberList?.filter(member => 
            member.role !== "OWNER"  && member.status !== "INVITING"
        );
        
        return(
            <div className="full_w full_h" onClick={this.toggleTeamSelectionClick}>
                <div className="register calendar-view row my-team-detail my-team height-100vh calendar-container" >
                    <div style={{position:'relative', minHeight:'100%'}} className="full_w full_h_1">
                    {this.state.isloading && <Loaderview/>}
                    <Header isSearch={true}
                                teamList={teamList}
                                toggleTeamSelection={toggleTeamSelection}
                                myTeam={myTeam}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />
                                
                        <div className="col-sm-12 display_col padding-0 full_h_1 body_container" style={{position:'relative',marginTop:"23px"}}>
                            <div className={`search-bars col-sm-12 shadow-11 display_row sub-header-fixed1`} style={{zIndex : this.state.isScroll && temeopen === true ? "-1" : "1" , marginTop:"-5px"}}>
                                <div className="search-view" style={{flexWrap:'wrap-reverse', justifyContent:"space-between"}}>
                                    <div className="col-sm-6 display_row tab-view" style={{marginLeft:"18px"}}>
                                        {dataTab.map((key, idx)=>{
                                            return(
                                                <div key={idx} className={"tab-item "+(this.state.flagTab === idx ? 'active' : '')}
                                                     onClick={()=>this.changeTab(idx)}>
                                                    {key}
                                                </div>
                                            )
                                        })}
                                    </div>
                                {/* <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create New Event</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        onClick={this.toggleCreateEvent}>
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                            className="icon-plus"/>
                                    </div>
                                </OverlayTrigger> */}
                                <button className="display_row align-center create_new_event_btn button-not-resp"
                                     onClick={this.toggleCreateEvent}>
                                    {/* <Image src={require('../../assets/images/icon-plus.png')}
                                           className="icon-plus"/> */}
                                    CREATE NEW EVENT
                                </button>
                                </div>
                            </div>
                            {this.state.modalOpen === true && 
                            <Modal show={this.state.modalOpen} onHide={this.popupclose}
                             style={{maxWidth:'100%', margin:'auto'}} className='event_calendar'>
                              <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this.popupclose}/>   

                                <Modal.Body className='event_calendar' style={{marginTop:"25px"}}>
                                    <Image
                                        className="logo remove-member-logo "
                                        src={require("../../assets/images/register/icon-member.png")}
                              
                                    />
                                
                                <div className="Event_title">Event Details</div>
                                    <div className="display_col event-view event-view-popup" style={{marginTop:"40px"}}>
                            <div className="modal-body holiday-body shadow_popup" >
                                <div className="calendar modals-body" style={{width: "879px"}}>
                                    <div className='all-details' >
                                        <div className="profile-details" style={{marginLeft:"4px"}}>
                                            <h5 style={{width:"39%", textAlign:"end" , fontFamily:'open-sans-semibold'}}>Event name:</h5>
                                            <h6 style={{textAlign:"start" , width:"50%"}}>{userMonthEvent ? userMonthEvent.name : ""}</h6>
                                        </div>
                                        <div className="profile-details" style={{ width: "333px", marginTop: "30px", marginLeft:"17px" , textAlign:"justify" }}>
                                            <h5 style={{ width: "78%" , fontFamily:'open-sans-semibold'}}>Assigned To:</h5>
                                            <h6 className="eventUserName" style={{display:"flex" , width:"538px"}}>
                                                {filteredMembers.length === userMonthEvent?.staff?.length ? (
                                                <span>All</span>
                                            ) : (
                                                userMonthEvent?.staff?.slice(0, 5).map((key, index) => {
                                                    console.log("----------------key,", key);
                                                    const staffs = key;
                                                    if (staffs) {
                                                        const userAvator = key?.fullName || "---";
                                                        return (
                                                            <div key={`event-${index}`}>
                                                                <h6 className="user_names"  >
                                                                    {this._userFullNameLength(userAvator)}
                                                                    {index < userMonthEvent?.staff?.length - 1 && ', '}
                                                                </h6>
                                                            </div>
                                                        );
                                                    }
                                                })
                                            )}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='all-details' >
                                        <div className='Already-Scheduled'>
                                            <h5 style={{fontFamily:'open-sans-semibold'}}>Date:</h5>
                                            <h6>{moment(userMonthEvent?.time).format('MMMM Do, YYYY')}</h6>
                                        </div>
                                        <div className='Already-Scheduled' style={{ marginTop: "30px" }}>
                                            <h5 style={{fontFamily:'open-sans-semibold'}}>Time:</h5>
                                            <h6>{moment(userMonthEvent?.time).format('HH:mm')}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: "15.5%" , marginTop:"10px"}}>
                                    <div style={{display:"flex"}}>
                                    {filteredMembers.length === userMonthEvent?.staff?.length ? (
                                         <Image  className="avatar-staff"  src={require("../calendar/teamie-logoavatar.png")}
                                                style={{ width: "37px", height: "37px" , borderRadius:"50%"}}
                                            />
                                            ) : (
                                               userMonthEvent?.staff?.map((keyStaff, idxStaff) => {
                                            const userAvator = keyStaff?.avatar || Config.avaDef;
                                            return (
                                                <div key={keyStaff.id} style={{marginLeft:"-5px" , width:"37px" , height:"37px"}}>
                                                    <Image
                                                    key={keyStaff.id}
                                                        className="avatar-staff"
                                                        alt={keyStaff.name}
                                                        src={userAvator}
                                                        style={{ width: "37px", height: "37px" , borderRadius:"50%"}}
                                                    />
                                                </div>
                                            );
                                        })
                                            )}
                                    </div>
                                </div>
                                {!isBefore && 
                                <div className="cta-buttons event_button">
                                    <Button
                                        className="login_btn_login btn-linear shadow_popup"
                                        style={{ maxHeight: 43, borderRadius: "25px", width: "164px", height: "37px" }}
                                        onClick={() => this.editeTOggleCreateEvent(userMonthEvent)}

                                    >
                                        EDIT
                                    </Button>
                                    <Button
                                        className="btn-linear-orange shadow_popup"
                                        style={{ maxHeight: 43, borderRadius: "25px", width: "164px", height: "37px" }}
                                        onClick={() => this.removeConfirmpopup(userMonthEvent)}
                                    >
                                        DELETE
                                    </Button>
                               </div>
     } 
                            </div>
                            </div>
                                    
                                </Modal.Body>
                            </Modal>
                            }

                            {this.state.isUpdateRemoveEventConfirmed === true &&
                            <Modal show={this.state.isUpdateRemoveEventConfirmed} onHide={this.closeRemoveConfirmpopup} id="popup-change-plan" className={"root-popup-change-plan remove-team-member show_event_modal"} style={{  maxWidth: "100%", margin: "auto" }} >
                                <Modal.Body>
                                        <Image className="logo remove-member-logo" src={require("../../assets/images/register/icon-member.png")} />
                                        <div className="title">Remove Event</div>
                                        <div className="title1 remove-admin">Do you want to remove this Event?</div>
                                        <div className="button_display_row" style={{ padding: "10px 0" }}>
                                        <Button
                                        className="login_btn_login btn-linear-orange shadow_popup"
                                        style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px"}}
                                        onClick={this.closeRemoveConfirmpopup}
                                        >
                                        CANCEL
                                        </Button>
                                        <Button
                                        className="login_btn_login btn-linear shadow_popup"
                                        style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                                        onClick={()=>this.removeEvent(userMonthEvent)}
                                        >
                                        CONFIRM
                                        </Button>
                                        </div>
                                </Modal.Body>
                            </Modal>
                             }


                            <div className="member-view display_col scroll edit-calender" style={{overflowX:"hidden"}} >
                            {this.state.flagTab === 0 && (
                                    <WeekView goEvent={this.goEvent}
                                              listEvent={this.state.listEvent}
                                              onWeekSendData ={this.popupmodal}
                                              modalpopup={this.state.modalOpen}
                                              onChangeweek={this.onChangeweek}
                                              
                                              />
                                )}
                                {this.state.flagTab === 2 && (
                                    <YearView goEvent={this.goEvent}
                                              listEvent={this.state.listEvent}
                                              onsend={this.popupmodal}
                                              onChangeyear={this.onChangeyear}
                                              onYearUpdate={this.handleYearUpdate}
                                            
                                              />
                                )}
                                {this.state.flagTab === 1 && (
                                    <MonthView goEvent={this.goEvent}       
                                               onChangeMonth={this.onChangeMonth}
                                               listEvent={this.state.listEvent}
                                               onWeekSendData={this._weekEvent}
                                               onsend={this.popupmodal}
                                               modalpopup={this.state.modalOpen}

                                               />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <CreateEvent show={this.state.isCreateEvent}
                             idTeam={this.state.idTeam}
                             dataStaff={memberList}
                             addEvent={this.addEvent}
                             handleClose={()=>this.toggleCreateEvent()}
                             editeValue = {this.state.editEvent}
                             isloading={this.state.isloading}
                             isUpdateEvent={this.state.isUpdateEvent}
                             listEvent={this.state.listEvent}
                             />

                <ShowEvent show={this.state.isShowEvent}
                           listEvent={this.state.listEvent}
                            handleClose={this.toggleShowEvent}
                            onsend={this.popupmodal}
                            onSendData={this.editeTOggleCreateEvent} 
                            />

              
            </div>
        )
    }
}

class CreateEvent extends Component {

    constructor(props){
        super(props);
        this.eventForm= {
           name: {
                    value: "",
                    isValid: true,
                    isRequired: true
                },
                date: {
                    value: new Date(),
                    isValid: true,
                    isRequired: true
                },
                dropdownValue: {
                    value: [],
                    isValid: true,
                    isRequired: true
                },
                time: {
                    value: new Date(new Date().setSeconds(0)),
                    isValid: true,
                    isRequired: true
                },
        },
        this.dataForm = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Enter event name",
                feedback: false,
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isName',
            },
            {
                type: 'DATE',
                key: "day",
                id: "day",
                alignPopover:'bottom',
                placeholder:"Date",
                validator: 'isRequired',
                // def_value: '',
                def_value:moment().valueOf(),
                feedback: false,
            },
            {
                type: 'TIME',
                key: "time",
                id: "time",
                class:"rc-time-picker-input",
                alignPopover:'bottom',
                placeholder:"Time",
                feedback: false,
                // def_value: '',
                def_value:moment().format('HH:mm'),
                validator: 'isRequired',
            }
        ];

        // Set default time to the current time (e.g., 12:00 PM or whatever time it is)
   


        this.state = {
            dataChoose:[],
            isLoading: false,
            textContent : "",
            selectedUsers: [],
            isOpen: false,
            selectedTime: null,
            selectedDate: null,
            formData: {
                name: "",
                date: new Date(),
                dropdownValue: "",
                time: null,
            },
          
        };
        this.datePickerRef = React.createRef();
        this.timePickerRef = React.createRef();
        this.isSubmit = false;
    }
    handleTimeChange = (time) => {
        setSelectedTime(time);
        console.log("Selected Time:", time?.format('HH:mm')); // Logs the selected time in 24-hour format
    }
    handleTimeChangeNew = (time) => {
        this.setState({ selectedTime: time });
        console.log("Selected Time:", time ? time.format('HH:mm') : 'None'); // Logs the selected time
    };
    handleDateChangeNew = (date) => {
        this.setState({ selectedDate: date.format('YYYY-MM-DD') });
        console.log("Selected date new one:", date.format('YYYY-MM-DD')); // Logs the selected time
    };

    
    handleChange = (newValue) => {
        // Update the selected users whenever the selection changes
        this.setState({ selectedUsers: newValue });
      };

    onSelectionChange=(value)=> {
        console.log('Selected valuesssssssssssssssss:', value);
        let {dataStaff} = this.props;
        let isAllSelected=value.filter(i=>{if(i.id=='all')return true;});
        if (isAllSelected.length > 0) {
        
            let selectedOption=[];
            if(dataStaff) {
                let checkLogin = localStorage.getItem('LOGIN');
                checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
                dataStaff.filter(i=>{
                        if(checkLogin.user.id !== i.user.id && i.status === 'ACTIVE')
                        {
                            selectedOption.push({id: i.user.id, text:i.name});;
                        }
                    })
            }
            value=selectedOption;
        }
        this.eventForm.dropdownValue.value=value;

    }

    handleChange = (event) => {
        this.eventForm.name.value=event.target.value;
        this.setState({ [event.target.name]: event.target.value });
      };

      handleDateChange = (date) => {
        this.eventForm.date.value=date;

        // Extract date in YYYY-MM-DD format
        const extractedDate = date.toISOString().split("T")[0];

        console.log("eventForm1111111111",this.eventForm);
        // Extract time in HH:MM format
        const extractedTime = this.eventForm.time.value.toTimeString().split(" ")[0].slice(0, 5);

        const combinedDateTime = new Date(`${extractedDate}T${extractedTime}`);

        this.eventForm.dateTime = combinedDateTime;
        console.log("eventForm",this.eventForm);
        
      };
    
      handleTimeChange = (time) => {
       
       // Extract date in YYYY-MM-DD format
       const extractedDate = this.eventForm.date.value.toISOString().split("T")[0];
        if(time){
            this.eventForm.time.value=time
        }else{
            time=this.eventForm.time;
            this.eventForm.time.value=time;
        }
       // Extract time in HH:MM format
       const extractedTime = time.toTimeString().split(" ")[0].slice(0, 5);

       const combinedDateTime = new Date(`${extractedDate}T${extractedTime}`);

       this.eventForm.dateTime = combinedDateTime;

        this.setState({ 
            formData: {
                ...this.state.formData,
                time: time,
              },
            });
            console.log("eventForm",this.eventForm);
      };
    
      handleDropdownChange = (event) => {
        this.eventForm.dropdownValue= event.target.value;
      };

    _handleClose = () => {
        this.setState({
            dataChoose:[]
        });
        if(this.props.handleClose){
            this.props.handleClose();
        }
    };

 

    onChangeSelect = (e, idx) => {
        console.log("-------------------eeee",e.target.value)
        let {dataStaff} = this.props;
        let dataChoose = [];
        for(let eChild in e){
            let dataTeam = dataStaff.filter(i=>i.id === e[eChild].value);
            dataChoose=dataChoose.concat(dataTeam);
        }
        this.setState({
            dataChoose:dataChoose
        })
        console.log("----------datachooose :",dataChoose)
    };
    formValidate = () => {
        let isValid = true;
        console.log("this.eventForm",this.eventForm);
        // Iterate over each field in the eventForm to validate required fields
        for (let field in this.eventForm) {
            const fieldValue = this.eventForm[field].value;
            const { isRequired } = this.eventForm[field];
    
            // Check if the field is required and its value is empty
            if (isRequired && (typeof fieldValue === 'string' && fieldValue.trim() === '') || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
                isValid = false;
            }
        }
        console.log("isValid",isValid);
        return isValid;
    };

    _addEvent = () => {
        if(this.isSubmit) return;
        this.isSubmit = true;
        let {isUpdateEvent,editeValue} = this.props;
        let check1 = this.formValidate();
        let dt = this.eventForm;

        
        if(!check1) {
            this.setState({messageEvent: 'Fill out all fields or input correct details to the fields and try again'});
            setTimeout(()=>{
                this.setState({messageEvent: '',isLoading:false});
                this.isSubmit = false;
            },5000);
            return;
        }
        

        let dataStaffId = [];
        this.eventForm.dropdownValue.value.filter(i=>{if(i.id) dataStaffId.push(i.id); return true;});
        console.log("dataStaffId",dataStaffId);

        let day = moment(dt.date.value.$d).format('DD/MM/YYYY');
        let time = moment(dt.time.value).format('HH:mm');
        let date = moment(day+' '+time, 'DD/MM/YYYY HH:mm').valueOf();
        let isValidTime = moment(day+' '+time, 'DD/MM/YYYY HH:mm').isValid()

        if(!isValidTime){
            this.setState({messageEvent: 'Please select valid time'});
            return
        }
        console.log("<========isValidTime=========>",isValidTime)
        if(moment(day+' '+time, 'DD/MM/YYYY HH:mm')< new Date()){
            this.setState({messageEvent: 'Please select valid time'});
            setTimeout(()=>{
                this.setState({messageEvent: '',isLoading:false});
                this.isSubmit = false;
            },5000);
            return
        }
        this.setState({
            isLoading: true
        });
        let params = {
            team: this.props.idTeam,
            name: dt.name.value,
            time: date,
            staff: JSON.stringify(dataStaffId),
        };
        if(isUpdateEvent){
            params.id = editeValue.id;
        }
        console.log("event params",params);
        this.isSubmit = false;

        if (this.props.addEvent) {
            this.props.addEvent(params, (error, data) => {
                if (error) {
                    this.isSubmit = true;
                    this.setState({
                        messageEvent: error.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                    // setTimeout(()=>{
                    //     this.setState({messageEvent: '',isLoading: false});
                    //     this.isSubmit = false;
                    // },5000);
                }
                else {
                    setTimeout(()=>{
                        this.setState({messageEvent: '',isLoading: false,dataChoose: []});
                        this.isSubmit = false;
                    },5000);
                    // this._handleClose();
                }
            });
        }
    };
    
    ValueContainer = ({ innerProps, selectProps, data }) => {
        let dataChoose = this.state.dataChoose.filter(i => i.id === data.value);
        dataChoose = dataChoose[0] ? dataChoose[0] : null;

        if (!dataChoose) return null;
    
        const divs = document.querySelectorAll('.select__multi-value');
    
        if (divs.length > 5) {
            for (let i = 5; i < divs.length; i++) {
                divs[i].style.display = 'none';
            }
    
            if (!document.querySelector('.and-more') && divs) {
                const andMoreTag = document.createElement('div');
                andMoreTag.className = 'and-more';
                andMoreTag.textContent = 'and more';
    
                const container = document.querySelector('.select__value-container');
                if (container) {
                    container.appendChild(andMoreTag);
                }
    
            }
        }
    
        return (
            <div className="staff-view" style={{ marginLeft: "3px", width: "75px", height: "75px" }}>
                <Image alt="" className="avatar-staff" src={dataChoose.user.avatar ? dataChoose.user.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                <span className="name-staff">{dataChoose.name}</span>
            </div>
        );
    };
    
    handleChildValue = (value) => {
        this.setState({ childValue: value });
        console.log('Received from child:', value);
      };

openCalendar = () => {
    const { isOpen } = this.state; 

    this.setState({ isOpen: !isOpen }, () => {

        if (this.datePickerRef.current) {
            this.datePickerRef.current.setOpen(!isOpen);
        }
    });  
}
    openTimePicker = () => {
        const { isOpen } = this.state; 
        this.setState({ isOpen: !isOpen }, () => {

            if (this.timePickerRef.current) {
                this.timePickerRef.current.setOpen(!isOpen);
            }
        });
    }

    


    render(){
        let {dataStaff,editeValue,isUpdateEvent} = this.props;
        let todayDate=new Date();
        let dataOption = [
            {value: 'all', label:'All', image: require('../../assets/images/icon-all.png')}
        ];
        let {listEvent} = this.props
        let filteredEvents = listEvent.filter(event => event.id === editeValue.id);
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        if(dataStaff) {
            dataStaff.filter(i=>{
                    if(checkLogin.user.id !== i.user.id && i.status === 'ACTIVE')
                    {
                        dataOption.push({value: i.user.id, label:i.name, image: i.user.avatar});
                    } 
                    return true;
                })
        }
        
        this.dataForm[0].def_value = editeValue ? editeValue.name : '';
        this.dataForm[1].def_value = moment().valueOf();
        this.dataForm[2].def_value = moment().format('HH:mm');
        this.eventForm=editeValue ? editeValue : this.eventForm;
        
        console.log("moment().format('HH:mm')",this.eventForm,editeValue);

        let editdate = editeValue.date.value.toISOString();
        

        let selectedValues=[];
        this.eventForm.dropdownValue.value.forEach(element => {
            selectedValues.push(element.id);
        });

        const formatted = moment(this.eventForm.date.value).format('dddd, MMMM DD YYYY');
        const todatDateformatted = moment(todayDate).format('dddd, MMMM DD YYYY');


        let checkDateformate = formatted === todatDateformatted

        const isEdited = !(editeValue?.name?.value === filteredEvents?.[0]?.name &&
            editeValue?.dropdownValue?.value?.length === filteredEvents?.[0]?.staff.length &&
            editdate === filteredEvents?.[0]?.time);
        
        return(
           
            <div>
                  {this.state.isLoading && <Loaderview/> }
            <Modal show={this.props.show} onHide={this._handleClose} className="fix-modal event-schedule" style={{zIndex:this.state.isLoading ? "-1" : ""}}>
               <Modal.Body className='event_modal_body' style={{paddingTop:"26px"}}>
                <Image
                    className="logo remove-member-logo "
                    src={require("../../assets/images/register/icon-member.png")}
               
                />
                {isUpdateEvent ? <div className="Create_event_title">Edit Event</div> :<div className="Create_event_title">Create Event</div>}
                    

            <div className='create_event_modals-body'>
                <div className='row' style={{marginLeft:"-10%"}}>
                    {/* Name Field */}
                    <div className="col-md-7" style={{paddingRight:"0px", paddingLeft:"0px"}}>
                        <input
                        type="text"
                        className="form-control field-form-control"
                        id="name"
                        name="name"
                        value={this.eventForm.name.value}
                        onChange={this.handleChange}
                        required
                        placeholder='Enter event name'
                        maxLength="30"
                        />
                    </div>
                    {/* Date Picker */}
                    <div className="col-md-5 date-input">
                        <FormatedDatePicker onDateChange={this.handleDateChange} selected={this.eventForm.date.value} className="form-control field-form-control"></FormatedDatePicker>
                        {this.state.selectedDate && (
                            <p>Selected Date: {this.state.selectedDate}</p>
                        )} 
                    </div>
                    {/* <div className='col-md-1' style={{display:"flex" , alignItems:"center" , paddingLeft:"0px" , paddingRight:"-1px" , justifyContent:"end" , zIndex:"0" , width:"55px" , cursor:"pointer"}} onClick={this.openCalendar}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                        <path d="M14.6041 1.2024L13.8904 0.488747C13.7953 0.393447 13.6858 0.345947 13.5619 0.345947C13.4383 0.345947 13.3289 0.393447 13.2338 0.488747L7.6233 6.0989L2.0131 0.488897C1.91795 0.393597 1.80845 0.346097 1.68475 0.346097C1.561 0.346097 1.4515 0.393597 1.3564 0.488897L0.6428 1.2026C0.5475 1.2977 0.5 1.4072 0.5 1.53095C0.5 1.6546 0.54765 1.7641 0.6428 1.8592L7.29495 8.5115C7.39005 8.60665 7.4996 8.6542 7.6233 8.6542C7.747 8.6542 7.85635 8.60665 7.9514 8.5115L14.6041 1.8592C14.6992 1.76405 14.7465 1.65455 14.7465 1.53095C14.7465 1.4072 14.6992 1.2977 14.6041 1.2024Z" fill="#31CCBC"/>
                        </svg>
                    </div> */}
                    
                </div>
                <div className="row" style={{marginLeft:"-10%"}}>
                    {/* Dropdown */}
                    <div className="col-md-7 user-input" style={{paddingTop:"8px" , paddingLeft:"0px",paddingRight:"45px"}}>
                        <Select2Component
                        options={dataOption}
                        selectedValue={selectedValues}
                        className="form-control field-form-control"
                        allowClear={true}
                        multiple={true}
                        closeOnSelect={true}
                        onChange={this.onChangeSelect}
                        onSelectionChange={this.onSelectionChange}
                        />    
                    </div>
                    {console.log("-------------selecct users :",this.state.dataChoose)}
                    {/* Time Picker */}
                    {/* <div className="col-md-4 time-input">
                        <DatePicker
                        selected={this.eventForm.time.value}
                        onChange={this.handleTimeChange}
                        className="form-control field-form-control"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Event Time"
                        dateFormat="HH:mm"
                        placeholderText="Select a time"
                        minTime={checkDateformate ? new Date(todayDate.setSeconds(0, 0)) : undefined}
                        maxTime={checkDateformate ? new Date(new Date().setHours(23, 59, 59, 0)) : undefined}
                        ref={this.timePickerRef}
                        />

                    </div>
                    <div className='col-md-1' style={{display:"flex" , alignItems:"center" , paddingLeft:"0px" , paddingRight:"8px" , justifyContent:"end" , zIndex:"0" , width:"55px" , height:"62px" , cursor:"pointer"}} onClick={this.openTimePicker}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                        <path d="M14.6041 1.2024L13.8904 0.488747C13.7953 0.393447 13.6858 0.345947 13.5619 0.345947C13.4383 0.345947 13.3289 0.393447 13.2338 0.488747L7.6233 6.0989L2.0131 0.488897C1.91795 0.393597 1.80845 0.346097 1.68475 0.346097C1.561 0.346097 1.4515 0.393597 1.3564 0.488897L0.6428 1.2026C0.5475 1.2977 0.5 1.4072 0.5 1.53095C0.5 1.6546 0.54765 1.7641 0.6428 1.8592L7.29495 8.5115C7.39005 8.60665 7.4996 8.6542 7.6233 8.6542C7.747 8.6542 7.85635 8.60665 7.9514 8.5115L14.6041 1.8592C14.6992 1.76405 14.7465 1.65455 14.7465 1.53095C14.7465 1.4072 14.6992 1.2977 14.6041 1.2024Z" fill="#31CCBC"/>
                        </svg>
                    </div> */}
                    <div className="col-md-5 calendar-time-picker">
                        <FormatedTimePicker onTimeChange={this.handleTimeChangeNew} className="form-control field-form-control"></FormatedTimePicker>  
                              
                    </div>
                    <div className='col-md-1'>

                    </div>
               
                    
                </div>
            </div>
                     {/* <div className="calendar_create_event modals-body" style={{marginTop:"35px", marginLeft:"-53px",width:"934px"}}>
                         <div className='all-details'>


                            <div className='Create_Event_details'>
                            
                            <CForm data={this.dataForm} ref="CForm" styleForm="form-create-event" />
                            </div>
                             <div className="profile-details2">
                             <div className="drop-staff Create_Event_details">
                            <Select
                                options={dataOption}
                                components={{MultiValueLabel:this.ValueContainer}}
                                placeholder='Assigned to'
                                isMulti={true}
                                className={`open-sans-regular basic-multi-select`}
                                classNamePrefix="select"
                                closeMenuOnSelect={false}
                                onChange={this.onChangeSelect}
                            />
                        </div>
                             </div>
                         </div>
                     </div>   */}

                     <div className="cta-buttons event_button">
                     <Button
                     className="login_btn_login btn-linear-orange shadow_popup"
                     style={{ maxHeight: 43 , borderRadius:"25px", width:"164px" , height:"37px" , fontFamily:'open-sans-semibold' , textAlign:"center"}}
                     onClick={this._handleClose}
                    >
                     CANCEL
                        </Button>
                        <Button
                     className={"login_btn_login btn-linear shadow_popup  "+(this.isSubmit? 'disabled' : '')}
                     style={{ maxHeight: 43 , borderRadius:"25px" , width:"164px" , height:"37px",fontFamily:'open-sans-semibold' , textAlign:"center",cursor: isEdited ? "pointer" : "not-allowed" , opacity:isEdited ? "1.5" :"0.5"}}
                     onClick={isEdited ? this._addEvent : null}
                    >
                     CONFIRM
                    </Button>
                </div>
                    {this.state.messageEvent!=='' && (
                        <div className="register_error" style={{width:'100%', bottom:60, textAlign:'center',alignItems:'flex-end' , height:"30px"}}>
                            {this.state.messageEvent}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            </div>
        )
    }
}

class ShowEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deletetasks:{},
            EventList : [],
            isUpdateRemoveEventConfirmed : true
           
        };
    }
_handleClose = () => {
        const elements = document.getElementsByClassName("modal-content");
     
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
        }

        window.history.pushState({}, '', '/calendar');

        if (this.props.handleClose) {
            this.props.handleClose();
        }
};


_userFullNameLength = (userFullName)=>{
    let maxLength = 15
    if (userFullName.length > maxLength) {
        return userFullName.substring(0, maxLength) + "...";
    } else {
        return userFullName;
    }

}

_editeEvent = (val)=>{
    if (this.props.handleClose) {
       this.props.handleClose();
    }
    this.props.onSendData(val);
}

yearEventDetails = (events) => { 
    console.log("--------------done", events);
    this.props.onsend(events)
};
   
    

render(){
        let {listEvent,show} = this.props;
        let dataEventFilter = listEvent.filter(i=>(moment(i.time).startOf('date').valueOf() === moment(show).startOf('date').valueOf()));
        dataEventFilter = dataEventFilter.sort((i,j)=>{return moment(i.time).valueOf() - moment(j.time).valueOf()});
        let isoTime  = dataEventFilter[0] ? dataEventFilter[0].time : dataEventFilter[0]
        const date = new Date(isoTime);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return(
            <div>
                <Modal show={show ? true : false} onHide={this._handleClose}
                style={{maxWidth:'100%', margin:'auto'}} className='event_calendar'>  
                <Modal.Body className='event_calendar' style={{ position:"fixed"}}>
                    <Image
                        className="logo remove-member-logo "
                        src={require("../../assets/images/register/icon-member.png")}
                    />        
                <div className="Event_title">{formattedDate}</div>
                <div className='multiple-event-pop-up'>
                {dataEventFilter?.map((key, index)=>( 
                    <>
                        <div className="event-card">
                            <div style={{width:"103px" , height:"25px" , textAlign:"justify"}}>
                                <span className="event-time-title">Time :</span>
                                <span className="event-times">{moment(key?.time).format('HH:mm')}</span>
                            </div>
                            <div style={{width:"425px", height:"25px" , textAlign:"justify"}}>
                                <span className="event-names">Event Name :</span>
                                <span className="event-name-titles">{key.name}</span>
                            </div>
                            <div style={{width:"32px", height:"25px" , textAlign:"justify" , cursor:"pointer"}}>
                            <span className="view-btns" role="button" tabIndex="0" onClick={() => this.yearEventDetails(key)}  onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                    e.preventDefault();
                                    this.yearEventDetails(key);
                                    }
                                }}> View</span>

                            </div>
                        </div>
                        <div className='under-line'></div>
                    </>
                ))}
                </div> 
                <Button
                     className="login_btn_login btn-linear shadow_popup "
                     style={{ borderRadius:"25px" , width:"164px" , height:"45px" , marginTop:"42px"}}
                     onClick={this._handleClose}
                    >
                     CLOSE
                    </Button>    
            </Modal.Body>
            
                </Modal>
                
            </div> 
        )
    }
}

class YearView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleEvent: "",
            currentYear: new Date().getFullYear(), 
        };
    }

    componentDidMount = () =>{
        if(localStorage.getItem('getYear')){
            let storedTeamsId = localStorage.getItem('getYear');
            let teamsId = storedTeamsId ? JSON.parse(storedTeamsId) : teamId;
            let teamIds = teamsId.length > 0 ? teamsId[0] : null;

            let notificationYear = new Date(teamIds).getFullYear()
            let currentYear = new Date().getFullYear()

            if(notificationYear !== currentYear){
                this.setState({ currentYear: notificationYear }, () => {
                    this.props.onYearUpdate(this.state.currentYear);
                });
            }
        }
    }

    goEvent = (data) => {
        if (!data) return;
        if (this.props.goEvent) this.props.goEvent(data);
    };

    goSinglePopUp = (data) => {
        this.props.onsend(data);
    };

    changeYear = (direction) => {
        localStorage.removeItem('getYear');
        this.setState((prevState) => ({
            currentYear: prevState.currentYear + direction,
        }),()=>{
            this.props.onChangeyear(this.state.currentYear);
        });
        
    };

    render() {
        const { listEvent } = this.props;
        let { currentYear } = this.state;


        let dataEventFilter = [];
        dataEventFilter = listEvent.sort((i, j) => {
            return moment(i.time).valueOf() - moment(j.time).valueOf();
        });

        return (
            <div className="col-sm-12 year-view" style={{ width: "102%", marginLeft: "0px", marginTop: "9%" }}>
              <div className='change_week_value' style={{marginLeft:"18px" , width:"100px"}}>
            <div onClick={() => this.changeYear(-1)} className='change_icons'>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                <path d="M3.18915 7.62437L8.08203 2.62889C8.30034 2.40545 8.29863 2.04806 8.07747 1.82747L7.20195 0.951953C6.97908 0.729083 6.61713 0.729653 6.39426 0.953093L0.16701 7.22138C0.05586 7.33253 0 7.47845 0 7.62437C0 7.77029 0.05586 7.91621 0.16701 8.02736L6.39426 14.2957C6.61656 14.5197 6.97851 14.5202 7.20195 14.2968L8.07747 13.4213C8.29806 13.2007 8.30034 12.8427 8.08203 12.6199L3.18915 7.62437Z" fill="#31CCBC"/>
                </svg>
            </div>
            <div className='change_daysOfWeek'>
                <p>{currentYear}</p>
            </div>
            <div onClick={() => this.changeYear(1)} className='change_icons'>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                <path d="M5.80011 7.62437L0.907228 2.62889C0.688918 2.40545 0.690628 2.04806 0.911788 1.82747L1.78731 0.951953C2.01018 0.729083 2.37213 0.729653 2.595 0.953093L8.82225 7.22138C8.9334 7.33253 8.98926 7.47845 8.98926 7.62437C8.98926 7.77029 8.9334 7.91621 8.82225 8.02736L2.595 14.2957C2.3727 14.5197 2.01075 14.5202 1.78731 14.2968L0.911788 13.4213C0.691198 13.2007 0.688918 12.8427 0.907228 12.6199L5.80011 7.62437Z" fill="#31CCBC"/>
                </svg>
            </div>
            </div>
                <DayPicker
                    numberOfMonths={12}
                    canChangeMonth={false}
                    month={new Date(currentYear, 0)} 
                    renderDay={(day) => {
                        let date = day.getDate();
                        let dataEventFilter = listEvent.filter(
                            (i) =>
                                moment(i.time).startOf("date").valueOf() === moment(day).startOf("date").valueOf()
                        );
                        let className = dataEventFilter && dataEventFilter.length > 0 ? "hasEvent" : "";
                        let dataEventFilters = dataEventFilter[0];
                        return (
                            <div
                                className={className}
                                onClick={() => {
                                    if (className && dataEventFilter) {
                                        dataEventFilter.length > 1
                                            ? this.goEvent(day)
                                            : this.goSinglePopUp(dataEventFilters);
                                    }
                                }}
                            >
                                {date}
                            </div>
                        );
                    }}
                    captionElement={({ date }) => {
                        return (
                            <div className="DayPicker-Caption">{moment(date).format("MMMM YYYY")}</div>
                        );
                    }}
                />
            </div>
        );
    }
}

class MonthView extends Component {

    constructor(props){
        super(props);
        this.state={
            dataEvent: null,
            showsingleEvent:""
        }
    }

    goEvent = (data) => {
        if(this.props.goEvent) this.props.goEvent(data);
    };

    onChangeMonth = (e) => {
        if(this.props.onChangeMonth) this.props.onChangeMonth(e);
    };

    monthEventDetails = (events) => { 
        console.log("--------------done", events);
        this.props.onsend(events)
    };
    eventNameFormate = (userEventName) =>{
        const formattedName = userEventName.replace(/([a-zA-Z])([A-Z0-9])/g, '$1 $2'); 
        const truncatedName = formattedName.length > 9  ? formattedName.slice(0, 9) + ".."  : formattedName;

        return truncatedName;
    }

    render() {
        let { listEvent } = this.props;
        let { modalpopup} = this.props
      console.log("-----------issssssssss", modalpopup)
        let dataEventFilters = listEvent.sort((i, j) => moment(i.time).valueOf() - moment(j.time).valueOf());
        console.log("----",dataEventFilters)

        return (
            <div className="month-view" style={{ border: 'none', flexWrap: 'wrap' , marginTop:"10%",marginLeft:"16px"}}>
                <DayPicker
                    numberOfMonths={1}
                    canChangeMonth={true}
                    month={new Date()}
                    date={new Date()}
                    showOutsideDays={true}
                    onMonthChange={(e) => this.onChangeMonth(e)}
                    renderDay={(day) => {
                        const date = day.getDate();
                        let dataEventFilters = listEvent.filter(i => moment(i.time).startOf('date').valueOf() === moment(day).startOf('date').valueOf());
                        let className = (dataEventFilters && dataEventFilters.length > 0) ? 'hasEvent' : '';
                       
                        let eventDetails = dataEventFilters.slice(0, 4).map((val, index) => {
                            const eventName = val.name;
                            const eventTime = moment(val.time).format('HH:mm');

                            let truncatedEventName;

                            if (eventName) {
                              if (eventName.length > 3) {
                                truncatedEventName = `${eventName.slice(0, 6)}...`;
                              } else {
                                truncatedEventName = eventName;
                              }
                            } else {
                              truncatedEventName = "---";
                            }
                            

                            const getBottomPosition = (index) => {
                                switch (index) {
                                    case 0: return "26px";
                                    case 1: return "48px";
                                    case 2: return "70px";
                                    case 3: return "93px";
                                    default: return "100px";                                    
                                }
                              };
                          
                            return (
                                    <div className={`event_${index}`} key={index} style={{position:"absolute", bottom: getBottomPosition(index) }}>
                                        
                                    <div className='event_continer'>
                                        <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 15 16" fill="none" className='event_icon'>
                                            <path d="M0 3.92847H13.5714V12.857C13.5714 13.8431 12.7718 14.6428 11.7857 14.6428H1.78571C0.799643 14.6428 0 13.8431 0 12.857V3.92847Z" fill="#EFF3F9"/>
                                            <path d="M13.5714 10.5428V12.857C13.5714 13.1106 13.5179 13.3535 13.425 13.5713C13.1464 14.1999 12.5179 14.6428 11.7857 14.6428H1.78571C0.8 14.6428 0 13.8428 0 12.857V3.92847H1.07143V12.857C1.07143 13.2499 1.39286 13.5713 1.78571 13.5713H8.59286C8.57857 13.4535 8.57143 13.3356 8.57143 13.2142C8.57143 11.4392 10.0107 9.9999 11.7857 9.9999C12.4464 9.9999 13.0607 10.1999 13.5714 10.5428Z" fill="#CFE0F3"/>
                                            <path d="M1.78571 1.07129H11.7857C12.7718 1.07129 13.5714 1.87093 13.5714 2.857V4.28557H0V2.857C0 1.87093 0.799643 1.07129 1.78571 1.07129Z" fill="#0CA0F2"/>
                                            <path d="M1.79536 1.07129H1.78571C0.799643 1.07129 0 1.87093 0 2.857V4.28557H1.07143V2.49986C1.07143 1.91307 1.35857 1.39665 1.79536 1.07129Z" fill="#0588E2"/>
                                            <path d="M4.28599 0.714286C4.28599 0.319797 3.9662 0 3.57171 0C3.17722 0 2.85742 0.319797 2.85742 0.714286V1.78571C2.85742 2.1802 3.17722 2.5 3.57171 2.5C3.9662 2.5 4.28599 2.1802 4.28599 1.78571V0.714286Z" fill="#CFE0F3"/>
                                            <path d="M10.7142 0.714286C10.7142 0.319797 10.3944 0 9.99993 0C9.60544 0 9.28564 0.319797 9.28564 0.714286V1.78571C9.28564 2.1802 9.60544 2.5 9.99993 2.5C10.3944 2.5 10.7142 2.1802 10.7142 1.78571V0.714286Z" fill="#CFE0F3"/>
                                            <path d="M3.57178 0V1.07143C3.57178 1.46607 3.89142 1.78571 4.28606 1.78571V0.714286C4.28606 0.319643 3.96642 0 3.57178 0Z" fill="#EFF3F9"/>
                                            <path d="M10 0V1.07143C10 1.46607 10.3196 1.78571 10.7143 1.78571V0.714286C10.7143 0.319643 10.3946 0 10 0Z" fill="#EFF3F9"/>
                                            <path d="M3.74972 6.07129H2.67829C2.38243 6.07129 2.14258 6.31114 2.14258 6.607V7.67843C2.14258 7.9743 2.38243 8.21415 2.67829 8.21415H3.74972C4.04559 8.21415 4.28544 7.9743 4.28544 7.67843V6.607C4.28544 6.31114 4.04559 6.07129 3.74972 6.07129Z" fill="#B6CAE5"/>
                                            <path d="M7.3215 6.07129H6.25007C5.9542 6.07129 5.71436 6.31114 5.71436 6.607V7.67843C5.71436 7.9743 5.9542 8.21415 6.25007 8.21415H7.3215C7.61737 8.21415 7.85721 7.9743 7.85721 7.67843V6.607C7.85721 6.31114 7.61737 6.07129 7.3215 6.07129Z" fill="#B6CAE5"/>
                                            <path d="M10.8928 6.07129H9.82136C9.52549 6.07129 9.28564 6.31114 9.28564 6.607V7.67843C9.28564 7.9743 9.52549 8.21415 9.82136 8.21415H10.8928C11.1887 8.21415 11.4285 7.9743 11.4285 7.67843V6.607C11.4285 6.31114 11.1887 6.07129 10.8928 6.07129Z" fill="#B6CAE5"/>
                                            <path d="M3.74972 10H2.67829C2.38243 10 2.14258 10.2398 2.14258 10.5357V11.6071C2.14258 11.903 2.38243 12.1429 2.67829 12.1429H3.74972C4.04559 12.1429 4.28544 11.903 4.28544 11.6071V10.5357C4.28544 10.2398 4.04559 10 3.74972 10Z" fill="#B6CAE5"/>
                                            <path d="M7.3215 10H6.25007C5.9542 10 5.71436 10.2398 5.71436 10.5357V11.6071C5.71436 11.903 5.9542 12.1429 6.25007 12.1429H7.3215C7.61737 12.1429 7.85721 11.903 7.85721 11.6071V10.5357C7.85721 10.2398 7.61737 10 7.3215 10Z" fill="#B6CAE5"/>
                                            <path d="M10.8928 10H9.82136C9.52549 10 9.28564 10.2398 9.28564 10.5357V11.6071C9.28564 11.903 9.52549 12.1429 9.82136 12.1429H10.8928C11.1887 12.1429 11.4285 11.903 11.4285 11.6071V10.5357C11.4285 10.2398 11.1887 10 10.8928 10Z" fill="#B6CAE5"/>
                                            <path d="M9.28564 11.1959V11.607C9.28564 11.9027 9.52564 12.1427 9.82136 12.1427H10.8928C11.1885 12.1427 11.4285 11.9027 11.4285 11.607V10.5355C11.4285 10.3305 11.3121 10.1545 11.1428 10.0645C10.3974 10.2159 9.74672 10.6255 9.28564 11.1959Z" fill="#9EB6D8"/>
                                            <path d="M12.1428 15.7143C13.7207 15.7143 14.9999 14.4351 14.9999 12.8571C14.9999 11.2792 13.7207 10 12.1428 10C10.5648 10 9.28564 11.2792 9.28564 12.8571C9.28564 14.4351 10.5648 15.7143 12.1428 15.7143Z" fill="#00B277"/>
                                            <path d="M12.1429 15.0001C13.3263 15.0001 14.2857 14.0407 14.2857 12.8572C14.2857 11.6737 13.3263 10.7144 12.1429 10.7144C10.9594 10.7144 10 11.6737 10 12.8572C10 14.0407 10.9594 15.0001 12.1429 15.0001Z" fill="#00D18D"/>
                                            <path d="M11.7859 13.9286C11.6944 13.9286 11.603 13.8936 11.5334 13.8239L10.8191 13.1097C10.6794 12.97 10.6794 12.7443 10.8191 12.6047C10.9587 12.465 11.1844 12.465 11.3241 12.6047L11.7859 13.0664L12.9619 11.8904C13.1016 11.7507 13.3273 11.7507 13.4669 11.8904C13.6066 12.03 13.6066 12.2557 13.4669 12.3954L12.0384 13.8239C11.9687 13.8936 11.8773 13.9286 11.7859 13.9286Z" fill="#EFF3F9"/>
                                            </svg>
                                        </div>
                                        <div className="month_event_name" onClick={() => this.monthEventDetails(val)}  onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            this.monthEventDetails(val);
                                            }
                                        }}
                                        role="button"
                                        tabIndex="0" style={{fontWeight:"700"}}> {this.eventNameFormate(val.name)}</div>
                                        <div className="month_event_time">{eventTime}</div>
                                    </div>
                                    </div>
                            );
                        });
                           
                        if (dataEventFilters.length > 4) {
                            eventDetails.push(
                                <div
                                className='and_more_option'
                                onClick={() => this.goEvent(day)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    this.goEvent(day);
                                  } }} role="button" tabIndex="0" >
                                {`+ ${dataEventFilters.length - 4} More`}
                              </div>
                              
                            );
                        }
    
                        const dayOnly = parseInt(dataEventFilters[0]?.time.split("T")[0].split("-")[2], 10);
    
                        return (
                            <div className={className}>
                                {date}
                                {dataEventFilters.length > 0 && date === dayOnly && eventDetails}
                            </div>
                        );
                    }}
                    weekdayElement={({ weekday, className, localeUtils, locale }) => {
                        const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
                        return (
                            <div className={className} title={weekdayName}>
                                {weekdayName.slice(0, 3).toUpperCase()}
                            </div>
                        );
                    }}
                    navbarElement={({ nextMonth, previousMonth, onPreviousClick, onNextClick, className, localeUtils }) => {
                        return (
                            <div className={className}>
                                <i className="fa fa-angle-left" onClick={() => onPreviousClick()} />
                                <i className="fa fa-angle-right" onClick={() => onNextClick()} />
                            </div>
                        );
                    }}
                />
                
            </div>
        );
    }
    
    
}


class WeekView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentWeek: new Date(), 
        };
    }

   goToNextWeek = () => {
    this.setState((prevState) => ({
        currentWeek: moment(prevState.currentWeek).add(1, 'week').toDate(),
        
    }),()=>{
        this.props.onChangeweek(this.state.currentWeek);
    });
};

      goToPreviousWeek = () => {
        this.setState((prevState) => ({
            currentWeek: moment(prevState.currentWeek).subtract(1, 'week').toDate(),
        }),()=>{
            this.props.onChangeweek(this.state.currentWeek);
        });
    };

    _weekEvent = (val)=>{
        console.log("----------valu", val)
        this.props.onWeekSendData(val);
    }

   goEvent = (day) => {
    console.log('Event on:', day);
};
eventNameFormate = (userEventName) =>{
    const formattedName = userEventName.replace(/([a-zA-Z])([A-Z0-9])/g, '$1 $2'); 
    const truncatedName = formattedName.length > 15  ? formattedName.slice(0, 15) + "..."  : formattedName;
    return truncatedName;
}

render() {
    let { listEvent } = this.props;
    let { currentWeek } = this.state;

    let dataEventFilters = listEvent.sort((i, j) => moment(i.time).valueOf() - moment(j.time).valueOf());
    console.log("--------dataEventFilters 1",dataEventFilters)
    let startOfWeek = moment(currentWeek).startOf('isoWeek');
    let daysOfWeek = Array.from({ length: 7 }, (_, i) =>
        moment(startOfWeek).add(i, 'days').startOf('day').toDate() 
    );
    console.log("Days of Week:", daysOfWeek.map(day => moment(day).format('YYYY-MM-DD')));
    
    const formattedDate = daysOfWeek[0].toLocaleDateString("en-US", {weekday: "short", month: "long", day: "2-digit",});



    return (

        <div style={{marginTop:"10%",marginLeft:"16px"}}>
            <div className='change_week_value' >
            <div onClick={this.goToPreviousWeek}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  this.goToPreviousWeek();
                }
              }}
              role="button"
              tabIndex="0" 
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                <path d="M3.18915 7.62437L8.08203 2.62889C8.30034 2.40545 8.29863 2.04806 8.07747 1.82747L7.20195 0.951953C6.97908 0.729083 6.61713 0.729653 6.39426 0.953093L0.16701 7.22138C0.05586 7.33253 0 7.47845 0 7.62437C0 7.77029 0.05586 7.91621 0.16701 8.02736L6.39426 14.2957C6.61656 14.5197 6.97851 14.5202 7.20195 14.2968L8.07747 13.4213C8.29806 13.2007 8.30034 12.8427 8.08203 12.6199L3.18915 7.62437Z" fill="#31CCBC"/>
                </svg>
            </div>
            <div className='change_daysOfWeek'>
                <p>{formattedDate}</p>
            </div>
            <div onClick={this.goToNextWeek}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  this.goToNextWeek();
                }
              }}
              role="button"
              tabIndex="0" 
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                <path d="M5.80011 7.62437L0.907228 2.62889C0.688918 2.40545 0.690628 2.04806 0.911788 1.82747L1.78731 0.951953C2.01018 0.729083 2.37213 0.729653 2.595 0.953093L8.82225 7.22138C8.9334 7.33253 8.98926 7.47845 8.98926 7.62437C8.98926 7.77029 8.9334 7.91621 8.82225 8.02736L2.595 14.2957C2.3727 14.5197 2.01075 14.5202 1.78731 14.2968L0.911788 13.4213C0.691198 13.2007 0.688918 12.8427 0.907228 12.6199L5.80011 7.62437Z" fill="#31CCBC"/>
                </svg>
            </div>
            </div>
           
            <div className="week-days">
                {daysOfWeek.map((day, index) => {

           let dataEventFilters = listEvent.filter(i=>(moment(i.time).startOf('date').valueOf() === moment(day).startOf('date').valueOf()));
                  
                    return (
                        <div key={index} className='events-container'>

                            <div className="day-name">
                                {moment(day).format('ddd DD')}
                            </div>
                            <div className='event-container'>
                            {dataEventFilters && dataEventFilters.length > 0 ? (
                                dataEventFilters.map((filter, filterIndex) => (
                                   
                                <div key={filterIndex} className="event-item">
                                  <div className='Week_event_details'>
                                    <p className='week_event-name'>{this.eventNameFormate(filter.name)}</p>
                                    <p className='pop-up-event' onClick={() => this._weekEvent(filter)}
                                     onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                          e.preventDefault();
                                          this._weekEvent();
                                        }
                                      }}
                                      tabIndex="0" 
                                        >View</p>
                                  </div>

                                  <div className='Week_event_details image-containers'>
                                    <div style={{display:"flex" , marginTop:"-8px" , marginLeft:"5px"}}>
                                    {filter?.staff?.slice(0, 3).map((keyStaff, idxStaff) => {
                                        const userAvator = keyStaff?.avatar || Config.avaDef;
                                        return (
                                            <div key={keyStaff.id} className={`avator_${idxStaff}`}>
                                                <Image alt={keyStaff.name} src={userAvator} className='staff-avator'/>
                                            </div>
                                        );})}
                                    </div>
                                    <p className='event-time'>{moment(filter?.time).format('HH:mm')}</p>
                                  </div>
                                </div>
                                
                              
                                ))
                            ) : (
                                <div className='event-item' style={{boxShadow:"none" , display:"flex" , alignItems:"center"}}>
                                    <h6 className="rotate-vertical">NO EVENTS</h6>
                                </div>
                                
                            )}
                            </div>
                            </div>
                      );
                })}
            </div>
        </div>
    );
}

}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        eventList: state.team.eventList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
    })
)(Calendar);