import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import './tasks-page.css'

import CForm from "../libs/cform/cform";
import {browserHistory} from 'react-router';
import Config from "../../config";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import {Image} from "react-bootstrap";
import moment from 'moment';
import * as userActions from "../../redux/user/user_actions";
import {Button} from "react-bootstrap";
import Loaderview from '../loader/loader';
import { Modal, Carousel } from 'react-bootstrap';
import Header from '../header/header';
// import Loaderview from './loaderview.js';

class TasksDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDetail: null,
            imageOpen:false,
            selectedImage: null,
            attachmentFiles: {},
            userRole:''
        };
    }

    componentDidMount(){
        if(window.location.search){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const taskIdParam = urlParams.get('taskId');

        let taskId = null;
        let userId = null;

        if (taskIdParam) {
        const params = taskIdParam.split('?userId=');
        taskId = params[0];
        userId = params[1] || null;
        }       
        
        if(!taskId){
            browserHistory.push('/task');
            return;
        }
        this.props.taskActions.detailTask({id:taskId,userid:userId},(err, data)=>{
            if(data){
                this.setState({
                    taskDetail: data
                }
                //, () => {
                    //let attachments = JSON.parse(this.state.taskDetail.attachments);
                    //attachments.forEach(() => { this.downloadFiles(attachments) });
                    //this.downloadFiles(JSON.parse(this.state.taskDetail.attachments));
                //}
            )
            }
            else if(err){
                Config.popup.show('INFO',[Config.alertMess(err)]);
                setTimeout(()=>{
                    browserHistory.goBack();
                },2000)
            }
        });
        }
    };

    setasCompletedtask = (params) =>{
    
        let status = params.status === 'NEW' ? 'DONE' : '';

        this.props.taskActions.editTask({ id: params.id, status: status }, (err, data) => {
            console.log("---------------paramssss value",data)
            if (err) {
                this.setState({
                    isPopup: true,
                    errorMessage: err.message
                });
            }
            else if(data){
                //window.location.href='/tasks';
                browserHistory.push('/tasks');
                
            }
        });
    }

    _editTask(taskDetail) {
        browserHistory.push({
            pathname: '/create-tasks',
            state: { task: taskDetail }
        });
    }
    
   
    getMimeType(type) {
        switch (type) {
            case 'doc':
                return '';
            case 'docx':
                return '';

            case 'xls':
                return '';
            case 'xlsx':
                return '';

            case 'ppt':
                return '';
            case 'pptx':
                return '';

            case 'pdf':
                return '';

            case 'txt':
                return 'text/plain';

            default:
                return 'image/jpeg';
        }
    }

    renderIcon(file, type) {
        // let ext = file.split('.');
        // ext=ext[ext.length-1];
        // ext=ext.toLowerCase();
        switch (type) {
            case 'doc':
                return (<Image src={require("../../assets/images/file/icon-doc.png")}  style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'docx':
                return (<Image src={require("../../assets/images/file/icon-docx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'xls':
                return (<Image src={require("../../assets/images/file/icon-xls.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'xlsx':
                return (<Image src={require("../../assets/images/file/icon-xlsx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'ppt':
                return (<Image src={require("../../assets/images/file/icon-ppt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'pptx':
                return (<Image src={require("../../assets/images/file/icon-pptx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'pdf':
                return (<Image src={require("../../assets/images/file/icon-pdf.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'txt':
                return (<Image src={require("../../assets/images/file/icon-txt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            default:
                return (<Image src={file} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
        }
    }

    imageOpens = (src) => {
        this.setState({
          imageOpen: true,
          selectedImage: src, 
        });
      };
    
      imageClose = () => {
        this.setState({
            imageOpen: false, 
        }) 
    };
    
    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    render() {
        let {taskDetail, attachmentFiles} = this.state;
        console.log("----------------taskDetail",taskDetail)
        let def_value
        if(taskDetail) {
            def_value =moment(new Date(taskDetail.deadline * 1000)).format('HH:mm DD/MM/YYYY');}
     

        let arrAssignTo = taskDetail && taskDetail.assignTo && Config.isJSON(taskDetail.assignTo) ? JSON.parse(taskDetail.assignTo) : [];
        let attachments = taskDetail && taskDetail.attachments && Config.isJSON(taskDetail.attachments) ? JSON.parse(taskDetail.attachments) : [];

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let uid = checkLogin.user.id;

        let urole = checkLogin?.userRole?.slug
        ;

        let hasNonStaff  = arrAssignTo.some(item => {
            console.log("-------arrAssignTo ID:", item.user.id);
            console.log("-------item:",item);
            return item.user.id === uid || urole === 'owner' || urole === 'manager';
        });
        
        console.log("------------------hasNonStaff", hasNonStaff);
        
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        let isOwnedBy=taskDetail && taskDetail.assignBy && taskDetail.assignBy.id === uid;

        return (
            <>
            <div className="container-fluid tasks-view-detail-page">
                    {!taskDetail && <div style={{marginLeft:"-11px", left:"0px",position:"absolute"}}><Loaderview/></div>  }
                <div className="row task-detail-page-section">
                    
                    <div className="col-md-12">
                        
                        <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>
                        <div className="register staff-info my-team row scroll" style={{marginTop:-30}}>
                            <div className="back-to-page"  onClick={() => { browserHistory.goBack() }} style={{margin:"-106px 0px 0px -100px"}}>
                                <img alt="" src={require('../../assets/images/roster/icon-back-def.png')} style={{width:"12px" , height:"20px" , position:"fixed" , marginLeft:"-19px"}} />
                                <div className='back-text'>Back</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                    {
                        taskDetail && taskDetail.status!="DONE" && <>
                        <div className='task-view-button-section'>
                        {isOwnedBy && <><Button
                                className="btn-linear shadow_popup task-detail-btn"
                                style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                                onClick={() => this._editTask(taskDetail)}
                                >EDIT TASK
                            </Button>
                            </>
                        }
                            <Button
                                className="btn-linear-orange shadow_popup task-detail-btn"
                                style={{ maxHeight: 50,  margin: "0px 0px 0px 20px" , borderRadius:"25px", display:hasNonStaff  ? "": "none"}}
                            onClick={() => this.setasCompletedtask(taskDetail)}
                                >SET AS COMPLETE
                            </Button>
                        </div>
                        </>
                    }
                        <div className="task-detail-desc">
                            <div className="task-content-section">
                                <div className='task-details-titles task-name-title'>{taskDetail? taskDetail.name : null}</div>
                                <div className="task-sub-details">
                                    <div className="task-sub-description">
                                        <div className='task-detail-note task-note-desc'> {taskDetail? taskDetail.description : null}</div>
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="lable-name">Assigned To:</td>
                                                    <td className="label-desciption">
                                                        <span className="user-name">
                                                            {arrAssignTo && arrAssignTo.length > 0 && (
                                                            <>
                                                                {arrAssignTo.slice(0, 2).map((key) => key.name).join(", ")}
                                                                {arrAssignTo.length > 2 && ` +${arrAssignTo.length - 2} more`}
                                                            </>)}
                                                        </span>
                                                    </td>
                                                    <td className="label-image">
                                                        {arrAssignTo && arrAssignTo.length > 0 && (
                                                        <>
                                                        {arrAssignTo.slice(0, 2).map((key, idx, arr) => (
                                                        <Image className="task-avatar-staff" src={(key.user.avatar) ? key.user.avatar : Config.avaDef}/>))}
                                                        {arrAssignTo.length > 2 && (
                                                            <span className=""> +{arrAssignTo.length - 2} more</span>)}
                                                        </> )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="lable-name">Completion Date:</td>
                                                    <td className="label-desciption">{def_value ? def_value : "---"}</td>
                                                    <td className=""></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="task-sub-image">
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[0] && attachments[0]?.src ? attachments[0]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => { attachments[0]?.src ? this.imageOpens(attachments[0].src) : null }} style={{ cursor: !attachments[0]?.src ? "not-allowed" : "" ,display: attachments[0]?.src ? "" : "none" }} />
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[1] && attachments[1]?.src ? attachments[1]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => {attachments[1]?.src ? this.imageOpens(attachments[1]?.src) : null}} style={{cursor:!attachments[1]?.src ? "not-allowed":"",display: attachments[1]?.src ? "" : "none"}}/>
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[2] && attachments[2]?.src ? attachments[2]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => {!attachments[2]?.src ?  null : this.imageOpens(attachments[2]?.src)}} style={{cursor:!attachments[2]?.src ? "not-allowed":"",display: attachments[2]?.src ? "" : "none"}}/>
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[3] && attachments[3]?.src ? attachments[3]?.src : require("../../assets/images/no-image.jpg")}
                                        onClick={() => {attachments[3]?.src ? this.imageOpens(attachments[3]?.src) : null}} style={{cursor:!attachments[3]?.src ? "not-allowed":"",display: attachments[3]?.src ? "" : "none"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="task-image-section"></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal show={this.state.imageOpen} onHide={this.imageClose} id="task-details-images">
                <Modal.Body>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" style={{position:"absolute",top:"0px",right:"1px" , cursor:"pointer"}} onClick={this.imageClose}>
                        <circle cx="11" cy="11" r="10" stroke="#C4C4C4" stroke-width="2"/>
                        <path d="M7.25 7.25L14.75 14.75" stroke="#C4C4C4" stroke-width="2"/>
                        <path d="M14.75 7.25L7.25 14.75" stroke="#C4C4C4" stroke-width="2"/>
                    </svg>
                    <Image className="task-image-show-pop-up task-page-pop-up-image" src={this.state.selectedImage}/>
                </Modal.Body>
            </Modal>
            </>
        )
    }

}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)( TasksDetailPage);