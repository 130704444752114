import React, { Component } from "react";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";

const OpenPickerIcon = styled(KeyboardArrowDownIcon)({
	backgroundColor: "#eef7f6",
	color: "#31ccbc",
	fontSize: "30px",
	width: "30px",
	height: "30px",
	marginTop: "4px",
	"&:hover": {
		backgroundColor: "rgba(0, 0, 0, 0) !important",
	},
});

class FormatDatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: null,
		};
	}

	handleDateChange = (newValue) => {
		this.setState({ selectedDate: newValue });
		if (this.props.onDateChange) {
            this.props.onDateChange(newValue);
        }
		console.log("Selected Date:", newValue ? newValue.format("YYYY-MM-DD") : "None");
	};

	render() {
		return (
			<div
				className={this.props.className}
				style={{ paddingLeft: "0px !important" }}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={this.state.selectedDate}
						onChange={this.handleDateChange}
						format="DD/MM/YYYY" 
						slots={{
							openPickerIcon: OpenPickerIcon,
						}}
						views={["year", "month", "day"]}
						slotProps={{
							popper: {
								sx: {
									"& .MuiPaper-root": {
										backgroundColor: "#fff",
									},
									".MuiPickersCalendarHeader-label, .MuiDayCalendar-weekDayLabel, .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin, .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton": {
										fontFamily: 'open-sans-semibold',
										fontSize: "1rem"
									},
									".MuiPickersCalendarHeader-labelContainer, .MuiPickersCalendarHeader-switchViewButton, .MuiPickersArrowSwitcher-leftArrowIcon, .MuiPickersArrowSwitcher-rightArrowIcon": {
										color: "#31ccbc",
									},
									".Mui-selected, .Mui-selected:hover": {
										color: "#fff",
										backgroundColor: "#31ccbc !important",
										border: "1px solid #31ccbc",
									},
									".MuiPickersDay-dayWithMargin:hover, .MuiPickersYear-yearButton:hover, .MuiPickersMonth-monthButton:hover": {
										color: "#fff",
										backgroundColor: "#f18977",
									},
									".MuiIconButton-edgeEnd": {
										backgroundColor: "rgba(0, 0, 0, 0) !important",
									},
								},
							},
							textField: {
								size: "small",
								placeholder: "Select date here",
								inputProps: { readOnly: true },
								onFocus: (e) => e.target.blur()
							},
						}}
					/>
				</LocalizationProvider>
			</div>
		);
	}
}

export default FormatDatePicker;
