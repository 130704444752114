import
{
    all
} from 'redux-saga/effects';

import{
    watchUploadFile,
    watchLoading,
    watchCheckLogin,
    watchCheckOut,
    watchUpdateNumberBadge,
    watchGetNumberBadge
} from "../redux/main/main_sagas";

import{
    watchRegister,

    watchCheckUpload,
    watchCheckDownload,
    watchCheckUploadMuti,
    watchCheckEmail,
    watchCheckRtoken,
    watchCheckRegtoken,

    watchRegisterPayment,
    watchEditUser,
    watchLoginUser,
    watchLoginUser1,
    watchChangePass,
    watchReteivePayment,
    watchUpdatePayment,
    watchForgotPass,
    watchGetUser,
    watchVerifyUser
} from "../redux/user/user_sagas";

import{
    watchRegisterCompany,
    watchRegisterLink,
    watchEditCompany,
    watchLoginCompany,
    watchChangePassCompany,
    watchInactiveCompany,
    watchUpdatepayment
}
from "../redux/company/company_sagas";


import
{
    watchSearchAnnouncement,
    watchAddAnnouncement,
    watchToggleLikeAnnouncement,
    watchDeleteAnnouncement,
    watchAddComment,
    watchGetComment,
    watchEditComment,
    watchDelComment,
    watchToggleLikeComment,
    watchSearchComment


}
    from "../redux/announcement/announcement_sagas";

import
{
    watchGetListTeam,
    watchGetMemberTeamList,
    watchInviteMember,
    watchRemoveMember,
    watchSearchMemberTeamList,
    watchCreateTeam,
    watchJoinTeam,
    watchSaveTeam,
    watchRemoveTeam,
    watchEditTeam,
    watchToggleClock,
    watchGetTeam,
    watchEditClockOptions
} from "../redux/team/team_sagas";

import
{
    watchGetMemberInfo,
    watchEditMember
} from "../redux/member/member_sagas";

import
{
    watchGetListEvent,
    watchAddEvent,
    watchEditEvent,
    watchSummaryEvent,
    watchRemoveEvent
} from "../redux/event/event_sagas";
import
{
    watchGetListTask,
    watchAddTask,
    watchRemoveTask,
    watchGetDetailTask,
} from "../redux/task/task_sagas";
import
{
    watchGetListReport,
    watchAddReport,
    watchRemoveReport,
    watchGetDetailReport,
} from "../redux/report/report_sagas";

import{
    watchAddShift,
    watchGetListShift,
    watchRemoveShift,
    watchEditShift
} from "../redux/shift/shift_sagas";

import{
    watchAddRoster,
    watchGetListRoster,
    watchRemoveRoster,
    watchEditRoster,
    watchGetRosterClock,
    watchConfirmHolidayRequest
} from "../redux/roster/roster_sagas";

import{
    watchLoadMessage,
    watchLoadGroupMessage,
    watchSearchMessage,
    watchNewMessage,
    watchRoomMessage,
} from "../redux/message/message_sagas";

import{
    watchAddDevice,
} from "../redux/device/device_sagas";

import{
    watchListPackage,
    watchUpgradePackage,
} from "../redux/package/package_sagas";

import{
    watchAddWelcome,
    watchWelcomeList,
    watchEditWelcome,
    watchRemoveWelcome,
} from "../redux/welcome/welcome_sagas";

import{
    watchAddMemberListMangement,
    watchGetMemberTeamListManagement, 
    watchRemoveMemberManagement,  
} from "../redux/management/management_sagas";

export default function* rootSaga() {
    yield all([
        watchCheckLogin(),
        watchCheckOut(),
        watchLoading(),
        watchUploadFile(),
        watchForgotPass(),
        watchChangePass(),
        watchReteivePayment(),
        watchUpdatePayment(),
        watchRegister(),
        watchRegisterLink(),
        watchRegisterPayment(),
        watchVerifyUser(),
        watchEditUser(),
        watchLoginUser(),
        watchLoginUser1(),
        watchCheckEmail(),
        watchCheckRtoken(),
        watchCheckRegtoken(),

        watchCheckUpload(),
        watchCheckDownload(),
        watchCheckUploadMuti(),
        watchGetUser(),
        watchUpdateNumberBadge(),
        watchGetNumberBadge(),

        watchSearchAnnouncement(),
        watchAddAnnouncement(),
        watchToggleLikeAnnouncement(),
        watchDeleteAnnouncement(),
        watchAddComment(),
        watchGetComment(),
        watchEditComment(),
        watchDelComment(),
        watchToggleLikeComment(),
        watchSearchComment(),

        watchRegisterCompany(),
        watchEditCompany(),
        watchLoginCompany(),
        watchChangePassCompany(),
        watchInactiveCompany(),
        watchUpdatepayment(),
        
        watchGetListTeam(),
        watchGetMemberTeamList(),
        watchSearchMemberTeamList(),
        watchInviteMember(),
        watchRemoveMember(),
        watchRemoveTeam(),
        watchEditTeam(),
        watchCreateTeam(),
        watchJoinTeam(),
        watchSaveTeam(),
        watchToggleClock(),
        watchGetTeam(),
        watchEditClockOptions(),

        watchGetMemberInfo(),
        watchEditMember(),

        watchGetListEvent(),
        watchAddEvent(),
        watchEditEvent(),
        watchRemoveEvent(),
        watchSummaryEvent(),

        watchGetListTask(),
        watchAddTask(),
        watchRemoveTask(),
        watchGetDetailTask(),

        watchGetListReport(),
        watchAddReport(),
        watchRemoveReport(),
        watchGetDetailReport(),

        watchAddShift(),
        watchGetListShift(),
        watchRemoveShift(),
        watchEditShift(),

        watchAddRoster(),
        watchGetListRoster(),
        watchRemoveRoster(),
        watchEditRoster(),
        watchGetRosterClock(),
        watchConfirmHolidayRequest(),

        watchLoadMessage(),
        watchSearchMessage(),
        watchNewMessage(),
        watchRoomMessage(),
        watchLoadGroupMessage(),

        watchAddDevice(),

        watchListPackage(),
        watchUpgradePackage(),

        watchAddWelcome(),
        watchWelcomeList(),
        watchEditWelcome(),
        watchRemoveWelcome(),

        watchAddMemberListMangement(),
        watchGetMemberTeamListManagement(),
        watchRemoveMemberManagement(),       
    
    ])
}