
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as rosterActions from "../../redux/roster/roster_actions";
import '../register/register.css'
import {Button, Image,Modal} from 'react-bootstrap';
import Config from "../../config";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import CForm from "../libs/cform/cform";
import SearchBar from "./search-bar";
import {browserHistory} from 'react-router';
import ReactLoading from "react-loading";
import './my-team-detail.css'
import moment from "moment";
import Select from 'react-select';
import Header from '../header/header';
import Loaderview from '../roster/loaderview';
class MyTeamDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            changeTeam: false,
            idTeam: null,
            memberList: null,
            inviteMember:false,
            keyWord: '',
            rosterList:[],
            fixedValue:100,
            toggleTeamSelection:true,
            isTeamOpened:false,
            ListMemberCloseIcons:false
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
    }

    componentDidMount = () => {
        let state = this.props.location.state;
        let id = state && state.id  ? state.id : null;
        if(!id){
            browserHistory.push('/my-team');
        }
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 0) {
            this.setState({
                fixedValue: 100
            });
        } else {
            this.setState({
                fixedValue: 70
            });
        }
    }

    getRoster = () => {
        let dataFilter = this.dataFilter;
        dataFilter.team = this.state.idTeam;
        dataFilter.timeFrom = moment().startOf('day').toISOString();
        dataFilter.timeTo = moment().endOf('year').toISOString();
        // return;
        this.props.rosterActions.getListRoster(dataFilter,(err, data)=> {
            if(err){
                // Config.popup.show('INFO',err.message);
                this.setState({
                    rosterList:[]
                });
            }
            else{
                this.setState({
                    rosterList:data
                });
            }
        });
    };

    searchMember = (data) => {
        this.setState({
            memberList: this.props.memberList
        });
    };

    _setListMember = (data) =>{
        this.setState({
            memberList: data
        })
    };

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                Config.popup.show('INFO',err.message);
                this.setState({
                    memberList: []
                })
            }
            else{
                this.setState({
                    memberList: data
                })
                // that._goScreenTeamDetai();
            }
        });
    };

    changeTeam = () => {
      this.setState({
          changeTeam: !this.state.changeTeam
      })
    };

    changeDropDownTeam = (data, id) => {
        this.setState({
            idTeam: id
        },()=>{
            this.getRoster();
            this.setState({
                toggleTeamSelection:false
            });
        });
    };

    _togglePopupInviteMember = (flag, cb) => {
        this.setState({
            inviteMember: this.state.inviteMember ? false : (flag ? flag : true)
        },()=>{
            cb && cb();
        })
    };

    _inviteMember = (params, cb) => {
        if(this.state.inviteMember === 'ADMIN'){
            params.role = 'ADMIN';
        }
        params.team = this.state.idTeam;
        this.props.teamActions.inviteMember(params, (err, data)=> {
            if(data){
                this._getMemberTeamList();
            }
            cb && cb(err, data);
        });
    };

    _goRemove=(item)=>{
        this.setState({ isUpdateRemoveMemberConfirmed: false });
       this._removeMember(item);
    };
    removeMember = () => {
        this.setState({ isUpdateRemoveMemberConfirmed: true });
      };
  _ConfirmRemove = (item) => {
    this._removeMember(item);
  };
  _removeMember = (item) => {
    this.props.teamActions.removeMember({id:item.id}, (err, data)=> {
        if (err) {}
        else {
            this._getMemberTeamList();
        }
    })
};

_searchMember = (keyword) => {
    const keywordValue = (keyword && keyword.target) ? keyword.target.value : keyword;
    this.setState({ keyWord: keywordValue ? keywordValue : null });
    let condition = '';
    if (keywordValue) {
        condition = JSON.stringify({ "team": this.state.idTeam, "name": keywordValue });
    } else {
        condition = JSON.stringify({ "team": this.state.idTeam });
    }
    this.props.teamActions.searchMemberTeamList({ limit: 1000, skip: 0, condition: condition }, (err, data) => {
        if (err) {
            this._setListMember([]);
        } else {
            this._setListMember(data);
        }
    });
};
    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            // this._init();
            this.getRoster();
        });
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }
    _updated = () => {
        console.log('------------------aassss');
       this.setState({
        ListMemberCloseIcons: !this.state.ListMemberCloseIcons 
       })
    };
    

    render(){
        let {teamList,memberList} = this.props;
        let myTeam = null;
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let isAdmin = null;
        let isOwner = null;
        if(this.state.memberList){
            isAdmin = this.state.memberList.filter(i=>((i.role === 'ADMIN') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
            isOwner = this.state.memberList.filter(i=>((i.role === 'OWNER') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
        }

        let resultFilter = [];

        if(this.state.rosterList && this.state.rosterList.length > 0  && memberList){
            let arrTeam = [...this.state.rosterList];
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (a.staff && a.staff.id && !hash[a.staff.id]) {
                        hash[a.staff.id] = {staff:a.staff,activities: []};
                        resultFilter.push(hash[a.staff.id]);
                    }
                    if(a.staff && a.staff.id) hash[a.staff.id].activities.push(a);
                };
            }(Object.create(null)));

            if(resultFilter.length < memberList.length){
                for(let i in memberList){
                    let arr = resultFilter.filter(j=>j.staff && memberList[i].user && j.staff.id === memberList[i].user.id);
                    if(arr.length === 0){
                        resultFilter.push({staff:memberList[i].user,activities: []});
                    }
                }
            }

            for(let i in resultFilter){
                resultFilter[i].activities = resultFilter[i].activities.sort((a, b) => {return moment(a.time).valueOf() - moment(b.time).valueOf()})
            }
        }
        
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        return(
            <div className="full_w full_h height-100vh my_team_details_container" onClick={this.toggleTeamSelectionClick}>
                <div className="register1 my-team my-team-detail row"  style={{backgroundColor:'transparent'}}>
                    {!myTeam && <div style={{marginLeft:"-11px"}}> <Loaderview/> </div>}
                <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                searchMember={this.searchMember}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />



                    
                    <div className="register member-view scroll h-100 body_container" style={{padding:'auto',marginTop:"12px"}}>
                       
                        {memberList && this.state.memberList && (
                            <ListMember idTeam={this.state.idTeam}
                                        data={this.state.memberList}
                                        admin={true}
                                        checkLogin={checkLogin}
                                        keyWord={this.state.keyWord}
                                        inviteMember={this._togglePopupInviteMember}
                                        removeMember={this._goRemove}
                                        resultFilter={resultFilter}
                                        isAdmin={isAdmin && isAdmin.length > 0}
                                        isOwner={isOwner && isOwner.length > 0}
                                        onsend={this._searchMember}
                                        update={this._updated}
                                        updatedListMemberCloseIcons={this.state.ListMemberCloseIcons}
                            />
                        )}
                        {memberList && this.state.memberList && (
                            <ListMember idTeam={this.state.idTeam}
                                        data={this.state.memberList}
                                        admin={false}
                                        checkLogin={checkLogin}
                                        keyWord={this.state.keyWord}
                                        inviteMember={this._togglePopupInviteMember}
                                        removeMember={this._goRemove}
                                        resultFilter={resultFilter}
                                        isAdmin={isAdmin && isAdmin.length > 0}
                                        isOwner={isOwner && isOwner.length > 0}
                                        onsend={this._searchMember}
                                        update={this._updated}
                                        updatedListMemberCloseIcons={this.state.ListMemberCloseIcons}
                            />
                        )}
                            {this.state.inviteMember &&
                                <PopupInvite show={this.state.inviteMember!==false}
                                             inviteMember={this._inviteMember}
                                             handleClose={this._togglePopupInviteMember}/>
                            }
                    </div>
                </div>
            </div>
        )
    }
}
class PopupInvite extends Component{

    constructor(props){
        super(props);
        this.state = {
            show: null,
            isLoading: false,
        };
        this.dataForm = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Name",
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isName',
                feedback: false,
            },
            {
                type: 'TEXT',
                key: "email",
                id: "email",
                placeholder:"Email",
                validator: 'isEmail',
                def_value: '',
                // def_value:'tranghoang1001@gmail.com',
                feedback: false,
            },
            {
                type: 'TEXT',
                key: "title",
                id: "title",
                placeholder:"Job Title",
                def_value: '',
                // def_value:'123456',
                validator: 'isRequired',
                feedback: false,
            }
        ]
    }

    componentDidMount() {
        this.setState({
            show: this.props.show
        })
    }

    _handleClose = () => {
        this.setState({show: false});
        this._timer = setTimeout(()=>{
            clearTimeout(this._timer);
            if(this.props.handleClose) this.props.handleClose();
        },300);
    };

    _inviteMember = () => {
        if(this.isSubmit || this.state.isLoading) return;
        let check = this.refs['CForm'].formValidate();
        let dt = this.refs['CForm'].formValue();
        this.setState({
            isLoading: true
        });
        let that = this;
        if(!check){
            this.setState({messageInviteMember: 'Fill out all fields or input correct details to the fields and try again'});
            setTimeout(()=>{
                this.setState({
                    messageInviteMember: '',
                    isLoading: false
                });
                this.isSubmit = false;
            },2000);
        }
        if(check) {
            let params = {
                name: dt.name.value,
                title: dt.title.value,
                email: dt.email.value,
            };

            if(this.props.inviteMember) this.props.inviteMember(params,(err, data)=>{
                if(data){
                    this._handleClose();
                }
                else{
                    that.isSubmit = true;
                    that.setState({
                        messageInviteMember: err.message
                    });
                    that._timer = setTimeout(()=>{
                        that.setState({
                            messageInviteMember: '',
                            isLoading: false
                        });
                    },2000);
                    that.isSubmit = false;
                    return;
                }
            });
        }
    };

    render(){

        return(
        
            <Modal show={this.state.show} onHide={this._handleClose} className="fix-modal" id='my-team-details-modal'>
                <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this._handleClose} />
                <Modal.Body>
                    <div className="modal-title">Add Staff Member</div>
                    <div className="sub-title add-staff-sub-title">Enter the job title and email of the new team member here and they will recieve a link to register. Your invoice next month will reflect this change.  </div>
                    <div className='my-team-staffMember-popup'>
                    <CForm data={this.dataForm} ref="CForm"/>
                    </div>
                   
                    <div>
                    {this.state.messageInviteMember!=='' && (
                        <div className="register_error" style={{bottom:56, height:32, textAlign:'center', justifyContent:'center', alignItems:'flex-end', width:'100%'}}>
                            {this.state.messageInviteMember}
                        </div>
                    )}
                    </div>
                    <Button className="blue-btns shadow_popup" style={{borderRadius:"25px",position:"relative", marginTop:"90px"}} onClick={this._inviteMember}>
                        SEND
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading loader-fixed">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                </Modal.Body>
            </Modal>
    
        )
    }
}

class ListMember extends Component{

    constructor(props){
        super(props);
        this.state={
            flagEdit: false,
            flagEditAdmin: false,
            notVerifiedUser:false,
            deletemembers:{},
            searchKeyWord: '', 
        }
    }

    _goTeamDetail = (key) => {
        let {admin} = this.props;
        if((this.state.flagEdit === this.props.idTeam && !admin) || (this.state.flagEditAdmin===this.props.idTeam && admin)) return;
        if(key.status === 'INVITING'){
            this.setState({notVerifiedUser : true})
            // Config.popup.show('INFO','This user has not verified.');
        }
        else{
            browserHistory.push({
                pathname: '/staff-info',
                state: {id:key.id}
            });
        }
    };
    

    _inviteMember = (flag) => {
        if(this.isSubmit) return;
        this.isSubmit = true;
        let that = this;
        if(this.props.inviteMember)
            this.props.inviteMember(flag, ()=>{that.isSubmit = false;});
    };

    _searchgetMemberTeamList = (e) =>{
        console.log("------------------keywords",e)
    }

    removeasAdmin = (item) => {
        this.setState({
          updatePlanPayment: !this.props.memberActions.editMember({
            id: item.id,
            role: "STAFF",
          }),
        });
      };

    _editMember = (admin) => {
        if(admin){
            if(this.props.update){
                this.props.update()
            }
        }
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        let hours = a[0].padStart(2, '0');
        a = hours + ':' + second;
        return a;
    };
    _removeMember = (item) => {
        console.log("-------------item",item)
        this.setState({deletemembers:item});
        this.setState({ isUpdateRemoveMemberConfirmed: true });
      };

    _ConfirmRemove = (item) => {
        this.setState({ isUpdateRemoveMemberConfirmed: false });
        console.log(item);
        this.props.removeMember(item);
      };
    
      _removeMembers = (item) => {
        this.props.teamActions.removeMember({ id: item.id }, (err, data) => {
          if (err) {
          } else {
            this._getMemberTeamList();

          }
        });
      };
    _handleClose = () => {
        setTimeout(()=>{
            this.setState({ isUpdateRemoveMemberConfirmed: false });
        },1000)
        
      };

      _titleLength = (userTitle)=>{
        let maxLength = 15
        if (userTitle.length > maxLength) {
            return userTitle.substring(0, maxLength) + "...";
        } else {
            return userTitle;
        }
    }

    _teamNameLength = (Teamname)=>{
        let maxLength = 15
        if (Teamname.length > maxLength) {
            return Teamname.substring(0, maxLength) + "...";
        } else {
            return Teamname;
        }
    }
    _closenotverifiedUserpopup = () =>{
        this.setState({notVerifiedUser : false})
    }

    handleSearchChange = (e) => {
        const searchKeyWord = e.target.value.slice(0, 25);
    
        this.setState({ searchKeyWord });
        this.props.onsend(searchKeyWord);
    };
    

    render() {
        let { data, admin, isAdmin, isOwner, keyWord, checkLogin, resultFilter,updatedListMemberCloseIcons } = this.props;
        let { searchKeyWord } = this.state;

        let filteredData = data.filter((item) => {
            const name = item.name || (item.user && item.user.fullName) || '';
            return (
                name.toLowerCase().includes(searchKeyWord.toLowerCase())
            );
        });

        let totalAdmin = filteredData.filter((i) => i.role === 'ADMIN');
        let totalMember = filteredData.filter((i) => i.role === 'STAFF');
        let formatDate = {
            sameDay: '[Today]',
            nextDay: 'DD MMM',
            nextWeek: 'DD MMM',
            lastDay: 'DD MMM',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM',
        };

        return (
            <div className="col-sm-12 padding-0" style={{marginTop : !admin ? "40px" : ""}}>
                <div className="my-team-header-container" >
                    
                    <div className="team-title" style={{marginBottom:"10px"}} >
                        {admin ? 'Managers' : 'Team Members'}
                    </div>
                     <div style={{position:"relative",paddingLeft:"45%", display:!admin ? "none" : ""}}> 
                                <input
                                    type="text"
                                    className="search_bars"
                                    placeholder="Search"
                                    maxLength={25}
                                    defaultValue={searchKeyWord}
                                    onChange={this.handleSearchChange}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" className='search-icon'>
  <circle cx="10.3151" cy="10.5235" r="10" fill="#31CCBC"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.91333 9.76096C6.91333 8.17019 8.19948 6.88403 9.79025 6.88403C11.381 6.88403 12.6672 8.17019 12.6672 9.76096C12.6672 11.3517 11.381 12.6379 9.79025 12.6379C8.19948 12.6379 6.91333 11.3517 6.91333 9.76096ZM7.5902 9.7606C7.5902 10.9791 8.57174 11.9606 9.7902 11.9606C11.0087 11.9606 11.9902 10.9791 11.9902 9.7606C11.9902 8.54214 11.0087 7.5606 9.7902 7.5606C8.57174 7.5606 7.5902 8.54214 7.5902 9.7606Z" fill="white"/>
  <path d="M11.5162 11.656L13.767 13.9068" stroke="white" stroke-width="0.945769"/>
</svg>
                        </div>

                 
                        <div className="teams-header-btn">
                   
                            {data.length > 0 && (
                                <Button
                                    className="change-team-btn shadow_popup"
                                    onClick={() => this._editMember('admin')}
                                    style={{ borderRadius: '25px' , display:!admin ? "none" : ""}}
                                >
                                    
                                    {updatedListMemberCloseIcons ? 'CANCEL' : 'EDIT'}
                                </Button>
                            )}
                            {data.length > 0 && (
                                 <Button
                                 className="btn-linear-orange shadow_popup"
                                 onClick={() => this._inviteMember()}
                                 style={{ borderRadius: '25px', width: '144px', height: '32px' , display:!admin?"none":""}}
                             >
                                 ADD NEW
                             </Button>
                            )}
                        </div>
                </div>

                {resultFilter.length === 0 && this.props.onLoadingChange && <Loaderview />}
                <div className="team-view" style={{ display: 'flex', gap: '46.79px', rowGap: '40px' }}>
                    {filteredData && filteredData.length > 0 ? (
                        filteredData.map((key, idx) => {
                            if ((admin && key.role === 'STAFF') || (!admin && key.role !== 'STAFF')) return null;
                            let isRemove = checkLogin.user.id !== key.user.id;
                            let infoRoster = resultFilter.filter((i) => i.staff && key.user.id === i.staff.id);
                            let userTitle = key.title;
                            let Teamname = key.team.name;

                            let Timestart;
                            let clockIn_Time;
                            if (infoRoster?.[0]?.activities?.[0]?.timeStart) {
                                Timestart = this.changeTimeToSecond(infoRoster[0].activities[0].timeStart);
                            }

                            if (infoRoster?.[0]?.activities?.[0]?.clock_in) {
                                clockIn_Time = infoRoster?.[0]?.activities?.[0]?.clock_in;
                            }

                            return (
                                <div className="member-information animation-show col-sm-4 col-lg-4 my-team-card"   
                                style={{cursor:"pointer"}}>
                                  {console.log("-----------------key",key)}
                                    { isRemove && updatedListMemberCloseIcons && (
                                        <Image
                                            src={require('../../assets/images/button_tasks_delete.png')}
                                            className="icon-close icon-remove my-team-close-icon"
                                            
                                            onClick={() => this._removeMember(key)}
                                        />
                                    )}  

                                     <div className="info-in" onClick={()=>this._goTeamDetail(key)}>
                                    <div className='team-card-image'>
                                    <div className='user-image-div'>
                                    <Image
                                        src={`${(key.user && key.user.avatar) ? key.user.avatar : Config.avaDef}`}
                                        className={"team-ava-member "+(admin?"red":'blue')}
                                    />
                                    </div>
                                   
                                    </div>
                                    
                                    <div className="display_col justify-center full_w text-view memberDetails">
                                        <div className={"member-name info "+(admin?"red":'green_left')} style={{position:"relative"}}>{key.name ? key.name : ((key.user && key.user.fullName) ? key.user.fullName : '')}
                                        {(clockIn_Time > Timestart) && infoRoster[0].activities[0].clock_out === '' && infoRoster[0].activities[0].is_clocked_in && (
                                            <Image className="late-working-logo" src={require("../../assets/images/late_image.jpg")} alt=''/>
                                            )}

                                        </div>
                                        <div className="member-title info">
                                        {key.role !== "OWNER" ? (key && key.title ? this._titleLength(userTitle) : this._teamNameLength(Teamname)) : (key && key.team && key.team.name ? this._teamNameLength(Teamname) : this._titleLength(userTitle))}
                                        </div>
                                        <div className="member-time-info" style={{display:"flex",alignItems:"baseline",justifyContent:"space-between",paddingRight:"20px"}}>
                                        {infoRoster && infoRoster[0] && infoRoster[0].activities && infoRoster[0].activities[0] ? (
                                                 clockIn_Time > Timestart && infoRoster[0].activities[0].clock_out === '' && infoRoster[0].activities[0].is_clocked_in  ? (
                                                    <div className="member-time-working-late" style={{marginTop:"17px"}}>LATE TODAY</div>
                                                ) :  infoRoster[0].activities[0].clock_out === '' && infoRoster[0].activities[0].is_clocked_in  ? (
                                                    <div className="member-time-working">WORKING NOW</div>
                                                  
                                                ) : (
                                                    <div className="member-time">
                                                     Next Working : {(infoRoster && infoRoster.length > 0 && infoRoster[0].activities && infoRoster[0].activities.length > 0 && infoRoster[0].activities[0].timeStart !== (undefined) && infoRoster[0].activities[0].timeEnd !== (undefined)) ? ((infoRoster[0].activities[0].timeStart === 0 && infoRoster[0].activities[0].timeEnd === 0 ? 'OFF': moment(infoRoster[0].activities[0].time).calendar(null, formatDate) + ' ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeStart) + ' - ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeEnd))): "OFF"}
                                                    </div>
                                                )
                                            ): (
                                            <div className="member-time">
                                            {key.status === 'INVITING' && <span className="status-pending" style={{marginTop:"7px"}}>PENDING</span>}
                                            <div style={{display:key.status === 'INVITING'? "none" : "flex"}} className='working-div'> Next Working : OFF </div> 
                                            </div>
                                        )}
                                        <div className='view-user-info'>View</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                            );
                        })
                    ) : (
                        // {admin ? 'Managers' : 'Team Members'}
                        <div style={{ display:!admin?"none":"",marginLeft:"43%"}} className='no-members'>No members found</div>
                    )
                    
                    }

                    <Modal show={this.state.notVerifiedUser} onHide={this._closenotverifiedUserpopup} className="remove-user">
                            <Image src={require('../../assets/images/btn-close-popup.png')}className="icon-close" onClick={this._closenotverifiedUserpopup}/>
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" ,color:"#4A4A4A"}}>
                                This user has not verified.       
                                </div>
                            </Modal.Body>
                         </Modal>

                         <Modal
            show={this.state.isUpdateRemoveMemberConfirmed}
            onHide={this._handleClose}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member remove-team-member-pop-up"}
            style={{  maxWidth: "100%", margin: "auto" }}
          >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="modal-title">Remove Member</div>
              <div className="title1 remove-admin" style={{paddingTop:"10px"}}>Do you want to remove this member from the team?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="orange-btns shadow_popup"
                  style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="blue-btns shadow_popup"
                  style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                  onClick={() => this._ConfirmRemove(this.state.deletemembers)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
                    
                </div>
            </div>

            
        );
    }
}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
    })
)(MyTeamDetail);
