// action types
export const types = {
    SEARCH_ANNOUNCEMENT: 'SEARCH_ANNOUNCEMENT',
    ADD_ANNOUNCEMENT: 'ADD_ANNOUNCEMENT',
    LIKE_ANNOUNCEMENT: 'LIKE_ANNOUNCEMENT',
    DISLIKE_ANNOUNCEMENT: 'DISLIKE_ANNOUNCEMENT',
    DELETE_ANNOUNCEMENT:'DELETE_ANNOUNCEMENT',
    
   



};

// commentTypes.js
export const commentTypes = {
    ADD_COMMENT: 'ADD_COMMENT',
    GET_COMMENT: 'GET_COMMENT',
    EDIT_COMMENT: 'EDIT_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    LIKECOMMENT_COMMENT: 'LIKECOMMENT_COMMENT',
    DISLIKECOMMENT_COMMENT: 'DISLIKECOMMENT_COMMENT',
    SEARCH_COMMENT: 'SEARCH_COMMENT',

};


export function getSearchAnn(params, cb) {
    return {
        type: types.SEARCH_ANNOUNCEMENT,
        params,
        cb
    }
}

export function addAnn(params, cb) {
    return {
        type: types.ADD_ANNOUNCEMENT,
        params,
        cb
    }
}


export function toggleLikeAnn(params, cb, isLike) {
    return {
        type: isLike ? types.LIKE_ANNOUNCEMENT : types.DISLIKE_ANNOUNCEMENT,
        params,
        cb
    };
}




export function deleteAnn(params, cb) {
    return {
        type: types.DELETE_ANNOUNCEMENT,
        params,
        cb
    }
}

//Comment section

export function addComment(params, cb) {
    return {
        type: commentTypes.ADD_COMMENT, 
        params,
        cb
    }
}

export function getComment(params, cb){
    return{
        type: commentTypes.GET_COMMENT,
        params,
        cb
    }
}

export function editComment(params, cb){
    return{
        type: commentTypes.EDIT_COMMENT,
        params,
        cb
    }
}
// announcement_actions.js
export function deleteComment(params, cb){
    return{
        type: commentTypes.DELETE_COMMENT,
        params,
        cb
    }
}

export function toggleLikeComment(params, cb) {
    console.log('param like', params);
    console.log('cb like', cb);
    return {
        type: commentTypes.LIKECOMMENT_COMMENT,
        params,
        cb
    };
}

// export function likeComment(params, cb){
//     return{
//         type: commentTypes.LIKECOMMENT_COMMENT,
//         params,
//         cb
//     }
// }
// export function disLikeComment(params, cb){
//     return{
//         type: commentTypes.DISLIKECOMMENT_COMMENT,
//         params,
//         cb
//     }
// }

export function searchComment(params, cb){
    return{
        type: commentTypes.SEARCH_COMMENT,
        params,
        cb
    }
}

