// import {delay} from 'redux-saga';
import {takeLatest, take, cancel} from 'redux-saga/effects';
import {commentTypes} from "./announcement_actions";
import {types} from "./announcement_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

//##############################
export function* searchAnnouncement(data) {
    try {
        let searchData = yield Api.put('/announcement/search', data.params); // Ensure this endpoint returns all announcements
        if (searchData && searchData.data) {
            data.cb && data.cb(null, searchData.data);
        } else {
            if (data.cb) data.cb(searchData, null);
        }
    } catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchSearchAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.SEARCH_ANNOUNCEMENT, searchAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* addAnnouncement(data) {
    try {
        let addData = yield Api.put('/announcement/add', data.params); // Ensure this endpoint adds the announcement correctly
        if (addData && addData.data) {
            data.cb && data.cb(null, addData.data);
        } else {
            if (data.cb) data.cb(addData, null);
        }
    } catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchAddAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.ADD_ANNOUNCEMENT, addAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* toggleLikeAnnouncement(data) {
    try {
        const isLike = data.isLike; // Get isLike from the action
        const endpoint = '/announcement/likeOrDislike'; // Use the new endpoint
        let response = yield Api.put(endpoint, data.params);
        
        if (response && response.data) {
            // Update the state with the new announcement data
            data.cb && data.cb(null, response.data);
        } else {
            if (data.cb) data.cb(response, null);
        }
    } catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchToggleLikeAnnouncement() {
    while (true) {
        const action = yield takeLatest([
            types.LIKE_ANNOUNCEMENT, 
            types.DISLIKE_ANNOUNCEMENT
        ], toggleLikeAnnouncement);
        
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(action);
    }
}








export function* deleteAnnouncement(data) {
    try {
        // Make the API call to delete the announcement
       let deleteData = yield Api.put('/announcement/del', data.params);
        if (deleteData && deleteData.data) {
            data.cb && data.cb(null, deleteData.data);
        } else {
            if (data.cb) data.cb(deleteData, null);
        }
    } catch (e) {
        console.error('Error deleting announcement:', e);
        data.cb && data.cb(null, null);
    }
}

export function* watchDeleteAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.DELETE_ANNOUNCEMENT, deleteAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//comment section 
export function* addComment(data) {
    try {
        let addData = yield Api.put('/an-comment/add', data.params);
        if (addData && addData.data) {
            data.cb && data.cb(null, addData.data);
        } else {
            if (data.cb) data.cb(addData, null);
        }
    } catch (e) {
        data.cb && data.cb(addData, null);
    }
}
export function* watchAddComment() {
    while (true) {
        const watcher = yield takeLatest(commentTypes.ADD_COMMENT, addComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getComment(data) {
    try {
        let getData = yield Api.put('/an-comment/get', data.params);
        if (getData && getData.data) {
            data.cb && data.cb(null, getData.data);
        } else {
            if (data.cb) data.cb(getData, null);
        }
    } catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchGetComment() {
    while (true) {
        const watcher = yield takeLatest(commentTypes.GET_COMMENT, getComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* editComment(data) {
    try {
        let editData = yield Api.put('/an-comment/edit', data.params);
        if (editData && editData.data) {
            data.cb && data.cb(null, editData.data);
        } else {
            if (data.cb) data.cb(editData, null);
        }
    } catch (e) {
        data.cb && data.cb(editData, null);
    }
}
export function* watchEditComment() {
    while (true) {
        const watcher = yield takeLatest(commentTypes.EDIT_COMMENT, editComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteComment(data) {
    try {
        let deleteData = yield Api.put('/an-comment/del', data.params);
        if (deleteData && deleteData.data) {
            data.cb && data.cb(null, deleteData.data);
        } else {
            if (data.cb) data.cb(deleteData, null);
        }
    } catch (e) {
        data.cb && data.cb(deleteData, null);
    }
}
export function* watchDelComment() {
    while (true) {
        const watcher = yield takeLatest(commentTypes.DELETE_COMMENT, deleteComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* toggleLikeComment(data) {
    try {
        const isLike = data.isLike; // Get isLike from the action
        const endpoint = '/an-comment/action'; // Use the new endpoint
        let response = yield Api.put(endpoint, data.params);
        
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            if (data.cb) data.cb(response, null);
        }
    } catch (e) {
        console.error('Error toggling like/dislike:', e);
        data.cb && data.cb(null, null);
    }
}

export function* watchToggleLikeComment() {
    while (true) {
        const action = yield takeLatest(
            commentTypes.LIKECOMMENT_COMMENT
        , toggleLikeComment);
        
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(action);
    }
}





export function* searchComment(data) {
    try {
        let searchData = yield Api.put('/an-comment/search', data.params);
        if (searchData && searchData.data) {
            data.cb && data.cb(null, searchData.data);
        } else {
            if (data.cb) data.cb(searchData, null);
        }
    } catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchSearchComment() {
    while (true) {
        const watcher = yield takeLatest(commentTypes.SEARCH_COMMENT, searchComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}





