import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import '../tasks-page/tasks-page.css'

import { browserHistory } from 'react-router';
import { Button } from "react-bootstrap";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import moment from 'moment';
import Config from "../../config";
import SearchBar from "../my-team-detail/search-bar";
import ReactLoading from "react-loading";
import { Image, Modal } from 'react-bootstrap';
import * as reportActions from "../../redux/report/report_actions";
import _ from 'lodash';
import Loader from '../loader/loader';

import Header from '../header/header';
import Loaderview from '../roster/loaderview';

class ReportPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTaskComplete: false,
            taskList: null,
            isLoadMore: true,
            disLoadMore: false,
            fixedValue: 80,
            toggleTeamSelection: true,
            isTeamOpened: false,
            isDelete: false,
            isloading: false,



        };

        this.perPage = 4;
        this.numPage = 0;

        this.dataFilter = {
            limit: this.perPage,
            skip: this.numPage * this.perPage,
            sort: '[{"createdAt": "DESC"}]'
        }
    }



    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if (scrollTop > 5) {
            this.setState({
                fixedValue: 80
            });
        } else {
            this.setState({
                fixedValue: 80
            });
        }
    }

    getId = () => {
        let location = window.location.href;
        if (location.indexOf('?user=')) {
            let memberId = window.location.search.split('?user=');
            memberId = memberId[memberId.length - 1].split("&tid=");
            memberId = memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else {
            return null;
        }
    };

    _init = (page) => {
        console.log("----------init ", page)
        let condition = {};
        if ((this.state.isLoadMore && this.state.taskList) || this.state.disLoadMore) return;
        this.setState({
            isLoadMore: true
        });

        let dataTemp = this.state.taskList ? this.state.taskList.slice(0) : null;
        if (dataTemp) this.numPage = this.numPage + 1;
        else this.numPage = 0;

        let params = this.dataFilter;
        params.skip = this.numPage * this.perPage;

        console.log("-------------------paramsparams", params)
        console.log("-------------------this.state.activeTaskComplete", this.state.activeTaskComplete)

        if (!this.state.activeTaskComplete) {
            this.setState({ isDelete: false })
        }

        if (page || page === 0) {
            params.skip = 0;
        }
        let checkLogin = localStorage.getItem('LOGIN');
        let UserId = null;
        if (checkLogin) {
            checkLogin = JSON.parse(checkLogin);
            UserId = checkLogin.user.id;
        }

        params.teamid = this.state.idTeam;
        params.resolved = this.state.activeTaskComplete ? true : false;

        console.log("----------data-----params---", params)
        this.props.reportActions.getListReport(params, (err, data) => {
            console.log("----------data-----data---", data)
            if (err) {
                Config.alertMess(err);
                this.numPage = this.numPage > 0 ? this.numPage - 1 : 0;
                this.setState({
                    taskList: [],
                    isLoadMore: false,
                    disLoadMore: 0,
                    isloading: false,
                });
            }
            else if (data) {
                this.setState({
                    taskList: dataTemp && (!page && page !== 0) ? dataTemp.concat(data) : data,
                    isLoadMore: false,
                    disLoadMore: data.length < this.perPage,
                    isloading: false,
                })
            }
        });
    };

    reload = () => {
        let params = {
            limit: this.dataFilter.limit + this.dataFilter.skip,
            skip: 0,
            sort: this.dataFilter.sort,
            condition: this.dataFilter.condition
        };
        this.props.reportActions.getListReport(params, (err, data) => {
            if (err) {
                this.setState({
                    taskList: [],
                });
            }
            else if (data) {
                this.setState({
                    taskList: data,
                })
            }
        });
    };

    _checkTitleChangeComplete = (flag) => {
        console.log("flagflagflag", flag);
        console.log("-----------------doneee", flag, this.state.activeTaskComplete)
        if (flag === this.state.activeTaskComplete) return;
        console.log("-----------------doneee")
        this.numPage = 0;
        this.dataFilter.skip = 0;
        this.setState({
            activeTaskComplete: flag,
            disLoadMore: false,
            taskList: null
        }, () => {
            this._init();
        })
    };

    // deleteTask = (item) => {
    //     console.log("Deleting task with ID:", item.id); 
    //     this.props.taskActions.removeTask({ id: item.id }, (err, data) => {
    //         if (data) {
    //             console.log("Task deleted successfully:", data);
    //             this.reload(); 
    //         } else if (err) {
    //             console.error("Error deleting task:", err);
    //         }
    //     });
    // };

    setDataListTeam = (data, id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        }, () => {
            this._init(0);
        });
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id,
            disLoadMore: false,
            isDelete: false
        }, () => {
            this._init(0);
            this.setState({
                toggleTeamSelection: false,
                isloading: true
            });
        });
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection", this.state.toggleTeamSelection, this.state.isTeamOpened);
        if (this.state.isTeamOpened) {
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else {
            this.setState({
                toggleTeamSelection: true
            });
        }
    }
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }

    handleChildData = (isDelete) => {
        console.log("----------isDelete", isDelete)
        console.log("----------activeTaskComplete", this.state.activeTaskComplete)
        this.setState({ isDelete });
    }

    deleteTask = (item) => {
        this.setState({ isloading: true })
        if (this.isSubmit) return;
        this.isSubmit = true;
        this.props.reportActions.removeReport({ id: item.id }, (err, data) => {
            console.log(data);
            this.isSubmit = false;
            if (data) {
                this.isSubmit = false;
                setTimeout(()=>{
                    this.setState({ isloading: false })
                },2000)
                this.setState({
                    activeTaskComplete: false
                }, () => {
                    this._checkTitleChangeComplete(true);
                });
                
            }
            else if (err) {
                Config.alertMess(err);
                setTimeout(()=>{
                    this.setState({ isloading: false })
                },2000)
            }
        });

    };

    render() {
        let { teamList, memberList } = this.props;
        let { taskList, isLoadMore } = this.state;
        let myTeam = [];
        if (teamList && this.state.idTeam) {
            myTeam = teamList.find(i => { return (i.id === this.state.idTeam) });
        }
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        return (
            <div className="register1 calendar-view row my-team-detail my-team task-view full_w height-100vh" onClick={this.toggleTeamSelectionClick}>
                <div className="col-sm-12 display_col padding-0 full_h_1" style={{ position: 'relative' }}>
                    {!this.state.taskList && <div style={{ marginLeft: "-11px" }}><Loaderview /></div>}
                    {this.state.isloading && <div style={{ marginLeft: "-11px" }}><Loaderview /></div>}
                    <Header isSearch={true}
                        teamList={teamList}
                        myTeam={myTeam}
                        toggleTeamSelection={toggleTeamSelection}
                        dataListTeam={this.setDataListTeam}
                        changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                        isTeamOpened={this.isTeamOpened}
                    />

                    <div className="tasks-view full_h display_col  body_container">
                        <div className="col-sm-12 padding-0 display_col" style={{ height: 'calc(100vh - 170px)', paddingTop: "23px" }}>
                            <ListReportTab
                                activeTask={this.state.activeTask}
                                activeTaskComplete={this.state.activeTaskComplete}
                                checkTitleChangeComplete={this._checkTitleChangeComplete}
                                sendDataToParent={this.handleChildData}
                                deleteMode={this.state.deleteMode}
                                vale={this.state.isDelete}

                            />

                            <ListReportView
                                sampleData={taskList}
                                loadMore={this._init}
                                isLoadMore={isLoadMore}
                                checkLogin={checkLogin}
                                memberList={memberList}
                                activeTaskComplete={this.state.activeTaskComplete}
                                _deleteReport={this.state._deleteReport}
                                deleteTask={this.deleteTask}
                                vale={this.state.isDelete}
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

class ListReportTab extends Component {

    constructor(props) {
        super(props);

        this.state = { deleteMode: false, resetValue: this.props.vale };

    }


    toggleDeleteMode = () => {
        this.setState(
            (prevState) => ({ deleteMode: !prevState.deleteMode }),
            () => {

                this.props.sendDataToParent(this.state.deleteMode);
            }
        );
    };

    handleCancel = () => {
        this.setState({ deleteMode: false }, () => {
            this.props.sendDataToParent(false);
        });
    };


    _checkUpcoming = (flag) => {
        console.log("---------flag", flag)


        if (this.props.checkTitleChangeComplete) this.props.checkTitleChangeComplete(flag);
    };


    render() {
        let { activeTaskComplete, vale } = this.props;
        let { deleteMode } = this.state;


        console.log("----this. valu", this.props.vale)
        return (
            <div className="title display_row align-between" style={{ marginTop: "18px" }}>
                <div className="" style={{ display: "flex", gap: "99px" }}>
                    <span className={"completed_tasks " + (!activeTaskComplete ? "active" : '')}
                        onClick={() => this._checkUpcoming(false)}>
                        OPEN
                    </span>
                    <span className={"upcoming_tasks " + (activeTaskComplete ? "active" : '')}
                        onClick={() => this._checkUpcoming(true)}>
                        CLOSED
                    </span>
                </div>
                {!activeTaskComplete && <Button className="btn-linear-orange shadow_popup" style={{ width: 150, height: 35 }}
                    onClick={() => { browserHistory.push('create-report') }}>CREATE NEW REPORT</Button>}
                {activeTaskComplete && <Button
                    className="btn-linear-orange shadow_popup"
                    style={{ width: 145, height: 35 }}
                    onClick={deleteMode ? this.handleCancel : this.toggleDeleteMode}
                >
                    {vale ? "CANCEL" : "DELETE MODE"}
                </Button>}


            </div>

        );
    }
}


class ListReportView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deletetasks: {},
            isUpdateRemoveTeamConfirmed: false,

        };

        // this.state = {  _deleteReport: false,};

    }

    // handleClickDelete = () => {
    //     this.setState((prevState) => ({ _deleteReport: !prevState._deleteReport }));
    // };

    isJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return [];
        }
        return JSON.parse(str);
    };

    deleteTask = (item) => {
        if (this.props.deleteTask) this.props.deleteTask(item);
        console.log("-----------------delete------------", item)
    };

    handleScrollFeed = (event) => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            if (this.props.loadMore) this.props.loadMore();
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', _.debounce(this.handleScrollFeed, 200));
    }

    _removeTask = (item) => {
        this.setState({ deletetasks: item });
        this.setState({ isUpdateRemoveTeamConfirmed: true });
    };

    _ConfirmRemove = (item) => {
        console.log("--------------itemccccccccc", item)
        this.deleteTask(item)
        setTimeout(() => {
            this._handleClose();
        }, 300);
    };

    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false, vale: false });
    };

    //   _reportRemove = () => {
    //     this.setState({ isUpdateRemoveTeamConfirmed: true });
    // };




    render() {
        let { sampleData, isLoadMore, memberList, checkLogin, activeTaskComplete, vale, } = this.props;

        console.log("------------ccccccc", vale)


        return (
            <div className="full_h_1 scroll task-page-scrool" onScroll={this.handleScrollFeed} style={{ height: '100%', marginTop: "50px", width: "auto", marginLeft: "-11px" }}>
                <div className="task-list-view">
                    <div className="member-information animation-show col-sm-12"
                        style={{ marginBottom: 20, marginTop: "5px" }} ref={e => this.listView = e}>
                        {sampleData && sampleData.map((key, idx) => {

                            console.log("sampleData", sampleData);
                            console.log("sampleData key", key);

                            let assignTo = key.assignTo && this.isJSON(key.assignTo) ? JSON.parse(key.assignTo) : [];
                            console.log("----------- key.attachments", JSON.stringify(key.attachments, null, 2));
                            console.log("----------- key.attachments", JSON.stringify(key.attachments, null, 2));
                            const imgClass = idx === 0
                                ? 'users-profiles first'
                                : idx === 1
                                    ? 'users-profiles second' : ""
                            // : idx === 2 
                            // ? 'users-profiles third' 
                            // : 'users-profiles fourth';
                            let attachments = [];

                            try {
                                if (key.attachments && this.isJSON(key.attachments)) {
                                    attachments = JSON.parse(key.attachments);
                                }
                            } catch (error) {
                                console.error("Error parsing attachments:", error);
                            }

                            console.log("--------------attachments image----", attachments);


                            let allStaff = memberList ? memberList.filter(i => i.role !== 'OWNER' && (i.user && checkLogin && checkLogin.user && i.user.id !== checkLogin.user.id)) : [];
                            let lastDate = new Date(key.deadline * 1000)
                            return (
                                <div className="shadow-1 info-in" key={"data-" + idx}>
                                    <div className='task-details'>
                                        <div className="task-image">
                                            <img src={attachments[0] && attachments[0]?.src ? attachments[0]?.src : require("../../assets/images/no-image.jpg")}
                                                alt={key.title}
                                                className='task-attachments-profile'
                                            />
                                        </div>

                                        <div>
                                            <div className='task-title'>{key.title}</div>
                                            <div className='task-instructions'>{key.note.length > 100 ? key.note.substring(0, 100) + "..." : key.note}</div>
                                        </div>
                                    </div>
                                    <div className='task-user-details'>
                                        <div className="task-assignee-details">
                                            <div className="task-assignee-section">
                                                <div className="assignee-image-section">
                                                    {assignTo.slice(0, 4).map((keyx, idxx) => {
                                                        const imgClass = idxx === 0
                                                            ? 'users-profiles first'
                                                            : idxx === 1
                                                                ? 'users-profiles second'
                                                                : idxx === 2
                                                                    ? 'users-profiles third'
                                                                    : 'users-profiles fourth';

                                                        return (
                                                            <img
                                                                key={idxx}
                                                                src={keyx.user && keyx.user.avatar ? keyx.user.avatar : Config.avaDef}
                                                                alt={keyx.title}
                                                                className={imgClass}
                                                            />
                                                        );
                                                    })}
                                                    {assignTo.length > 4 && <span className={"plus_add assignee-count"}> and {assignTo.length - 4} more</span>}
                                                </div>
                                                <div className='assigned-staff-name'>
                                                        Reported to {allStaff.length === assignTo.length ? 'All Staff' : assignTo.map((e, idx) => e.name + (idx !== assignTo.length - 2 ? (idx !== assignTo.length - 1 ? ", " : '') : ' and '))}
                                                    </div>
                                                {key.status !== 'DONE' && <div className='tbc-time'>{key.date}</div>}


                                            </div>

                                            <div className="view-button-section">
                                                {!vale ? (<div className='task-view-btn' style={{ cursor: 'pointer' }} onClick={() => { browserHistory.push(`/report-detail?reportId=${key.id}?userId=${key.userid}`) }}> <p>VIEW</p></div>) : (
                                                    <div className='task-view-btn' style={{ cursor: 'pointer' }} onClick={() => { this._removeTask(key); }}>
                                                        <p style={{ color: '#EC6E68' }} >DELETE</p></div>)}
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            )
                        })}
                        <div style={{ height: "1px" }}></div>
                        {!sampleData &&
                            <div className="display_row align-center justify-center" style={{
                                width: '100%',
                                borderRadius: 25,
                                padding: 10,
                                position: 'absolute',
                                height: '100%'
                            }}>
                                <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                            </div>
                        }
                        {!sampleData || (sampleData && sampleData.length === 0) &&
                            <div className="full_w display_row align-center justify-center h-100 align-center task-empty-section-content">
                                <div className="txt-not-task no-content">
                                    {activeTaskComplete ? "Your completed reports list will appear here" : "Your assigned reports list will appear here"}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Modal
                        show={this.state.isUpdateRemoveTeamConfirmed}
                        onHide={this._handleClose}
                        id="popup-change-plan"
                        className={"root-popup-change-plan remove-team-member"}
                        style={{ maxWidth: "100%", margin: "auto" }}
                    >

                        <Modal.Body>
                            <Image
                                className="logo remove-member-logo"
                                src={require("../../assets/images/register/icon-member.png")}
                            />
                            <div className="title">Remove Report</div>
                            <div className="title1 remove-admin">Do you want to remove this report?</div>
                            <div className="button_display_row" style={{ padding: "10px 0" }}>

                                <Button
                                    className="login_btn_login btn-linear-orange shadow_popup"
                                    style={{ maxHeight: 50, margin: "0px 43px 0px 0px", borderRadius: "25px" }}
                                    onClick={this._handleClose}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    className="login_btn_login btn-linear shadow_popup"
                                    style={{ maxHeight: 50, margin: "0 5px", borderRadius: "25px" }}
                                    onClick={() => this._ConfirmRemove(this.state.deletetasks)}
                                >
                                    CONFIRM
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )

    }

}


export default connect(state => ({
    memberList: state.team.memberList,
    taskList: state.task.taskList,
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
}),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch),
    })
)(ReportPage);