// action types
export const types = {
    REPORT_LIST: 'REPORT_LIST',
    REPORT_LIST_SUCCESS: 'REPORT_LIST_SUCCESS',
    REPORT_ADD: 'REPORT_ADD',
    REPORT_ADD_SUCCESS: 'REPORT_ADD_SUCCESS',
    REPORT_REMOVE: 'REPORT_REMOVE',
    REPORT_RESOLVED: 'REPORT_RESOLVED',
    REPORT_REMOVE_SUCCESS: 'REPORT_REMOVE_SUCCESS',
    REPORT_DETAIL: 'REPORT_DETAIL',
};

export function getListReport(params,cb) {
    return{
        type: types.REPORT_LIST,
        params,
        cb
    }
}

export function addReport(params,cb) {
    return{
        type: types.REPORT_ADD,
        params,
        cb
    }
}

export function resolvedReport(params,cb) {
    return{
        type: types.REPORT_RESOLVED,
        params,
        cb
    }
}

export function removeReport(params,cb) {
    return{
        type: types.REPORT_REMOVE,
        params,
        cb
    }
}

export function detailReport(params,cb) {
    return{
        type: types.REPORT_DETAIL,
        params,
        cb
    }
}
