
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import '../register/register.css'
import './my-team.css'
// import {browserHistory} from 'react-router';
import {Button} from 'react-bootstrap';
// import Config from "../../config";

import {bindActionCreators} from 'redux';
import {browserHistory} from 'react-router';
import { connect } from 'react-redux';
import CForm from "../libs/cform/cform";
import ReactLoading from "react-loading";

class CreateTeam extends Component{
    constructor(props){
        super(props);
        this.state={
            dataSent:[],
            messageMember:'',
            messageTeam:'',
            messageCreateTeam:'',
            idTeam: null,
            flagInvite:true,
            isLoading: false,
            isLoadingSend: false,
        };
        this.dataForm1 = [
            {
                type: 'TEXT',
                key: 'teamName',
                id: 'teamName',
                feedback: false,
                placeholder: 'Name',
                label:'Team Name (if different from company name)',
                className: '',
                disabled:this.state.idTeam!==null,
                def_value: '',
                validator: 'isRequired',
                maxLength: 25
            }
        ];

        this.dataDef = [
            {
                type: 'TEXT',
                key: 'memberName',
                id: 'memberName',
                label:'Add Team Member',
                feedback: false,
                placeholder: 'Name',
                className: '',
                def_value: '',
                // def_value: 'Trang Hoàng',
                validator: 'isRequired'
            },
            {
                type: 'TEXT',
                key: 'jobTitle',
                id: 'jobTitle',
                feedback: false,
                passIcon:false,
                placeholder: 'Job Title',
                className: '',
                def_value: '',
                // def_value: 'Staff',
                validator: 'isRequired'
            },
            {
                type: 'TEXT',
                key: 'email',
                id: 'email',
                feedback: false,
                passIcon:false,
                placeholder: 'Email',
                className: '',
                def_value: '',
                // def_value: 'c01@email.com',
                validator: 'isEmail'
            }
        ];

        this.dataForm2 = [
            {
                type: 'TEXT',
                key: 'memberName',
                id: 'memberName',
                label:'Add Team Member',
                feedback: false,
                placeholder: 'Name', 
                className: '',
                def_value: '',
                // def_value: 'Trang Hoàng',
                validator: 'isRequired'
            },
            {
                type: 'TEXT',
                key: 'jobTitle',
                id: 'jobTitle',
                feedback: false,
                passIcon:false,
                placeholder: 'Job Title',
                className: '',
                def_value: '',
                // def_value: 'Staff',
                validator: 'isRequired'
            },
            {
                type: 'TEXT',
                key: 'email',
                id: 'email',
                feedback: false,
                passIcon:false,
                placeholder: 'Email',
                className: '',
                def_value: '',
                // def_value: 'c01@email.com',
                validator: 'isEmail'
            }
        ];
    }

    _createTeam = () => {
        if(this.isSubmit || this.state.isLoading) return;
        let dataTeam = this._checkInfoTeam();
        if(!dataTeam) return;

        this.isSubmit = true;
        this.setState({
            isLoading: true
        });
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let userRole = checkLogin && checkLogin.userRole ? checkLogin.userRole.slug : null;
        let companyOwner=checkLogin && checkLogin.company ? checkLogin.company.owner : null;
        let params = {
            name: dataTeam.name,
            userRole: userRole,
            companyOwner: companyOwner,
        };

        console.log('Teams Param',params);

        this.props.teamActions.createTeam(params,(error, data)=> {
            if(error){
                this.setState({
                    messageTeam: error.message,
                    isLoading:false
                });
                this.isSubmit = false;
                // this._timer = setTimeout(()=>{
                //     this.setState({
                //         messageTeam: '',
                //         isLoading:false
                //     });
                //     this.isSubmit = false;
                // },3000);
            }
            else{
                this.dataForm1[0].def_value = data.name;
                this.setState({
                    idTeam: data.id,
                    isLoading:false
                });
                this.isSubmit = false;
            }
        });
    };

    _checkInfoTeam = () => {
        let check1 = this.refs['CForm1'].formValidate();
        let dt1 = this.refs['CForm1'].formValue();

        if(!check1) {
            this.isSubmit = true;
            this.setState({messageTeam: 'Fill out all fields or input correct details to the fields and try again'});
            this._timer = setTimeout(()=>{
                this.setState({messageTeam: ''});
                this.isSubmit = false;
            },5000);
            return;
        }

        let params = {
            name: dt1.teamName.value,
        };

        return params;

    };

    _checkInfoMember = () => {
        let dataTem = this.state.dataSent.slice();

        if(dataTem.length > 10){
            this.isSubmit = true;
            this.setState({messageMember: 'Maximum is 10 member'});
            this._timer = setTimeout(()=>{
                this.setState({messageMember: ''});
                this.isSubmit = false;
            },5000);
            return;
        }

        let check = this.refs['CForm2'].formValidate();
        let dt = this.refs['CForm2'].formValue();

        if(!check) {
            this.isSubmit = true;
            this.setState({messageMember: 'Fill out all fields or input correct details to the fields and try again'});
            this._timer = setTimeout(()=>{
                this.setState({messageMember: ''});
                this.isSubmit = false;
            },5000);
            return;
        }

        let arrEmail = dataTem.find((x) => {return(x.email === dt.email.value)});

        if(arrEmail){
            this.isSubmit = true;
            this.setState({messageMember: 'Email is exited'});
            this._timer = setTimeout(()=>{
                this.setState({messageMember: ''});
                this.isSubmit = false;
            },5000);
            return;
        }

        let params = {
            name: dt.memberName.value,
            title:dt.jobTitle.value,
            email:dt.email.value,
        };

        dataTem.push(params);

        return params;
    };

    _inviteMember = () => {
        if(this.isSubmit || this.state.isLoadingSend){
            return;
        }
        let dataMember = this._checkInfoMember();
        if(!dataMember) return;
        this.isSubmit = true;
        dataMember.team = this.state.idTeam;
        this.setState({
            isLoadingSend: true,
            flagInvite: false
        });
        this.props.teamActions.inviteMember(dataMember, (err, data)=> {
            if(data){
                let dataTem = this.state.dataSent.slice();
                dataTem.push(data);
                this.setState({
                    messageMember: '',
                    dataSent:dataTem,
                    flagInvite: true,
                    isLoadingSend: false
                });
                this.isSubmit = false;
            }
            else if(err){
                this.setState({
                    messageMember: err.message,
                    flagInvite: true,
                    isLoadingSend: false
                });
                this.isSubmit = false;
                this._timer = setTimeout(()=>{
                    this.setState({
                        messageMember: '',
                        // flagInvite: true,
                        // isLoadingSend: false
                    });
                    // this.isSubmit = false;
                },5000);
            }
        });
    };
    goBack = () =>{
         browserHistory.push("/my-team")
    }

    render(){
        this.dataForm1[0].disabled=this.state.idTeam;
        return(
            <div className="full_w full_h height-100vh">
                <div className="register my-team row">
                    <div style={{position:'relative', minHeight:'100%'}} className="full_w full_h_1 scroll">
                        <div className="col-sm-12 display_col padding-0" style={{maxWidth: 782, position:'relative'}}>
                           <div className="back-to-page"  onClick={this.goBack} style={{margin:"-51px 0px 0px 0px"}}>
                                          <img alt="" src={require('../../assets/images/roster/icon-back-def.png')} style={{width:"12px" , height:"20px" , position:"fixed" , marginLeft:"-19px"}} />
                                            <div className='back-text'>Back</div>
                                          </div>
                            <div className="create-team-title"
                                 style={{paddingTop:"10%"}}>
                                CREATE TEAMS
                            </div>
                            <div style={{position:'relative'}} className='create-team-container'>
                                <CForm styleForm="form-col" ref='CForm1' data={this.dataForm1}/>
                                {!this.state.idTeam &&
                                    <Button className="menu-left-action btn-edit-team btn-linear shadow_popup btn btn-default"
                                            style={{bottom: 20, right: 40 , borderRadius:"25px"}}
                                            onClick={this._createTeam}>
                                        CREATE TEAM
                                        {this.state.isLoading &&
                                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                                        </div>
                                        }
                                    </Button>
                                }
                            </div>
                            {this.state.messageTeam!=='' && (
                                <div className="register_error" style={{width:'calc(100% - 190px)', top:225, height:80, textAlign:'left', justifyContent:'left', alignItems:'flex-start'}}>
                                    {this.state.messageTeam}
                                </div>
                            )}
                            {this.state.idTeam &&
                                <div style={{position:'relative', margin:'40px 0 80px 0'}}>
                                    {this.state.flagInvite && <CForm styleForm="form-col" ref='CForm2' data={this.dataForm2}/>}
                                    {!this.state.flagInvite && <CForm styleForm="form-col" ref='CForm2' data={this.dataDef}/>}
                                    <div className="display_row full_w">
                                        {this.state.messageMember!=='' && (
                                            <div className="register_error" style={{width:'calc(100% - 190px)', bottom:-50, height:80, textAlign:'left', justifyContent:'left'}}>
                                                {this.state.messageMember}
                                            </div>
                                        )}
                                        <Button className="menu-left-action btn-linear-orange shadow"
                                                onClick={this._inviteMember}>
                                            SEND
                                            {this.state.isLoadingSend &&
                                            <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                                <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                                            </div>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            }
                            {this.state.idTeam &&
                                <div className="sent-view" style={{paddingBottom: 100}}>
                                    <span className="">Team Members</span>
                                    {this.state.dataSent && this.state.dataSent.length > 0 && this.state.dataSent.map((key, idx) => {
                                        return (
                                            <div className="name-sent" key={idx + 'item-member'}>
                                                {key.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        {this.state.messageCreateTeam!=='' && (
                            <div className="register_error" style={{bottom:55, height:80, textAlign:'left', justifyContent:'left', alignItems:'flex-end', width:'100%'}}>
                                {this.state.messageCreateTeam}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(CreateTeam);