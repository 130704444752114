import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import './tasks-page.css'

import {browserHistory} from 'react-router';
import {Button} from "react-bootstrap";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import moment from 'moment';
import Config from "../../config";
import SearchBar from "../my-team-detail/search-bar";
import ReactLoading from "react-loading";
import {Image,Modal} from 'react-bootstrap';
import _ from 'lodash';
import Loader from '../loader/loader';

import Header from '../header/header';
import Loaderview from '../roster/loaderview';

class TasksPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTaskComplete: false,
            taskList:null,
            isLoadMore: true,
            disLoadMore: false,
            isloading:false,
            fixedValue: 80,
            toggleTeamSelection:true,
           isTeamOpened:false,
           isDelete:false,
        };

        this.perPage = 4;
        this.numPage = 0;

        this.dataFilter = {
            limit: this.perPage,
            skip: this.numPage*this.perPage,
            sort: '[{"createdAt": "DESC"}]'
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 80
            });
        } else {
            this.setState({
                fixedValue: 80
            });
        }
    }

    getId = () => {
        let location = window.location.href;
        if(location.indexOf('?user=')){
            let memberId = window.location.search.split('?user=');
            memberId=memberId[memberId.length-1].split("&tid=");
            memberId=memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else{
            return null;
        }
    };

    _init =(page) =>{
        let condition = {};
        if((this.state.isLoadMore && this.state.taskList) || this.state.disLoadMore) return;
        this.setState({
            isLoadMore: true
        });

        let dataTemp = this.state.taskList ? this.state.taskList.slice(0) : null;
        if(dataTemp) this.numPage = this.numPage+1;
        else this.numPage= 0;

        let params = this.dataFilter;
        params.skip = this.numPage*this.perPage;
        let MemberId = this.getId();
        if(this.state.activeTaskComplete){
            condition = {team: this.state.idTeam, status:  "DONE"};
        }
        else{
            condition = {team: this.state.idTeam, status:  {"!=": "DONE"}};
        }

        if(MemberId){
            condition.assignTo = {like: '%'+MemberId+'%'};
        }

        if(page || page === 0){
            params.skip = 0;
        }     
        let checkLogin = localStorage.getItem('LOGIN');
        let UserId = null;
        if (checkLogin) {
            checkLogin = JSON.parse(checkLogin);
            UserId = checkLogin.user.id;
        }             
        params.userid = UserId;       
        params.teamId=this.state.idTeam;       
        params.condition = JSON.stringify(condition);

        this.props.taskActions.getListTask(params, (err, data)=>{
            if(err){
                Config.alertMess(err);
                this.numPage = this.numPage > 0 ? this.numPage - 1 : 0;
                this.setState({
                    taskList: [],
                    isLoadMore: false,
                    isloading: false,
                    disLoadMore: 0
                });
            }
            else if (data) {
                console.log("============data task", data);
                let updatedTaskList = dataTemp && (!page && page !== 0) ? dataTemp.concat(data) : data;
            
                let doneTasks = updatedTaskList.filter(task => task.status === "DONE");
                let otherTasks = updatedTaskList.filter(task => task.status !== "DONE");
            
                doneTasks.sort((a, b) => a.deadline - b.deadline);
            
                updatedTaskList = [...doneTasks, ...otherTasks];
            
                this.setState({
                    taskList: updatedTaskList,
                    isLoadMore: false,
                    disLoadMore: data.length < this.perPage,
                    isloading: false,
                });
            }            
        });
    };

    reload = () => {
        let params = {
            limit: this.dataFilter.limit + this.dataFilter.skip,
            skip:0,
            sort:this.dataFilter.sort,
            condition:this.dataFilter.condition
        };
        this.props.taskActions.getListTask(params, (err, data)=>{
            if(err){
                this.setState({
                    taskList: [],
                });
            }
            else if(data){
                this.setState({
                    taskList: data,
                })
            }
        });
    };

    _checkTitleChangeComplete = (flag) => {
        // console.log("flagflagflag", flag);
        if (flag === this.state.activeTaskComplete) return;
        this.numPage=0;
        this.dataFilter.skip=0;
        this.setState({
            activeTaskComplete: flag,
            disLoadMore: false,
            taskList:null,
            isDelete:false
        },()=>{
            this._init();
        })
    };

    deleteTask = (item) => {
        this.setState({isloading:true})
            this.props.taskActions.removeTask({id: item.id}, (err, data) => {
                if (data) {
                    this.reload();
                    this.setState({isloading:false})
                }
                else if (err) {
                    Config.alertMess(err);
                }
            });        
    };
    handleChildData = (isDelete) => {
        this.setState({ isDelete });
    }

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this._init(0);
        });
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id,
            disLoadMore: false,
            isDelete:false
        },()=>{
            this._init(0);
            this.setState({
                toggleTeamSelection: false,
                isloading: true
            });
        });
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }

    render() {
        let {teamList,memberList} = this.props;
        let {taskList,isLoadMore} = this.state;
        let myTeam = [];
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;

        console.log("-----------team",myTeam)
        let condision = myTeam?.members?.admins?.length === 0 && myTeam?.members?.staffs?.length === 0
        return (
                <div className="register1 calendar-view row my-team-detail my-team task-view full_w height-100vh" onClick={this.toggleTeamSelectionClick}>      
                    <div className="col-sm-12 display_col padding-0 full_h_1" style={{position:'relative'}}>
                     {!this.state.taskList && <div style={{marginLeft:"-11px"}}><Loaderview/></div>}
                     {this.state.isloading && <div style={{marginLeft:"-11px"}}><Loaderview/></div>}
                    <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />

                        <div className="tasks-view full_h display_col  body_container">
                            <div className="col-sm-12 padding-0 display_col" style={{height:'calc(100vh - 170px)',paddingTop:"23px"}}>
                                <ListTasksTab
                                    activeTask={this.state.activeTask}
                                    activeTaskComplete={this.state.activeTaskComplete}
                                    checkTitleChangeComplete={this._checkTitleChangeComplete}
                                    sendDataToParent={this.handleChildData}
                                    condition={condision}
                                    istaskList={taskList && taskList.length > 0}
                                    vale={this.state.isDelete}
                                />

                                <ListTasksView
                                    sampleData={taskList}
                                    loadMore={this._init}
                                    isLoadMore={isLoadMore}
                                    checkLogin={checkLogin}
                                    memberList={memberList}
                                    deleteTask={this.deleteTask}
                                    activeTaskComplete={this.state.activeTaskComplete}
                                    _deleteReport={this.state. _deleteReport}
                                    
                                    vale={this.state.isDelete}

                                />
                            </div>

                        </div>
                    </div>
                </div>
        )
    }

}

class ListTasksTab extends Component {

    constructor(props) {
        super(props);

        this.state = {deleteMode: false};

    }

    _checkUpcoming = (flag) => {
        if (this.props.checkTitleChangeComplete){
            this.props.checkTitleChangeComplete(flag);
        }
        this.setState({ deleteMode: false });
    };

    toggleDeleteMode = () => {
        this.setState(
            (prevState) => ({ deleteMode: !prevState.deleteMode }),
            () => {
              
                this.props.sendDataToParent(this.state.deleteMode);
            }
        );
    };

    handleCancel = () => {
        this.setState({ deleteMode: false }, () => {
            this.props.sendDataToParent(false);
        });
    };

    render() {
        let {activeTaskComplete,istaskList,vale} = this.props;
        let { deleteMode } = this.state;
console.log("activeTask",istaskList);

        return (
            <div className="title display_row align-between" style={{marginTop:"18px"}}>
                <div className="task-section-new">
                    <span className={"completed_tasks " + (!activeTaskComplete ? "active" : '')}
                          onClick={() => this._checkUpcoming(false)}>
                        UPCOMING 
                    </span>
                    <span className={"upcoming_tasks " + (activeTaskComplete ? "active" : '')}
                          onClick={() => this._checkUpcoming(true)}>
                       COMPLETED
                    </span>
                </div>
                <div style={{display:"flex",gap:"24.67px"}}>
                    {istaskList && 
                // <Button className="btn-linear shadow_popup" style={{ width: 145, height: 35 ,borderRadius: '25px' ,fontFamily:' open-sans-semibold' , display:this.props.condition ? "none" : ""}}
                //      onClick={deleteMode ? this.handleCancel : this.toggleDeleteMode}>
                //     {deleteMode ? "CANCEL" : "DELETE MODE"} </Button> 
                <Button className="btn-linear shadow_popup" style={{ width: 145, height: 35 ,borderRadius: '25px' ,fontFamily:' open-sans-semibold' , display:this.props.condition ? "none" : ""}}
                onClick={deleteMode ? this.handleCancel : this.toggleDeleteMode} >
                 {vale ? "CANCEL" : "DELETE MODE"} </Button> 
                    }
                <Button className="btn-linear-orange shadow_popup" style={{width:145, height:35}}
                        onClick={()=>{browserHistory.push('create-tasks')}}>CREATE NEW TASK</Button>
                </div>
                
            </div>
        );
    }
}


class ListTasksView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deletetasks:{},
            isUpdateRemoveTeamConfirmed:false,
            isloading:false
        };

    }

    isJSON = ( str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return [];
        }
        return JSON.parse(str);
    };

    deleteTask = (item) => {
        if(this.props.deleteTask) this.props.deleteTask(item);
    };

    handleScrollFeed = (event) => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            if(this.props.loadMore) this.props.loadMore();
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', _.debounce(this.handleScrollFeed,200));
    }

    _removeTask = (item) => {
        this.setState({deletetasks:item});
        this.setState({ isUpdateRemoveTeamConfirmed: true });
      };

    _ConfirmRemove = (item) => {
        this.deleteTask(item)
        setTimeout(() => {
            this._handleClose();                    
          }, 300);
        };

    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false ,vale: false });        
      };

    render() {
        let {sampleData,isLoadMore,memberList,checkLogin,activeTaskComplete,vale} = this.props;
        
        return (
          
            <div className="full_h_1 scroll task-page-scrool" onScroll={this.handleScrollFeed} style={{height:'100%',marginTop:"50px" , width:"auto"}}>
                <div className="task-list-view">
                    <div className="member-information animation-show col-sm-12"
                         style={{marginBottom: 20,marginTop:"5px"}} ref={e=>this.listView=e}>
                        {sampleData && sampleData.map((key, idx) => {
                            console.log("sampleData",sampleData);
                            console.log("sampleData key",key);
                            // if ((activeTaskComplete && deadline<0) || (!activeTaskComplete && deadline>=0) || !key.assignTo || !key.deadline) return null;
                            let assignTo = key.assignTo && this.isJSON(key.assignTo) ? JSON.parse(key.assignTo) : [];
                            let attachments = key.attachments && this.isJSON(key.attachments) ? JSON.parse(key.attachments) : [];
                            console.log("--------------attachments image----",attachments)
                            let allStaff = memberList ? memberList.filter(i=> i.role !== 'OWNER' && (i.user && checkLogin && checkLogin.user && i.user.id !== checkLogin.user.id)) : [];
                            let lastDate = new Date(key.deadline * 1000) 
                            return (
                                    <div className="shadow-1 info-in"   key={"data-" + idx} >
                                       <div className='task-details'>
                                            <div className="task-image">    
                                                <img src={attachments[0] && attachments[0]?.src? attachments[0]?.src: require("../../assets/images/no-image.jpg") }
                                                    alt={key.title}
                                                    className='task-attachments-profile'
                                                />
                                            </div>
                                            <div>
                                                <div className='task-title'>{key.name}</div>
                                                <div className='task-instructions'>{key.description && key.description.length > 0 ? (key.description.length > 100 ? key.description.substring(0, 100) + "..." : key.description) : key.note.substring(0, 100) + "..."}
</div>
                                            </div>
                                       </div>
                                       <div className='task-user-details'>
                                            <div className="task-assignee-details">
                                                <div className="task-assignee-section">
                                                    <div className="assignee-image-section">
                                                        {assignTo.slice(0, 4).map((keyx, idxx) => {
                                                            const imgClass = idxx === 0 
                                                            ? 'users-profiles first' 
                                                            : idxx === 1 
                                                            ? 'users-profiles second' 
                                                            : idxx === 2 
                                                            ? 'users-profiles third' 
                                                            : 'users-profiles fourth';

                                                            return (
                                                                <img 
                                                                    key={idxx} 
                                                                    src={keyx.user && keyx.user.avatar ? keyx.user.avatar : Config.avaDef}
                                                                    alt={keyx.title}
                                                                    className={imgClass}
                                                                />
                                                            );
                                                        })}
                                                        {assignTo.length > 4 && <span className={"plus_add assignee-count"}> and {assignTo.length -  4} more</span>}
                                                    </div>
                                                    <div className='assigned-staff-name'>
                                                        Assigned to {allStaff.length === assignTo.length ? 'All Staff' : assignTo.map((e, idx) => e.name + (idx !== assignTo.length - 2 ? (idx !== assignTo.length - 1 ? ", " : '') : ' and '))}
                                                    </div>
                                                    {key.status !== 'DONE' && <div className='tbc-time'>TBC by {moment(lastDate).format('HH:mm DD/MM/YYYY')}</div>}
                                                    {key.status === 'DONE' && <div className="task-status-completed">Completed</div>}
                                                </div>
                                                <div className="view-button-section">
                                                    {!vale ? (<div className='task-view-btn' style={{cursor:'pointer'}} onClick={()=>{browserHistory.push(`/task-detail?taskId=${key.id}?userId=${checkLogin.user.id}`)}}> <p>VIEW</p></div> ) : (
                                                    <div className='task-view-btn' style={{cursor:'pointer'}} onClick={() => { this._removeTask(key); }}>
                                                    <p  style={{color:'#EC6E68'}} >DELETE</p></div>)}
                                                </div>
                                            </div>                                             
                                        </div>
                                    </div>
                                )
                            })}
                            <div style={{height:"1px"}}></div>
                        {!sampleData &&
                             <div className="display_row align-center justify-center" style={{width:'100%',
                             borderRadius: 25,
                             padding: 10,
                             position: 'absolute',
                             height: '100%'
                         }}>
                             <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30}/>
                         </div>
                        }    
                        {!sampleData || (sampleData && sampleData.length===0) &&
                            <div className="full_w display_row align-center justify-center h-100 align-center task-empty-section-content">
                                <div className="txt-not-task no-content">
                                {activeTaskComplete ? "Your completed task list will appear here":"Your assigned task list will appear here"}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                <Modal
                show={this.state.isUpdateRemoveTeamConfirmed}
                onHide={this._handleClose}
                id="popup-change-plan"
                className={"root-popup-change-plan remove-team-member"}
                style={{  maxWidth: "100%", margin: "auto" }}
                >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
                />
                <div className="title">Remove Task</div>
                <div className="title1 remove-admin">Do you want to remove this task?</div>
                <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px"}}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                  onClick={()=>this._ConfirmRemove(this.state.deletetasks)}
                >
                  CONFIRM
                </Button>
                </div>
                   </Modal.Body>
                </Modal>
                </div>
            </div>
        )

    }

}


export default connect(state => ({
        memberList: state.team.memberList,
        taskList: state.task.taskList,
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    })
)( TasksPage);