import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import "./user-management.css";
import CForm from "../libs/cform/cform";
import Config from "../../config";
import * as userActions from "../../redux/user/user_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as memberActions from "../../redux/member/member_actions";
import * as managementActions from "../../redux/management/management_actions";
import * as companyActions from "../../redux/company/company_actions";
import ReactLoading from "react-loading";
import Loader from '../loader/loader';
import Select from 'react-select';
import myAccount from "../my-account/my-account";
import Header from '../header/header';
import Loaderview from "../roster/loaderview";
import { times } from "lodash";
import * as packageActions from "../../redux/package/package_actions";
import { CardElement,Elements,ElementsConsumer } from '@stripe/react-stripe-js';
import { stripePromise } from '../payment/stripe';

class UserManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamList: null,
      createTeam: false,
      datas: null,
      userId: "",
      idTeam: null,
      memberList: null,
      companyList:  [],
      companyTeamList: null,
      userRole: "",
      isRemoveMember: false,
      removeMemberName:"",
      memberId: null,
      memberRole: null,
      isAddMember: false,
      selectedRole:null,
      isLoading:true,
      currentPage: 1,
      itemsPerPage: 10,
      totalPages:"",
      activeMembers:null,
      totalMembers:null,
      emptyTeam:false,
      isCompany : null,
      subscription:'',
      upcomingInvoice:'',
      paymentMethod:'',
      listPackage:null,
      updatePlanPayment: false,
      editPayment : false,
      isClicked: false,
    };
    // file1Ref = React.createRef();
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.isSubmit=false;
    this.dataForm = [
      {
        type: "TEXT",
        key: "name",
        id: "name",
        placeholder: "Name",
        def_value: "",
        // def_value:'TrangHoang',
        validator: "isName",
        feedback: false,
      },
      {
        type: "TEXT",
        key: "email",
        id: "email",
        placeholder: "Email",
        validator: "isEmail",
        def_value: "",
        // def_value:'tranghoang1001@gmail.com',
        feedback: false,
      }, 
      {
        type: 'TEXT',
        key: "title",
        id: "title",
        placeholder:"Job Title",
        def_value: '',
        // def_value:'123456',
        validator: 'isRequired',
        feedback: false,
    }    
    ];
  }
 
  componentDidMount() {
    console.log("--------------------33",this.props);
    this._init(1);
    this.getPlan();

  }

  _init = (page) => {
  
    const { searchQuery } = this.state;
    console.log("======>this.props", this.props);
    let checkLogin = localStorage.getItem("LOGIN");
    checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
    let companyId = checkLogin.company.id;
    console.log("Company ID", companyId);

    this.props.managementActions.getMemberTeamListManagement(
      { companyId: companyId, page: page, perPage: this.state.itemsPerPage,searchQuery: searchQuery },
      (error, data) => {
        if(error){
          console.log('Member collection failed',error);
        }
        console.log('MemberDetails Response',data);
        this.setState({ companyList: data.userCollectionInfo.userCollection });
        this.setState({ companyTeamList: data.userCollectionInfo.teams });
        this.setState({ totalPages:data.totalPages})
        this.setState({activeMembers:data.userCollectionInfo.activeMember})
        this.setState({totalMembers:data.userCollectionInfo.totalMembers})        
        this.setState({subscription:data.subscription})        
        this.setState({upcomingInvoice:data.upcomingInvoice})        
        this.setState({paymentMethod:data.paymentMethod})        
        this.setState({
          isLoading: false
      });
      }
    );
  };

  getPlan = ()=>{
    this.props.packageActions.getListPackage((err, data)=>{
console.log("---------------data--",data)
        if(data){
            this.setState({
                listPackage: data
            })
        }
        else{
            Config.popup.show('INFO',[Config.alertMess(err)]);
        }
    });
};

  setFormRef = (ref) => {
    this.formRef = ref;
  };

  handleRoleChange = (event) => {
    this.setState({ selectedRole: event.target.value });
  };

  openModal = (member) => {
    
    this.setState({ isRemoveMember: true });
    this.setState({ memberId: member.user.id });
    this.setState({ removeMemberName: member.user.fullName });
  };
  closeModal = () => {
    this.setState({ isRemoveMember: false });
  };

  handleOpen = () => {
    this.setState({ isAddMember: true });
  }

  handleClose = () => {
    this.setState({ isAddMember: false });
    this._timer = setTimeout(() => {
      clearTimeout(this._timer);
      if (this.props.handleClose) this.props.handleClose();
    }, 300);
  };

  _removeMember = (memberId) => {
    console.log('member details',memberId);
    this.setState({
      isLoading: true
  });

  this.props.managementActions.removeMemberManagement({ id: memberId }, (err, data) => {
    if (err) {
      Config.alertMess(err);
    } else {
      // Remove the member from the companyList in the state
      this.closeModal();
      const { currentPage } = this.state;
      this._init(currentPage);      
    }
  });
   
  };

  _addMember = (cb) => {
    if(this.isSubmit) return;
    let checkLogin = localStorage.getItem("LOGIN");
    checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

    let companyId = checkLogin.company.id;
    let check = this.refs["CForm"].formValidate();
    let dt = this.refs["CForm"].formValue(); 
    let userRole=this.state.userRole;  
    console.log(userRole,'ussssssssssssss');
    if(!check || userRole=='') {
      this.setState({messageEvent: '* Fill out all fields or input correct details to the fields and try again.'});
      setTimeout(()=>{
          this.setState({messageEvent: '',isLoading:false});
          this.isSubmit = false;
      },5000);
      return;
  }

  this.setState({
    isLoading: true
});
    if (check && userRole) {
      console.log('Success');

      let params = {
        name: dt.name.value,
        title: dt.title.value,
        email: dt.email.value,
        team: userRole,
    };

    this.props.teamActions.inviteMember(params, (err, data)=> {
      if(data){
        this.setState({messageEvent: '',isLoading:false});
        this.setState({ isAddMember: false });
        this.isSubmit = false;
        const { currentPage } = this.state;
        this._init(currentPage);        
      }
      else{
        this.setState({messageInviteMember:err.message});
        this._timer = setTimeout(()=>{
          this.setState({
            messageInviteMember:'',
              isLoading: false              
          });
      },5000);              
        return;
    }
  });
  

    }
  };

  onChangeSelect = (e, idx) => {
    this.setState({ userRole: e.value });
};

handlePageChange = (pageNumber) => {
  this.setState({isLoading:true})
  this.setState({ currentPage: pageNumber });
  this._init(pageNumber); // Fetch data for the new page
};

handlePreviousPage = () => {
  this.setState({isLoading:true})
  const { currentPage } = this.state;
  if (currentPage > 1) {
    const Page = currentPage - 1;
    this.setState({ currentPage: Page }, () => {
      console.log("newpage",Page);
      this._init(Page);
   
    });
  }
};

handleNextPage = () => { 
  this.setState({isLoading:true})
  const { currentPage,totalPages, companyList } = this.state;
  let isDisable = companyList.length < 10 && currentPage + 1;  
  if (!isDisable && currentPage < totalPages) {
    const Page = currentPage + 1;
    this.setState({ currentPage: Page }, () => {
      console.log("newpage",Page);
      this._init(Page);
     
    });
  }
};


renderPageNumbers() {
  const { currentPage,totalPages,companyList } = this.state;

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    let isDisable = false;    
    if (currentPage && companyList.length < 10) {      
      if (i !== currentPage - 1 && i !== currentPage && totalPages !== currentPage){
        isDisable = true;
      }      
    }
    pageNumbers.push(
      <div
        key={i}
        className={`page-numbers ${currentPage === i ? "active" : ""} ${isDisable? "disabled" : ""}`}
        onClick={() =>!isDisable && this.handlePageChange(i)}
      >
        {i}
      </div>
    );
  }

  return pageNumbers;
}

handleSearchChange = (e) => {
  const searchQuery = e.target.value; 
  this.setState({ searchQuery, currentPage: 1 }, () => {
    this._init(1);
  });
};

clearSearch = () => {
  this.setState({ searchQuery: '' }, () => {    
    this._init(1);
  });
};

isNextPageDisabled() {
  const { currentPage, totalPages, companyList } = this.state;
  return companyList.length < 10 && currentPage < totalPages;
}
_onLogout = () => {
  this.props.mainActions.checkOut((err, data) => {
      window.location.reload();
  });
};

emptyTeamOpen = () =>{
  this.setState({
    emptyTeam : true
  })
}

closeTeamModal = ()=>{
  this.setState({
    emptyTeam : false
  })
}
paymentMethods = (method) => {
  console.log("-------------------method", method);

  if (typeof method !== 'string') {
      return;
  }

  const lastTwoDigits = method.slice(-2); 
  let isMethod = `**${lastTwoDigits}`;
  return isMethod;
}

  inactive = (data) => {
        this.props.companyActions.inactive((err, data) => {
            if(data){
                this.props.mainActions.checkOutNoUrl((err, data)=>{
                    window.location.reload();
                });
            }
            else if(err){
                Config.popup.show('INFO', Config.alertMess(err))
            }
        });
    };
  _handleClose = ()=>{
      this.setState({ isCancelMembershipConfirmed: false });    
    }
  _ConfirmRemove = (item) => {
    this.setState({ isCancelMembershipConfirmed: false });        
    this.inactive(item);
  };

  inactiveCompany=(item)=>{
    this.setState({
      editPayment:false
    })
    this.setState({ isCancelMembershipConfirmed: true });        
  };
  updatePlanPayment=(e)=>{
    console.log("***********updatePlanPayment");
    console.log(e);

    if (e.isSubmit || e.state.isLoading) {
      return;
    }
    //let check = this.refs['CForm'].formValidate();
    //Removed the Card Form Check Local and moved the validation to Live
    let check = true;
    //let dt = this.refs['CForm'].formValue();

    e.setState({
      isLoading: true,
    });
    if (!check) {
      e.setState({
        errPayment:
          "Fill out all fields or input correct details to the fields and try again",
      });
      setTimeout(() => {
        e.setState({ errPayment: "", isLoading: false });
        e.isSubmit = false;
      }, 3000);
    } else {
      let dataPay = e._getDataPayment();
      dataPay.stripeToken=e.state.stripeToken;
      console.log("updatePayment-------------------", dataPay);
      
        this.props.companyActions.updatepayment(dataPay, (err, data) => {
          if (data) {
            localStorage.setItem("LOGIN", JSON.stringify(data));
            e.setState({
              isLoading: false,
            });
            e.setState({
              isSubmit: false,
            });
            e.setState({
              successPayment: "Payment Method updated Successfully",
            });

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            e.setState({
              isLoading: false,
            });
            e.setState({
              isSubmit: false,
            });
            e.setState({
              errPayment: Config.alertMess(err),
            });

            setTimeout(() => {
              e.setState({ errPayment: "", isLoading: false, isSubmit: false });
            }, 3000);
          }
        });
      
    }
  }
  toggleChangePlanPayment = () => {
    console.log("-------------111")
    this.setState({
      updatePlanPayment: !this.state.updatePlanPayment,
    });
  };
  changePayment = (data) => {
    console.log(data);
    if (data != "free_plan") {
      this.toggleChangePlanPayment();
    }
  };

  render() {
    let { companyList,currentPage,activeMembers,totalMembers } = this.state;        
    console.log('******************************',this.state.companyTeamList);    
    let dataOption = [];

    if(this.state.companyTeamList) {
      this.state.companyTeamList.filter(i=>{dataOption.push({value: i.id, label:i.name});});
  }
  const { borderColor } = this.state;
  console.log("--------------companyList",companyList,"currentpage",currentPage,"activeMembers",activeMembers,"totalmembers",totalMembers)
  let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
      
        let companyInfo = login && login.company ? login.company : null;
        let stripeInfo = login && login.cardDetails ? login.cardDetails : null; 
        let cardLastFour='';
        if(stripeInfo && stripeInfo.last4!=undefined){
            cardLastFour=stripeInfo.last4; 
        }
        let planInfo = []
        let dataPlan = this.state.listPackage
        console.log("------dataPlan----",dataPlan)
        if(dataPlan && dataPlan.length){
            dataPlan.map((plan)=>{
                if(plan.type === companyInfo?.subcriptionPlan){
                    planInfo.push(plan)
                }
            })
        }
        console.log('plan details',companyInfo);

  let subscription = this.state.subscription
  let upcomingInvoice = this.state.upcomingInvoice
  let paymentMethod = this.state.paymentMethod
  let data = upcomingInvoice.lines?.data
  let lastObject = data?.[data.length - 1] || {};

  let current_period_end = subscription.current_period_end
  const date = new Date(current_period_end * 1000);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
console.log("---------------paymentMethod",paymentMethod)

    return (
      <div className="user-manage user_management_container">
        {this.state.isLoading && <div style={{position:'absolute',top:'0'}}> <Loaderview/> </div>}
         <Header isSearch={false}
                                 type={1} login={login} onLogout={this._onLogout}/>
                                 <div className="body_container">
  
      <div className="billing-title">Billing</div>
        <div className="billing-details">
            <div className="billing-box billing-tool" >
              <h2>Total Team Size</h2>
              <p>{subscription.quantity}</p>
            </div>
            <div className="billing-box billing-tool" id="billing-month">
              <h2>Total Cost</h2>
              <p className="billing-amount">{`€${upcomingInvoice.amount_due/100} per month`}</p>
              <h6>{`€${(((lastObject.amount)/ 100)  / subscription.quantity).toFixed(2)} per user`}</h6>
            </div>
            <div className="billing-box billing-tool">
              <h2>Next Billing Date</h2>
              <p>{formattedDate}</p>
            </div>
            <div className="billing-box" style={{border:this.state.editPayment ? " 1.5px solid #31CCBC" : ""}} id="billing-month" onClick={(e) => this.changePayment(companyInfo.subcriptionPlan)}>
              <h2>Payment Method</h2>
              <p className="billing-amount">{this.paymentMethods(cardLastFour)}</p>
              <h5>Edit</h5>
            </div>
            <div className="billing-box" style={{border:this.state.isCancelMembershipConfirmed ? " 1.5px solid #31CCBC" : ""}} id="billing-month" onClick={this.inactiveCompany} >
              <h2>Cancel Subscription</h2>
              <p className="billing-amount"></p>
              <h5 >Cancel</h5>
            </div>
        </div>
        <Modal
                        show={this.state.isCancelMembershipConfirmed}
                        onHide={this._handleClose}                
                        className="remove-user"    
                        style={{marginTop:"3%"}}        
                        >
                    
                    <Modal.Body>
                    <Image
                      className="logo"
                      src={require("../../assets/images/icon-member.png")}
                    />
                      <div className="head">Confirm</div>
                      <div className="content" style={{ margin: "40px 57px 30px " }}>Are you sure you wish to to cancel your account? This can not be undone and will result in all access being removed for all team members immediately? You will no longer have access to any team data such as rosters, timesheets etc.</div>
                      <div className="display_row modal-footers" style={{ padding: "10px 0" , display: "flex" , justifyContent:"center",gap:"45px" , marginTop:"6%"}}>
                        
                        <Button
                          className="orange-btns shadow_popup"
                          style={{ maxHeight: 43, margin: 0 , borderRadius:"25px"}}
                          onClick={this._handleClose}
                        >
                          CANCEL
                        </Button>
                        <Button
                          className="blue-btns shadow_popup"
                          style={{ maxHeight: 43, margin: "0" , borderRadius:"25px" }}
                          onClick={() => this._ConfirmRemove(this.state.isCancelMembershipConfirmed)}
                        >
                          CONFIRM
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
    <div className="team_sizeDiv">
       <div className="management-title">
       <div>User Management</div>
       </div>
         
          <div className="input-serach">
            <input
              placeholder="Search"
              value={this.state.searchQuery} 
              onChange={this.handleSearchChange}
            />
            {this.state.searchQuery ? (
              <div onClick={this.clearSearch}>
                 <Image src={require("../../assets/images/remove-user.png")} style={{width :"20px",height:"20px",right:"6px",top:"6px" , position:"absolute"}}/>
              </div>
            ) : (
              <Image src={require("../../assets/images/icon-search.png")} style={{width :"20px",height:"20px",right:"6px",top:"6px" , position:"absolute"}}/>
            )}
          </div>
          <div >
          <Button
                 className="add-btn shadow_popup"
                  onClick={dataOption.length === 0 ? this.emptyTeamOpen : this.handleOpen} 
                  style={{ borderRadius: "25px", cursor: dataOption.length === 0 ? "not-allowed" : "pointer",  opacity: dataOption.length === 0 ? 0.5 : 1
                  }}>
                  ADD NEW
              </Button>
          </div>
    
                        <Modal
                            show={this.state.emptyTeam}
                            onHide={this.closeTeamModal}
                            className="remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" }}>
                                You cannot add a new member without creating a team. Please create a team first to proceed.         
                                </div>
                            </Modal.Body>
                         </Modal>

    </div>
    <div className="user_tables" style={{ paddingTop: "33px" }}>
  {this.state.isLoading && (
    <div className="display_row align-center justify-center data-loader">
      <ReactLoading type={"spinningBubbles"} color={"#31CCBC"} height={30} width={30} />
    </div>
  )}

  {companyList && this.state.companyList && companyList.length > 0 ? (
    <table className="user-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th style={{ width: "35%" }}></th>
          <th style={{ width: "20%" }}>Role</th>
          <th style={{ width: "20%" }}>Team</th>
          <th style={{ width: "25%" }}></th>
        </tr>
      </thead>
      <tbody className="table-body">
        {companyList.map((member) => (
          <tr key={member.user.id}>
            <td className="Textlist user-detail">
              <img
                className="userimageEllipse"
                alt=""
                src={member?.user?.avatar ? member.user.avatar : Config.avaDef}
              />
              <span className="capitalize user-name">{member.user.fullName}</span>
            </td>
            <td className="Textlist">{member.role.name}</td>
            <td className="Textlist">
              {member?.team?.name ? (member.team.name.length > 15 ? member.team.name.substring(0, 15) + "..." : member.team.name) : ""}
            </td>
            <td
             
              className={`img-text ${member.role.slug === "owner" ? "disabled" : ""}`}
            >
              Remove
              <div className="image-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className="img-close"  onClick={() => {
                if (member.role.slug !== "owner") {
                  this.openModal(member);
                }
              }}>
                    <path d="M7.5 0C3.36433 0 0 3.36433 0 7.5C0 11.6357 3.36433 15 7.5 15C11.6357 15 15 11.6357 15 7.5C15 3.36433 11.6357 0 7.5 0Z" fill="#F44336"/>
                    <path d="M10.2618 9.37794C10.5061 9.62239 10.5061 10.0173 10.2618 10.2618C10.1399 10.3837 9.9799 10.4449 9.8198 10.4449C9.65981 10.4449 9.49982 10.3837 9.37794 10.2618L7.49985 8.38357L5.62176 10.2618C5.49988 10.3837 5.3399 10.4449 5.17991 10.4449C5.0198 10.4449 4.85982 10.3837 4.73794 10.2618C4.4936 10.0173 4.4936 9.62239 4.73794 9.37794L6.61614 7.49985L4.73794 5.62176C4.4936 5.37732 4.4936 4.98238 4.73794 4.73794C4.98238 4.4936 5.37732 4.4936 5.62176 4.73794L7.49985 6.61614L9.37794 4.73794C9.62239 4.4936 10.0173 4.4936 10.2618 4.73794C10.5061 4.98238 10.5061 5.37732 10.2618 5.62176L8.38357 7.49985L10.2618 9.37794Z" fill="#FAFAFA"/>
                  </svg>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className="no-data">No company members found</div>
  )}
</div>
 
        <div>
       {companyList && companyList.length > 0 ? (        
        <div className={`paginations ${companyList.length < 10 && currentPage === 1 ? 'hidden' : ''}`}>
          <div className="pagination-prev"
              onClick={currentPage === 1 ? null : this.handlePreviousPage} 
              style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer", opacity: currentPage === 1 ? 0.5 : 1 }}
            >
              Prev
            </div>

          <div className="arrow-icon">
           
          </div>
          {this.renderPageNumbers()}
          <div className={`arrow-icon-right ${this.isNextPageDisabled() ? "disabled" : ""}`}>
          
          </div>
          <div className="pagination-next"
                onClick={currentPage === this.state.totalPages ? null : this.handleNextPage} 
                style={{ cursor: currentPage === this.state.totalPages ? "not-allowed" : "pointer", opacity: currentPage === this.state.totalPages ? 0.5 : 1 }}
              >
                Next
              </div>
        </div>   
          ) : !companyList && companyList.length < 0 ? (
            <p style={{textAlign : 'center'}}>No Company Member Found.</p>
          ) : ""}
        </div>   
        </div>  
        <ChangePlanPayment
            show={this.state.updatePlanPayment}
            handleClose={this.toggleChangePlanPayment}
            userinfo={this.state.infoUser}
            changePlanPayment={this.updatePlanPayment}
          />

        <Modal
          show={this.state.isRemoveMember}
          onHide={this.closeModal}
          className="remove-user"
          id="user-management-pop-up"
        >
          <Modal.Body>
            <Image
              className="logo"
              src={require("../../assets/images/icon-member.png")}
            />
            <div className="head">Confirm</div>
            <div className="content" style={{ margin: "40px 70px 30px " }}>
              Are you sure you wish to remove {this.state.removeMemberName} from your account?
              Removing this person will revoke their access to the app and will
              delete all of their information within Teemie. This can not be
              undone. Your next invoice will reflect this change of team size.
            </div>
            <div className="display_row modal-footers" style={{ padding: "10px 0" , display: "flex" , justifyContent: "center",gap:"45px",marginTop:"6%"}}>
              <Button
                className="orange-btns shadow_popup"
                style={{ maxHeight: 43, margin: 0 , borderRadius:"25px"}}
                onClick={this.closeModal}
              >
                CANCEL
              </Button>

              <Button
                className="blue-btns shadow_popup"
                style={{ maxHeight: 43, margin: "0" , borderRadius:"25px"}}
                onClick={() => {
                  this._removeMember(this.state.memberId);
                }}
              >
                CONFIRM
              </Button>


            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.isAddMember}
          onHide={this.handleClose}
          className="fix-modal add-member-popup"
          id='my-account-details-modal'
        >
          <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this.handleClose}/>
                <Modal.Body id='user-managment-pop-up'>
                    <div className="title">Add Staff Member</div>
                    <div className="sub-title add-staff-sub-title">Enter the job title and email of the new team member here and they will recieve a link to register. Your invoice next month will reflect this change.  </div>
                    <div className='add-staff-popup'>
                    <CForm data={this.dataForm} ref="CForm" />
                    </div>
                    
                    <div className="user-management-selct">
                    <Select
                                options={dataOption} 
                                placeholder='Select Team'
                                className={`display_row form-input align-center userRoleSection`}
                                classNamePrefix="select"
                                defaultValue="staff"
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSelect}
                            />
                      </div>
                   
                        <div className="register_error" style={{bottom:56, height:32, textAlign:'center', justifyContent:'center', alignItems:'flex-end', width:'100%'}}>
                            {this.state.messageInviteMember}
                        </div>
                        <div className="register_error" style={{width:'100%', bottom:60, textAlign:'center',alignItems:'flex-end'}}>
                            {this.state.messageEvent}
                        </div>
             
                    <Button className="blue-btns shadow_popup" style={{borderRadius:"25px",position:"relative", marginTop:"60px"}} onClick={this._addMember}>
                        SEND
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading loader-fixed">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>

                   
                </Modal.Body>
                
        </Modal>
      </div>
    );
  }
}

class ChangePlanPayment extends Component {
  _handleClose = () => {
    this.setState({errPayment: ""});
    this.setState({isSubmit: false});
    this.setState({isLoading: false});
    if (this.props.handleClose) this.props.handleClose();
  };

  constructor(props) {
    super(props);
    this.state = {
        cardinfo: null,
        isLoading:false,
        message: '',
        errPayment:'',
        successPayment:'',
        stripeToken:'',
        tokenstatus:false
    };
    this.isSubmit = false;
}
  

onConfirm = () => {
        this.props.changePlanPayment(this);
    
};

_getDataPayment = () => {
    let dataPay = localStorage.getItem('LOGIN');
    dataPay = dataPay && Config.isJSON(dataPay) ? JSON.parse(dataPay) : null;
    if(!dataPay ||(dataPay && (!dataPay.company || !dataPay.user))){
        window.location.href = "/home";
    }
    else{
        console.log('Render Details',dataPay);
        return dataPay;
    }
};

cardPaymentProcess = (status,info) => {
    console.log(status);
    if(!status){
        this.setState({message: info});
        setTimeout(()=>{
            this.setState({message: '',isLoading:false});
            this.isSubmit = false;
        },3000);
    }else{
        this.setState({stripeToken:info});
        this.setState({tokenstatus:true});
        this.onConfirm();
    }
};


  render() {
    let { dataPlan, show, userinfo, errProfile } = this.props;

    return (
      <Modal
        show={show ? true : false}
        onHide={this._handleClose}
        id="popup-change-plan"
        className={"root-popup-change-plan"}
        style={{ width: 966, maxWidth: "100%", margin: "auto" }}
      >
       
        <Modal.Body>
          <Image
            className="logo-pay"
            src={require("../../assets/images/icon-member.png")}
          />
          <div className="title1">
            Change your payment method easily by just
            <br /> inputting your details below
          </div>
          <div className="register-payment ">
            <div className="image-container" style={{ marginBottom: 10 }}>
              <Image
                src={require("../../assets/images/register/icon-visa.png")}
                className="image-card small"
              />
              <Image
                src={require("../../assets/images/register/icon-master-card.png")}
                className="image-card small"
              />
              <Image
                src={require("../../assets/images/register/icon-maestro.png")}
                className="image-card small"
              />
            </div>
            {/* Removed the Card Form to integrate the stripe card element */}

<div className="changePayment">
        <Elements stripe={stripePromise}>
        <ElementsConsumer>
        {({ elements, stripe }) => (
          <CardPaymentFormClass elements={elements} stripe={stripe} cardPaymentStatus={this.cardPaymentProcess}
          _handleClose={this._handleClose}/>
        )}
      </ElementsConsumer>
      </Elements>
      </div>
          </div>
          <div className=" payment-btn">
                    <div className="register_error" style={{width:'100%', bottom: 50 , display:this.state.errPayment ? "" : "none"}}>
                        {this.state.errPayment}
                    </div>
                    <div className="label_success" style={{width:'100%', bottom: 50}}>
                        {this.state.successPayment}
                    </div>
               
                    {this.state.tokenstatus &&
                    <div className="payment-success-btn">
                    <Button
              className="orange-btns shadow_popup"
              onClick={this._handleClose}
              style={{borderRadius:"25px"}}
            >
              CANCEL
            </Button>
            <Button className="blue-btns shadow_popup"
            style={{borderRadius:"25px"}}
            >
                        CONFIRM
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                    </div>
  }

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class CardPaymentFormClass extends Component {

  constructor(props){
      super(props);
      this.state={
          message: '',
          cardSubmitted: false,
          isLoading:false,
          cardholderName: '', // Initialize cardholderName in the component's state
      };
      this.isSubmit = false;
  }
  _handleClose=async()=>{
      this.props._handleClose();
  };
  
  handleCardToken = async (e) => {

      if(this.isSubmit || this.state.isLoading){
          return;
      }
      let stripe=this.props.stripe;
      let elements=this.props.elements;

    console.log('handle submit');
    e.preventDefault();
    console.log(this.props.stripe);
    console.log(this.props.elements);

    console.log("stripesss===========", stripe);
    if (!stripe) {
      return;
    }
    this.setState({
      isLoading:true
  });
  
    this.isSubmit = true;
    console.log('check');
    console.log(elements.getElement(CardElement));
    const result = await stripe.createToken(elements.getElement(CardElement), {
      name: this.state.cardholderName,
    });

    if (result.error) {
      console.error(result.error.message);
      this.setState({isLoading: false});
                  this.isSubmit = false;
      this.props.cardPaymentStatus(false,result.error.message);
    } else {
      this.setState({isLoading: false});
      this.isSubmit = false;
      // Handle successful token creation, e.g., send to your server for payment processing
      console.log(result.token);
      this.setState({cardSubmitted:true});
      this.props.cardPaymentStatus(true,result.token);
    } 
  };
  handleNameChange = (event) => {
      this.setState({ cardholderName: event.target.value });
    };

  render() {
    return (
     <div className='change-payment-method'>
        <div className='card-element-container'>
          <div className='form-group'>
          <input
          type="text"
          id="cardholder-name"
          className='form-control'
          placeholder='Name On Card'
          value={this.state.cardholderName}
          onChange={this.handleNameChange}
        />
        </div>
          <CardElement
            id="card-element"
            className='card-element'
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  color: '#32325d',
          lineHeight: '24px',
          fontSize: '16px'
                },
              },
            }}
          />
          </div>
         
<div className='button_display_row payment-btn' style={{marginTop:"6%"}}>
<Button
            className="orange-btns shadow_popup"
            onClick={this._handleClose}
            style={{borderRadius:"25px"}}
          >
            CANCEL
          </Button>
        
        <Button className="blue-btns shadow_popup"   style={{borderRadius:"25px"}} onClick={(e) => this.handleCardToken(e)}>
                      CONFIRM
                      {this.state.isLoading &&
                      <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                          <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                      </div>
                      }
                  </Button>
                  </div>
 
      </div>
    );
  }
}

export default connect(
  (state) => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    teamId: state.team.teamId,
  }),
  (dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch),
    mainActions: bindActionCreators(mainActions, dispatch),
    memberActions: bindActionCreators(memberActions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    managementActions: bindActionCreators(managementActions, dispatch),
    packageActions: bindActionCreators(packageActions, dispatch),
  })
)(UserManagementPage);
