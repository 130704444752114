import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./report_actions";
import Api from '../../services/api';

export function* getListReport(data) {
    try {
        yield delay(300);
        yield put({type: types.REPORT_PENDING});
        let task = yield Api.put('/reports/search', data.params);
        if(task && task.data){
            yield put({type: types.REPORT_LIST + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            yield put({type: types.REPORT_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListReport() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_LIST,getListReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* addReport(data) {
    try {
        yield delay(300);
        yield put({type: types.REPORT_PENDING});
        let task = yield Api.put('/reports/add', data.params);
        console.log("---------------task reports",task)
        if(task && task.data){
            yield put({type: types.REPORT_ADD + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            yield put({type: types.REPORT_ADD + '_SUCCESS', payload: []});
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddReport() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_ADD,addReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* resolvedReport(data) {
    try {
        yield delay(300);
        yield put({type: types.REPORT_PENDING});
        let task = yield Api.put('/reports/resolve', data.params);
        console.log("---------------report reports",task)
        if(task && task.data){
            yield put({type: types.REPORT_RESOLVED + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            yield put({type: types.REPORT_RESOLVED + '_SUCCESS', payload: []});
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchresolvedReport() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_RESOLVED,resolvedReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeReport(data) {
    try {
        yield delay(300);
        yield put({type: types.REPORT_PENDING});
        let task = yield Api.put('/reports/del', data.params);
        if(task && task.data){
            yield put({type: types.REPORT_REMOVE + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveReport() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_REMOVE,removeReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDetailReport(data) {
    try {
        yield delay(300);
        yield put({type: types.REPORT_PENDING});
        let task = yield Api.put('/reports/get', data.params);
        if(task && task.data){
            data.cb && data.cb(null, task.data);
        }
        else{
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetDetailReport() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_DETAIL,getDetailReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

