/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/routes/routes.js
 */

import React, { Component } from "react";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import App from "../container/app";
import Home from "../components/home/home";
import Register from "../components/register/register";
import Login from "../components/login/login";
// import RegisterPayment from '../components/register/register_payment';
import RegisterPayment from "../components/register/register-payment";
import OnBoardingEmail from "../components/on-boarding/on-boarding-email";
import OnBoarding1 from "../components/on-boarding/on-boarding-one";
import OnBoarding2 from "../components/on-boarding/on-boarding-two";
import OnBoarding3 from "../components/on-boarding/on-boarding-three";
import ModuleNotFound from "../components/not-found/not-found";
import MyTeam from "../components/my-team/my-team";
import MyTeamDetail from "../components/my-team-detail/my-team-detail";
import CreateTeam from "../components/my-team/create-team";
import StaffInfo from "../components/staff-info/staff-info";
import Announcements from "../components/feed-page/feed-page";
import CreatePass from "../components/on-boarding/create-pasword";
import EmailWelcome from "../components/on-boarding/email-welcome";
import Calendar from "../components/calendar/calendar";
import Roster from "../components/roster/roster";
import Shift from "../components/shift/shift";
import Messages from "../components/messages/messages";
import Messages2 from "../components/messages/messages2";
import StaffHours from "../components/staff-hours/staff-hours";
import StaffHoursDetail from "../components/staff-hours-detail/staff-hours-detail";
import Tasks from "../components/tasks-page/tasks-page";
import Report  from '../components/reports-page/report-page';
import TasksDetail from "../components/tasks-page/tasks-detail-page";
import ReportDetail from "../components/reports-page/report-detail-page"
import UserManagement from "../components/user-management/user-management-page";
import CreateTask from "../components/tasks-page/create-tasks-page";
import CreateReport from "../components/reports-page/create-report-page";
import Welcome from "../components/welcome/welcome";
import MyAccount from "../components/my-account/my-account";
import Changeplan from "../components/my-account/changeplan";

import Term from "../components/t&c/t&c";
import Help from "../components/help/help";
import ForgetPassword from "../components/forget-password/forgetPassword";
import ResetPassword from "../components/forget-password/resetPassword";
import RegisterLink from "../components/register/register-link";

class Routes extends Component {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
        <Route path="/" component={App}>
          <IndexRoute component={MyTeam} />
          <Route path="/register-company/:rtoken/:unit" component={Register} />
          <Route path="/register-link/:rtoken/:unit" component={RegisterLink} />
          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/register-payment" component={RegisterPayment} />
          <Route path="/on-boarding-email" component={OnBoardingEmail} />
          <Route path="/customer/payment/cancel" component={OnBoardingEmail} />
          <Route path="/customer/payment/success" component={OnBoardingEmail} />
          <Route path="/on-boarding-one" component={OnBoarding1} />
          <Route path="/on-boarding-two" component={OnBoarding2} />
          <Route path="/on-boarding-three" component={OnBoarding3} />
          <Route path="/team-detail" component={MyTeamDetail} />
          <Route path="/my-team" component={MyTeam} />
          <Route path="/staff-info" component={StaffInfo} />
          <Route path="/create-team" component={CreateTeam} />
          <Route path="/announcements" component={Announcements} />
          <Route path="/create-password" component={CreatePass} />
          <Route path="/welcome-page" component={EmailWelcome} />
          <Route path="/calendar" component={Calendar} />
          <Route path="/roster" component={Roster} />
          <Route path="/shift" component={Shift} />
          <Route path="/messages" component={Messages} />
          <Route path="/messages2" component={Messages2} />
          <Route path="/staffhours" component={StaffHours} />
          <Route path="/staffdetail" component={StaffHoursDetail} />
          <Route path="/tasks" component={Tasks} />
          <Route path="/report" component={Report} />
          <Route path="/task-detail" component={TasksDetail} />
          <Route path="/report-detail" component={ReportDetail} />
          <Route path="/create-tasks" component={CreateTask} />
          <Route path="/create-report" component={CreateReport} />
          <Route path="/user-management" component={UserManagement} />
          <Route path="/welcome-manager" component={Welcome} />
          <Route path="/my-account" component={MyAccount} />
          <Route path="/changeplan" component={Changeplan} />
          <Route path="/terms-usage" component={Term} />
          <Route path="/help" component={Help} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/reset-password" component={ResetPassword} />

          <Route path="*" exact={true} component={ModuleNotFound} />
        </Route>
      </Router>
    );
  }
}

export default Routes;
