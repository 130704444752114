import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'


import CForm from "../libs/cform/cform";
import {browserHistory} from 'react-router';
import Config from "../../config";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import * as reportActions from "../../redux/report/report_actions";
import {Image} from "react-bootstrap";
import moment from 'moment';
import * as userActions from "../../redux/user/user_actions";
import {Button} from "react-bootstrap";
import Loaderview from '../loader/loader';
import {Modal,Carousel} from 'react-bootstrap';
import Header from '../header/header';

class ReportDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDetail: null,
            imageOpen:false,
            selectedImage: null,
            attachmentFiles: {},
            setasBtn:false,
            resolvedReports:'',
            isloading:false
        };
        this.isSubmit = false;
    }

    componentDidMount(){
        if(window.location.search){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const taskIdParam = urlParams.get('reportId');

        let reportId = null;
        let userId = null;

        if (taskIdParam) {
        const params = taskIdParam.split('?userId=');
        reportId = params[0];
        userId = params[1] || null;
        }       
        
        if(!reportId){
            browserHistory.push('/report');
            return;
        }
        this.props.reportActions.detailReport({id:reportId,userid:userId},(err, data)=>{
            console.log("-------------------data-details",data)
            if(data){
                this.setState({
                    taskDetail: data
                }
             
            )
            }
            else if(err){
                Config.popup.show('INFO',[Config.alertMess(err)]);
                setTimeout(()=>{
                    browserHistory.goBack();
                },2000)
            }
        });
        }
    };

    getMimeType(type) {
        switch (type) {
            case 'doc':
                return '';
            case 'docx':
                return '';

            case 'xls':
                return '';
            case 'xlsx':
                return '';

            case 'ppt':
                return '';
            case 'pptx':
                return '';

            case 'pdf':
                return '';

            case 'txt':
                return 'text/plain';

            default:
                return 'image/jpeg';
        }
    }

    renderIcon(file, type) {
        // let ext = file.split('.');
        // ext=ext[ext.length-1];
        // ext=ext.toLowerCase();
        switch (type) {
            case 'doc':
                return (<Image src={require("../../assets/images/file/icon-doc.png")}  style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'docx':
                return (<Image src={require("../../assets/images/file/icon-docx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'xls':
                return (<Image src={require("../../assets/images/file/icon-xls.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'xlsx':
                return (<Image src={require("../../assets/images/file/icon-xlsx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'ppt':
                return (<Image src={require("../../assets/images/file/icon-ppt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'pptx':
                return (<Image src={require("../../assets/images/file/icon-pptx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'pdf':
                return (<Image src={require("../../assets/images/file/icon-pdf.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'txt':
                return (<Image src={require("../../assets/images/file/icon-txt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            default:
                return (<Image src={file} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
        }
    }

    imageOpens = (src) => {
        this.setState({
          imageOpen: true,
          selectedImage: src, 
        });
      };
    
      imageClose = () => {
        this.setState({
            imageOpen: false, 
        })
    };
    acknowlegeReport = (params) => {
    this.setState({isloading:true})
        this.props.reportActions.resolvedReport(
            { teamid: params.teamid, resolved: params.resolved, id: params.id },
            (err, data) => {
                if (data) {
                    this.setState({
                        resolvedReports: data,
                        setasBtn: true
                    });
                    browserHistory.push('/report')
                   this.setState({isloading:false}) 
                } else if (err) {
                    console.error("Error:", err);
                }
                
            }
        );
    };
    

    setasCompletedreport = (params) => {
        if (!params || this.isSubmit) return;
    
        this.setState({ isLoading: true });
        this.isSubmit = true;
    
        let acknowledged = params;
        
        if (acknowledged?.status === "acknowledged") {
            let param = {
                id: acknowledged?.id,
                resolved: !acknowledged?.resolved, 
                teamid: acknowledged?.teamid,
            };
    
            this.props.reportActions.resolvedReport(param, (err, data) => {
                this.isSubmit = false;
                this.setState({ isLoading: false });
    
                if (err) {
                    console.error("Error resolving report:", err);
                    return; 
                }
    
                console.log("Resolved data:", data);
                browserHistory.push('/report');
            });
        } else {
            this.isSubmit = false; 
            this.setState({ isLoading: false });
        }
    };
    
    

    render() {
        let {taskDetail, attachmentFiles} = this.state;
        console.log("---------------taskDetais",taskDetail)

        let resolvedReportsBtn = false;
        let acknowlegeReportBtn =false;
        let def_value
        if(taskDetail) {
            def_value =moment(new Date(taskDetail.deadline * 1000)).format('HH:mm DD/MM/YYYY');

            if (taskDetail.status === "resolved") {
                resolvedReportsBtn = true;  // Enable the RESOLVED button
            } else if (taskDetail.status === "pending") {
                acknowlegeReportBtn = true;  // Enable the ACKNOWLEDGE button
            }
        }
     

        let arrAssignTo = taskDetail && taskDetail.assignTo && Config.isJSON(taskDetail.assignTo) ? JSON.parse(taskDetail.assignTo) : [];
        let attachments = taskDetail && taskDetail.attachments && Config.isJSON(taskDetail.attachments) ? JSON.parse(taskDetail.attachments) : [];

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let uid = login?.user?.id; // Ensure safe access
        let urole = login?.userRole?.slug
        ;

        let hasNonStaff  = arrAssignTo.some(item => {
            console.log("-------arrAssignTo ID:", item.user.id);
            return item.user.id === uid || urole === 'owner';
        });
    
        return (
<>
             <div className="container-fluid tasks-view-detail-page">
                {!taskDetail &&  <div style={{marginLeft:"-11px", left:"0px",position:"absolute"}}><Loaderview/></div>}
                {this.state.isloading &&  <div style={{marginLeft:"-11px", left:"0px",position:"absolute"}}><Loaderview/></div>}
                <div className="row task-detail-page-section">
                <div className="col-md-12">
                        <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>
                        <div className="register staff-info my-team row scroll" style={{marginTop:-30}}>
                <div className="back-to-page"   onClick={() => { browserHistory.push('/report') }} style={{margin:"-106px 0px 0px -100px"}}>
                    <img alt="" src={require('../../assets/images/roster/icon-back-def.png')} style={{width:"12px" , height:"20px" , position:"fixed" , marginLeft:"-19px"}}  />
                    <div className='back-text'   >Back</div>
                </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                    {
                       
                        <div className='task-view-button-section' style={{display:hasNonStaff ? "" : "none"}}>
                           {acknowlegeReportBtn ? (
                            <Button
                                className="btn-linear shadow_popup task-detail-btn"
                                style={{ maxHeight: 50, borderRadius: "25px" ,display:taskDetail?.status === 'resolved' ? "none" : ""}}
                                onClick={() => this.acknowlegeReport(taskDetail)}
                            >
                                ACKNOWLEDGE
                            </Button>
                        ) :  (
                            <Button
                                className="btn-linear-orange shadow_popup task-detail-btn"
                                style={{ maxHeight: 50, borderRadius: "25px",display:taskDetail?.status === 'resolved' ? "none" : "" }}
                                onClick={() => this.setasCompletedreport(taskDetail)}
                            >
                                RESOLVE
                            </Button>
                        ) }
                        </div>
                    }
                        <div className="task-detail-desc">
                            <div className="task-content-section">
                                {console.log("-----------task details",taskDetail)}
                                <div className='task-details-titles task-name-title'>{taskDetail? taskDetail.title : null}</div>
                                <div className="task-sub-details">
                                    <div className="task-sub-description">
                                        <div className='task-detail-note task-note-desc'>{taskDetail? taskDetail.note : null}</div>
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="lable-name">Reported To:</td>
                                                    <td className="label-desciption">
                                                        <span className="user-name">
                                                            {arrAssignTo && arrAssignTo.length > 0 && (
                                                            <>
                                                                {arrAssignTo.slice(0, 2).map((key) => key.name).join(", ")}
                                                                {arrAssignTo.length > 2 && ` +${arrAssignTo.length - 2} more`}
                                                            </>)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                    {arrAssignTo && arrAssignTo.length > 0 && ( <>
                                                      {arrAssignTo.slice(0, 2).map((key, idx, arr) => (<Image className="task-avatar-staff" src={(key.user.avatar) ? key.user.avatar : Config.avaDef}/>))}
                                                        {arrAssignTo.length > 2 && ( <span className=""> +{arrAssignTo.length - 2} more</span>)}</> )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="lable-name">Reported By:</td>
                                                    <td className="label-desciption">{taskDetail ? taskDetail.name : "---"}</td>
                                                    <td className=""><Image className="task-avatar-staff" src={taskDetail ? taskDetail?.avatar : Config.avaDef}/></td>
                                                </tr>
						 <tr>
                                                    <td className="lable-name">Reported Date:</td>
                                                    <td className="label-desciption">{taskDetail ? taskDetail.date : "---"}</td>
                                                    <td className=""></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                </div>
                       
                                    <div className="task-sub-image">
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[0] && attachments[0]?.src ? attachments[0]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => { attachments[0]?.src ? this.imageOpens(attachments[0].src) : null }} style={{ cursor: !attachments[0]?.src ? "not-allowed" : "" ,display: attachments[0]?.src ? "" : "none" }} />
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[1] && attachments[1]?.src ? attachments[1]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => {attachments[1]?.src ? this.imageOpens(attachments[1]?.src) : null}} style={{cursor:!attachments[1]?.src ? "not-allowed":"",display: attachments[1]?.src ? "" : "none" }}/>
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[2] && attachments[2]?.src ? attachments[2]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => {!attachments[2]?.src ?  null : this.imageOpens(attachments[2]?.src)}} style={{cursor:!attachments[2]?.src ? "not-allowed":"",display: attachments[2]?.src ? "" : "none" }}/>
                                        </div>
                                        <div className="individual-image">
                                            <img className="task-images-individual image-clike" src={attachments[3] && attachments[3]?.src ? attachments[3]?.src : require("../../assets/images/no-image.jpg")}
                                            onClick={() => {attachments[3]?.src ? this.imageOpens(attachments[3]?.src) : null}} style={{cursor:!attachments[3]?.src ? "not-allowed":"",display: attachments[3]?.src ? "" : "none" }}/>
                                        </div>
                                    </div>
                        
                        </div>
                    </div>
                            <div className="task-image-section"></div>
                </div>
               
            </div>
        </div>
          
    </div>
     <Modal
     show={this.state.imageOpen}
     onHide={this.imageClose}
     id="task-details-images"

   >
     <Modal.Body>
     <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" style={{position:"absolute",top:"0px",right:"1px" , cursor:"pointer"}} onClick={this.imageClose}>
        <circle cx="11" cy="11" r="10" stroke="#C4C4C4" stroke-width="2"/>
        <path d="M7.25 7.25L14.75 14.75" stroke="#C4C4C4" stroke-width="2"/>
        <path d="M14.75 7.25L7.25 14.75" stroke="#C4C4C4" stroke-width="2"/>
        </svg>
       <Image className="task-image-show-pop-up task-page-pop-up-image" src={this.state.selectedImage}/>
     </Modal.Body>
   </Modal>
   </>
        )
    }

}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch),
    })
)( ReportDetailPage);