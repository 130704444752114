
import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";
import * as welcomeActions from "../../redux/welcome/welcome_actions";

//import css
import '../register/register.css'
import './welcome.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';

//import component
import {Image,Modal,Button} from 'react-bootstrap';

//import lib react day
import 'react-day-picker/lib/style.css';

//import lib react Dropzone
import Dropzone from "react-dropzone";
import classNames from 'classnames';

import Config from "../../config";
import ReactLoading from "react-loading";
import Header from '../header/header';
import Loaderview from '../roster/loaderview';

class Welcome extends Component{
    constructor(props){
        super(props);
        this.state={
            flagTab:0,
            welcomeList:[],
            dataPDF:null,
            save_btn:false,
            deletetasks:{},
            deleteIndex:0,
            isUpdateRemoveTeamConfirmed:false,
        };
        this.numberUpload = 0;
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        this.props.welcomeActions.welcomeList((err, data) => {
            if(data){
                let welcomeList = data.filter(i=>i.type==='IMAGE');
                if(welcomeList.length === 0) {
                    welcomeList=[{
                        title:null,
                        content:null,
                        image:null,
                        type:'IMAGE'
                    }]
                }
                this.setState({
                    welcomeList:welcomeList
                });
                let arrPDF = data.find(i=>i.type === 'PDF');
                if(arrPDF){
                    this.setState({
                        dataPDF:arrPDF
                    })
                }
                else{
                    this.setState({
                        dataPDF:{
                            pdf:[],
                            type:'PDF'
                        }
                    })
                }
            }
        });
    };

    changeTab = (idx) => {
        this.setState({
            flagTab: idx
        })
    };

    onUploadImage = (e, cb) => {
        let reader = new FileReader();
        if(e.target.files.length===0) return null;
        let file = e.target.files[0];
        if(file.size > 10485760){
            alert('Maximum upload file size 10MB');
            return;
        }
        reader.onloadend = ()=> {
            this._uploadAva(reader.result, cb);
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    _uploadAva = (e, cb) => {
        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                this.props.userActions.upload(blob, (error, resp) => {
                    // this.vlType = null;
                    if(error) {
                        // this.setState({uploadLogoError: "upload-error"});

                        // setTimeout(()=>{
                            // this.setState({uploadLogoError: ""});
                        // }, 5000);
                        return false;
                    }

                    let img = null;
                    // if(resp && resp[0] && resp[0].path) {
                    //     img = {file: resp[0], src : resp[0].path}
                    // }
                    //let img = { src: e }
                    if(resp && resp.files[0].fd) {
                        img = {file: resp[0], src : "data:image/jpeg;base64,"+resp.path, fd: resp.files[0].fd}
                        //img["file"] = resp[0];
                        //img["fd"] = resp[0].fd;
                    }
                    cb && cb(img);
                });
            });
    };

    onUploadPDF = (files) => {
        let {dataPDF} = this.state;
        let arr = [];
        files.map((file, idx) => {
            if(file.size > 10485760){// 10M
                alert('File ' + file.name + ' upload is error. Maximum upload file size 10MB');
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                this._uploadPDF(reader.result, async(blob)=>{
                    var dataState = dataPDF ? {...dataPDF} : {pdf:[],type:'PDF'};;
                    dataState.pdf.push({nameTemp:file.name, name:file.name});
                    this.setState({
                        dataPDF: dataState,
                        save_btn:true
                    });
                    arr.push({blob:blob, name:file.name});
                    if(arr.length===files.length){
                       await this.props.userActions.uploadMulti(arr, (error, resp) => {
                            if(resp) {
                                console.log("resp",resp)
                                let dataState = dataPDF ? {...dataPDF} : {pdf:[],type:'PDF'};
                                resp.files.map(i=>{
                                    console.log("==============>",resp.path)
                                    console.log("===================>i",i)
                                    const idx = dataState.pdf.findIndex(x=>{
                                    return  x.name === files[0].name
                                    });
                                    console.log("idx===>",idx)
                                    dataState.pdf[idx] = {
                                        path:'',
                                        src:'',
                                        name:'',
                                    };
                                    dataState.pdf[idx] = {
                                        path:resp.path,
                                        src:i.fd,
                                        name:i.filename,
                                    };
                                    console.log(dataState)
                                    return true;
                                });
                                this.setState({
                                    dataPDF: dataState,
                                    save_btn:false
                                })
                            }
                        });
                    }
                });
            };
            reader.readAsDataURL(file);
            return true;
        });
    };

    _uploadPDF = (e, cb) => {
        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                cb && cb(blob);
            });
    };

    onSave = (data, cb, idx) => {
        this.setState({save_btn:true})
        let params = {
            title: data.title ? data.title : '',
            content: data.content ? data.content : ''
        };
        if(data.image){
            params.image = data.image
        }
        if(data.pdf){
            params = {
                pdf: data.pdf,
                type:'PDF',
            }
        }
        if(data.id){
            params.id =data.id;
            this.props.welcomeActions.editWelcome(params,(err, data) => {
                if(data){
                    this.setState({save_btn:true})
                    let dataTemp = this.state.welcomeList.slice(0);
                    dataTemp[idx]=data;
                    this.setState({
                        welcomeList: dataTemp,
                        save_btn:false
                    });
                    cb && cb(null, true);
                }
                else{
                    cb && cb(true, null);
                }
            })
        }
        else{
            this.props.welcomeActions.addWelcome(params,(err, data) => {
                if(data){
                    let dataTemp = this.state.welcomeList.slice(0);
                    dataTemp[idx]=data;
                    this.setState({
                        welcomeList: dataTemp,
                        save_btn:false
                    });
                    cb && cb(null, true);
                }
                else{
                    cb && cb(true, null);
                }
            })
        }
    };

    addMore = () => {
        let {welcomeList} = this.state;
        let dataTemp = welcomeList.slice(0);
        dataTemp.push({
            title:null,
            content:null,
            image:null,
            type:'IMAGE'
        });
        this.setState({
            welcomeList: dataTemp
        })
    };

    onRemove=(data, index, cb)=>{
        Config.popup.show('YES_NO','Do you want to remove step?',()=>{this.onRemoveYes(data, index, cb)});

    };

    onRemoveYes = (data, index, cb) => {
        if(data.id){
            this.props.welcomeActions.removeWelcome({id:data.id},(err, data) => {
                if(data) {
                    this.getList();
                    cb && cb();
                }
                else if(err){
                    Config.popup.show('INFO',[Config.alertMess(err)]);
                }
            });
        }
        else{
            cb && cb();
            let dataTem = this.state.welcomeList;
            dataTem.splice(this.state.deleteIndex,1);
            if(dataTem.length === 0){
                dataTem=[{
                    title:null,
                    content:null,
                    image:null,
                    type:'IMAGE'
                }]
            }
            this.setState({
                welcomeList:dataTem
            })
        }
    };

    onRemovePDF = (idx) => {
        let {dataPDF} = this.state;
        let dataTemp = dataPDF ? {...dataPDF} : {pdf:[],type:'PDF'};
        dataTemp.pdf.splice(idx, 1);
        this.setState({
            dataPDF: dataTemp
        })
    };

    renderPDF = (pdf, idx) => {
        console.log("< ===pdf.src===>",pdf)
        if(pdf.name){
            return(
                <div target="_blank" href={pdf.src} className="icon-pdf shadow" key={"pdf"+pdf.src+idx}>
                    <span>{idx+1+'.'+pdf.name}</span>
                    <span className="txt-remove-pdf" onClick={()=>this.onRemovePDF(idx)}>Remove</span>
                </div>
            );
        }
        else{
            return(
                <div className="icon-pdf shadow" key={"pdf"+pdf.src+idx}>
                    <span>{idx+1+'.'+pdf.name}</span>
                    <div style={{marginRight:15}}>
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={25} width={25}/>
                    </div>
                </div>
            )
        }
    };

    _removeTask = (data, index, cb) => {
        this.setState(
            {
                deletetasks: data,
                deleteIndex: index,
                isUpdateRemoveTeamConfirmed: true,
            },
            () => {
                if (cb) {
                    cb();
                }
            }
        );
      };

    _ConfirmRemove = (data, index, cb) => {
        this.onRemoveYes(data, index, cb)
        setTimeout(() => {
            this._handleClose();                    
          }, 300);
        };

    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false });        
      };

      _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    render(){

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        let dataTab = ['Onboarding Steps','Company Documents'];
        let {welcomeList,dataPDF} = this.state;
        let img = !this.state.flagTab ? require('../../assets/images/welcome/welcome-logo.png') : require('../../assets/images/welcome/welcome-logo-2.png')
        return(
            <div className="full_w full_h row welcome-view height-100vh">
                <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>
                <div className="display_col scroll body_container" style={{marginTop:"23px"}}>
                    <div className="txt-condensed-extra-bold title"
                         style={{fontSize:25, lineHeight:'30px', width:'100%', position:'relative', marginTop:"25px"}}>
                        WELCOME
                        <img src={img}
                             alt=""
                             className="img-logo"
                        />
                    </div>
                    <div className="title-small">
                        Use this tool to provide information and helpful steps to onboard new team members. The in-app onboarding steps once complete will look like the screen to the right.
                    </div>
                    <div className="display_row tab-view" style={{position:'relative'}}>
                        {dataTab.map((key, idx)=>{
                            return(
                                <div key={idx} className={"tab-item "+(this.state.flagTab === idx ? 'active' : '')}
                                     onClick={()=>this.changeTab(idx)}>
                                    {key}
                                </div>
                            )
                        })}
                    </div>
                    {!!this.state.flagTab &&
                        <div className="display_col" style={{paddingBottom: 100}}>
                        <Dropzone onDrop={this.onUploadPDF} accept="application/pdf">
                            {({getRootProps, getInputProps, isDragActive}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                        className={classNames('upload-pdf dropzone', {'dropzone--isActive': isDragActive})}
                                    >
                                        <input {...getInputProps()}/>
                                        {
                                            isDragActive ?
                                                <p>Drop files here...</p> :
                                                <p>Try dropping some files here, or click to select files to upload.</p>
                                        }

                                    </div>
                                )
                            }}
                        </Dropzone>
                            <button type="button"
                                    onClick={()=>this.onSave(this.state.dataPDF,()=>{
                                        this.setState({errMess:'Successfully saved'})
                                        setTimeout(() => {
                                            this.setState({
                                                errMess: ''
                                            })
                                        }, 3000);
                                    })}
                                    disabled={this.state.save_btn}
                                    className="btn-linear-orange shadow_popup btn-save-pdf">
                                SAVE
                            {this.state.save_btn && 
                            <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                            </div>
                            }
                            </button>
                                {this.state.errMess &&
                                <div className="register_error"
                                     style={{
                                         width: '100%',
                                         bottom: 28,
                                         height: 30,
                                         maxHeight: 30,
                                         right: 0,
                                         justifyContent: 'flex-start',
                                         overflow: 'inherit',
                                         whiteSpace:'pre'
                                     }}>
                                    {this.state.errMess}
                                </div>
                                }

                            {dataPDF && dataPDF.pdf && dataPDF.pdf.length > 0 &&
                        <div className="list-pdf-success display_col">
                            {dataPDF.pdf.map((pdf, idx)=>this.renderPDF(pdf, idx))}
                        </div>
                        }
                        </div>
                    }
                    {!this.state.flagTab &&
                        <div className="list-item">
                            {welcomeList && welcomeList.length > 0 && welcomeList.map((key, idx) => {
                                if(key.type !== 'IMAGE') return null;
                                return (
                                    <Step key={"step" + idx}
                                          data={key}
                                          index={idx}
                                          dataStep={welcomeList}
                                          onSave={(data, cb) => this.onSave(data, cb, idx)}
                                          onRemove={(data, index, cb) => this._removeTask (data, index, cb)}
                                          addMore={(data) => this.addMore(data, idx)}
                                          onUploadImage={(e, cb) => this.onUploadImage(e, cb)}/>
                                )
                            })}
                        </div>
                    }
                </div>
                <div>
                <Modal
                show={this.state.isUpdateRemoveTeamConfirmed}
                onHide={this.closeModal}
                id="popup-change-plan"
                className={"root-popup-change-plan remove-team-member popup-change-plan"}
                style={{  maxWidth: "100%", margin: "auto" }}
                >
                <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
                />
                <div className="title">Remove Document</div>
                <div className="title1 remove-admin">Do you want to remove the onboarding steps?</div>
                <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="orange-btns shadow_popup"
                  style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="blue-btns shadow_popup"
                  style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                  onClick={()=>this._ConfirmRemove(this.state.deletetasks)}
                >
                  CONFIRM
                </Button>
                </div>
                   </Modal.Body>
                </Modal>
                </div>
            </div>
        )
    }
}

class Step extends Component{
    constructor(props){
        super(props);
        this.state={
            imageLogo:null,
            isLoading:false,
            errMess:''
        };
        this.vlDataTemp = null;
    }

    onSave = () => {
        if(!this.vlDataTemp || this.state.isLoading || (this.vlDataTemp && (!this.vlDataTemp.title || !this.vlDataTemp.content))) {
            let err = this.state.isLoading ? 'Uploading image... Please wait' : 'Fill out all fields or input correct details to the fields and try again';
            this.setState({
                errMess: err
            });
            setTimeout(() => {
                this.setState({
                    errMess: ''
                })
            }, 3000);
            return;
        }
        if(this.props.onSave) this.props.onSave(this.vlDataTemp, (err, data)=>{
            if(data){
                this.setState({
                    errMess:'  Successfully saved'
                });
            }
            else{
                this.setState({
                    errMess:' Save fail'
                });
            }

            setTimeout(()=>{
                this.setState({
                    errMess:''
                })
            },3000)
        });
    };

    addMore = () => {
        if(this.props.addMore) this.props.addMore();
    };

    onRemove = (data , index) => {
            if(this.props.onRemove) this.props.onRemove(data, index , ()=>{
                this.setState({
                    imageLogo:null
                })
            });
        
        
    };

    onUploadImage = (e) => {
        if(this.state.isLoading || !e.target.files.length) return;
        this.setState({
            isLoading: true
        });
        if(this.props.onUploadImage) this.props.onUploadImage(e, (img)=>{
            this.setState({
                imageLogo: img,
                isLoading: false
            });
            this.vlDataTemp.image = img.src;
            document.getElementsByClassName("uploadimg").value = "";
        });
    };

    render(){
        let {data,dataStep,index} = this.props;
        let dataNumber = ['One','Two','Three'];
        this.vlDataTemp = data;
        let showRemove = !(dataStep.length === 1 && !data.id);
       
        return(
            <div className="flex_wrap step-item">
                {showRemove && <div className="col-12 col-sm-12 col-md-8 text-index">Step {dataNumber[index]}
                    <span className="txt-remove" onClick={()=>this.onRemove(data , index)}>Remove</span>
                </div>}
                <div className="col-12 col-sm-12 col-md-12">
                    <input placeholder="Step Name (Max 14 Characters)"
                           className="input-title col-md-12"
                           maxLength={14}
                           key={data.id+'input-name'}
                           onChange={e=>this.vlDataTemp.title=e.target.value}
                           value={data.title}/>
                </div>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-8 display_col align-right' style={{paddingRight:40,paddingBottom: 30}}>
                    <textarea className="text-area-content"
                              placeholder="Step Description"
                              value={data.content}
                              key={data.id+'input-textarea'}
                              onChange={e=>this.vlDataTemp.content=e.target.value}
                    />
                    {this.state.errMess &&
                            <div className="register_error"
                                 style={{
                                     width: '100%',
                                     top: 25,
                                     height: 30,
                                     right: 0,
                                     justifyContent: 'flex-start',
                                     overflow: 'inherit',
                                     whiteSpace:'pre',
                                     position:'relative',

                                 }}
                                >
                                {this.state.errMess}
                            </div>
                        }
                    <button type="button"
                            onClick={()=>this.onSave()}
                            style={{position:'relative',borderRadius:"25px"}}
                            className="btn-linear shadow_popup btn-upload">
                        SAVE
                    </button>
                    {dataStep.length <3 && (index === dataStep.length-1) &&
                    <button type="button" style={{marginTop:14 , textAlign:"center"}}
                            onClick={this.addMore}
                            className="shadow_popup btn-upload btn-linear-orange">
                        ADD ANOTHER
                    </button>}
                    </div>
                    <div className='col-md-4'>
                    <div className="imgPreview">
                        <Image src={(this.state.imageLogo && this.state.imageLogo.src) ? this.state.imageLogo.src : (data.image ? data.image: (Config.imageDef || require('../../assets/images/welcome/welcome-null.png')))}
                               className="avatar-image"/>
                    </div>
                    <div className="title titleupload">
                        <button type="button"
                                className="btn-linear shadow_popup btn-upload" style={{borderRadius:"25px"}}>
                                    {(this.state.imageLogo && this.state.imageLogo.src) ? "CHANGE IMAGE" : (data.image ? "CHANGE IMAGE": "ADD IMAGE")}
                            {this.state.isLoading &&
                            <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                            </div>
                            }
                            <input className="upload uploadimg" type="file" accept="image/*" onChange={(e)=>{this.onUploadImage(e)}} />
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        eventList: state.team.eventList,
        welcomeList: state.welcome.welcomeList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
        welcomeActions: bindActionCreators(welcomeActions, dispatch),
    })
)(Welcome);