import React, { Component } from "react";
import { styled } from "@mui/system";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const OpenPickerIcon = styled(KeyboardArrowDownIcon)({
    backgroundColor: "#eef7f6",
    color: "#31ccbc",
    fontSize: "30px",
    width: "30px",
    height: "30px",
    marginTop: "4px",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0) !important",
    }
});
function NoActionBar() {
    return null; // Render nothing for the Action Bar
}

class FormattedDateTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        selectedDateTime: props.selectedValue || null, 
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedValue !== this.props.selectedValue) {
            this.setState({ selectedDateTime: this.props.selectedValue });
        }
    }

    handleDateTimeChange = (newValue) => {
        this.setState({ selectedDateTime: newValue });
        console.log("Selected DateTime:", newValue ? newValue.format("YYYY-MM-DD HH:mm") : "None");

        if (this.props.onDateChange) {
            console.log(" FormattedDateTimePicker - Passing to Parent (CForm):", newValue.toISOString());
            this.props.onDateChange(newValue.toISOString()); 
        }
    };

    render() {
        console.log('hello world')
        console.log(this.state.selectedDateTime)
        return (
            <div className={this.props.className}> 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                            value={this.state.selectedDateTime}
                            onChange={this.handleDateTimeChange}
                            slots={{
                                openPickerIcon: OpenPickerIcon,
                                actionBar: NoActionBar,
                            }}
                            ampm={false}
                            views={["year", "month", "day", "hours", "minutes"]}
                            slotProps={{
                                popper: {
                                    sx: {
                                        "& .MuiPaper-root": {
                                            backgroundColor: "#fff",
                                        },
                                        ".MuiPickersCalendarHeader-labelContainer, .MuiPickersCalendarHeader-switchViewButton, .MuiPickersArrowSwitcher-leftArrowIcon, .MuiPickersArrowSwitcher-rightArrowIcon": {
                                            color: "#31ccbc",
                                        },
                                        ".Mui-selected, .Mui-selected:hover": {
                                            color: "#fff",
                                            backgroundColor: "#31ccbc !important",
                                            border: "1px solid #31ccbc",
                                        },
                                        ".MuiMenuItem-gutters:hover": {
                                            color: "#fff",
                                            backgroundColor: "#f18977",
                                        },
                                        ".MuiIconButton-edgeEnd": {
                                            backgroundColor: "rgba(0, 0, 0, 0) !important",
                                        },
                                        "ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root::after": {
                                            height: "0px !important",
                                        },
                                        "ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root": {
                                            fontFamily: "open-sans-bold",
                                        },
                                        "& .MuiPaper-root": {
                                            backgroundColor: "#fff",
                                        },
                                        ".MuiPickersCalendarHeader-label, .MuiDayCalendar-weekDayLabel, .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin, .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton, .MuiMultiSectionDigitalClockSection-item": {
                                            fontFamily: 'open-sans-semibold',
                                            fontSize: "1rem"
                                        },
                                        ".MuiPickersCalendarHeader-labelContainer, .MuiPickersCalendarHeader-switchViewButton, .MuiPickersArrowSwitcher-leftArrowIcon, .MuiPickersArrowSwitcher-rightArrowIcon": {
                                            color: "#31ccbc",
                                        },
                                        ".Mui-selected, .Mui-selected:hover": {
                                            color: "#fff",
                                            backgroundColor: "#31ccbc !important",
                                            border: "1px solid #31ccbc",
                                        },
                                        ".MuiPickersDay-dayWithMargin:hover, .MuiPickersYear-yearButton:hover, .MuiPickersMonth-monthButton:hover": {
                                            color: "#fff",
                                            backgroundColor: "#f18977",
                                        },
                                        'ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar': {
                                            width: '3px !important'
                                        },
                                        ".MuiIconButton-edgeEnd": {
                                            backgroundColor: "rgba(0, 0, 0, 0) !important",
                                        },
                                        ".MuiOutlinedInput-input": {
                                            paddingLeft: "0px !important",
                                            width: "150px !important",
                                        }
                                    },
                                },
                                textField: { 
                                    onClick:null,
                                    size: "small", 
                                    placeholder: this.props.placeholder,                                    
                                    inputProps: { 
                                        readOnly: true, 
                                        onKeyDown: (e) => e.preventDefault(),
                                        onFocus: (e) => e.target.blur(),
                                        style: {
                                            fontSize: "16px",
                                            color: "#4A4A4A"
                                        }
                                    }, 
                                    onKeyDown: (e) => e.preventDefault(), 
                                   sx:{
                                   
                                        '& .MuiInputBase-input::placeholder': { 
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#4A4A4A',
                                            fontFamily: 'open-sans-semibold',
                                            opacity: 1,
                                            pointerEvents:"none" 
                                        },
                                        "& .MuiInputBase-input, .MuiInputBase-inputAdornedEnd": {
                                            fontSize: "16px",
                                            color: "#4A4A4A",
                                            fontWeight: 600,
                                            fontFamily: 'open-sans-semibold',
                                            pointerEvents: "none", // Prevent manual editing
                                            opacity: 1,
                                            height: "50px !important",
                                            width: "258px !important",
                                            padding: "0px"
                                        },
                                   }
                                },
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        );
    }
}

export default FormattedDateTimePicker;
