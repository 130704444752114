import React, { Component } from 'react';
import { styled } from "@mui/system";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';

const OpenPickerIcon = styled(KeyboardArrowDownIcon)({
    backgroundColor: "#eef7f6",
    color: "#31ccbc",
    fontSize: "30px",
    width: "30px",
    height: "30px",
    marginTop: "4px",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0) !important",
    }
});
function NoActionBar() {
    return null; // Render nothing for the Action Bar
}

class BasicTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTime: null
        };
    }

    handleTimeChange = (newValue) => {
        this.setState({ selectedTime: newValue });
        console.log(newValue)
        console.log('hellowrld')
        if (this.props.onTimeChange) {
            this.props.onTimeChange(newValue);
        }
    };

    render() {
        return (
            <div className={this.props.className}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                        <TimePicker
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            slots={{
                                openPickerIcon: OpenPickerIcon,
                                actionBar: NoActionBar,
                            }}
                            ampm={false}
                            slotProps={{
                                popper: {
                                    sx: {
                                        '& .MuiPaper-root': {
                                            backgroundColor: '#fff',
                                        },
                                        '.MuiTimeClock-root.css-eegpc0-MuiTimeClock-root': {
                                            width: '250px',
                                            maxHeight: '250px'
                                        },
                                        '.MuiPickersCalendarHeader-labelContainer, .MuiPickersCalendarHeader-switchViewButton, .MuiPickersArrowSwitcher-leftArrowIcon, .MuiPickersArrowSwitcher-rightArrowIcon': {
                                            color: '#31ccbc',
                                        },
                                        '.Mui-selected, .Mui-selected:hover': {
                                            color: '#fff',
                                            backgroundColor: '#31ccbc !important',
                                            border: '1px solid #31ccbc'
                                        },
                                        '.MuiMenuItem-gutters:hover': {
                                            color: '#fff',
                                            backgroundColor: '#f18977',
                                        },
                                        '.MuiIconButton-edgeEnd': {
                                            backgroundColor: "rgba(0, 0, 0, 0) !important",
                                        },
                                        '.MuiPickersLayout-contentWrapper': {
                                            width: 'auto',
                                            height: '200px'
                                        },
                                        'ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar': {
                                            width: '3px !important'
                                        },
                                        'ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root::after': {
                                            height: '0px !important'
                                        },
                                        'ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root': {
                                            fontFamily: 'open-sans-bold'
                                        },
                                        '.MuiInputBase-input': {
                                            color: "#181818 !important"
                                        }
                                    },
                                },
                                textField: {
                                    size: "small",
                                    placeholder: "Select time here",
                                    inputProps: { readOnly: true },
                                    onFocus: (e) => e.target.blur()
                                },
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        );
    }
}

export default BasicTimePicker;
